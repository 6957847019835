export const decimals = {
    BTC: '100000000',
    ETH: '1000000000000000000',
    USDT: '1000000',
    BNB: '1000000000000000000',
    ADA: '1000000',
    DOGE: '100000000',
    XRP: '1000000',
    USDC: '1000000',
    DOT: '1000000000000000000',
    UNI: '1000000000000000000',
    CEX: '1000000000000000000',
    USD: '100',
}
