import {action, makeAutoObservable} from "mobx";
import {getTotalBalance} from "../../services/api/wallets/requests";

class WalletTotal{

    totalBalance: string = ''

    async setTotalBalance(){
        this.totalBalance = await getTotalBalance()
    }

    clear(){
        this.totalBalance = ''
    }

    constructor() {
        makeAutoObservable(this, {
            setTotalBalance: action
        })
    }
}

export default new WalletTotal()