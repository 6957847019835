import React, {FC, useEffect} from 'react';
import styled from "styled-components/macro";

interface IWrapper{
    isOpened: boolean
}

const Wrapper = styled.div<IWrapper>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpened? 'flex': 'none'};
  justify-content: center;
  align-items: center;
  z-index: 999999999999;
`

interface IModal{
    width: number;
    height: number;
}
const Modal = styled.div<IModal>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border-radius: 8px;
  background: #1B2228;
  padding: 24px;
`

interface IModalWrapper{
    isOpened: boolean,
    callback: () => void,
    height?: number,
    width? :number
}

const ModalWrapper :FC<IModalWrapper> = ({callback, isOpened, children, height= 250, width= 350}) => {

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return function (){
            document.body.style.overflow = 'auto'
            if(callback) callback()
        }
    },[])

    return (
        <Wrapper isOpened={isOpened}>
            <Modal height={height} width={width}>
                {children}
            </Modal>
        </Wrapper>
    );
};

export default ModalWrapper;