import React, {FC} from 'react';
import Spacer from "../../../ui/spacer/spacer";
import {ParsedCrypto} from "../../../../services/app/coins-parser/coins-parser";
import {toFixed} from "../../../../utils/numbers/toFixed";
import {
  BalanceCryptoItemWrapper,
  CryptoRow,
  DescriptionRow,
  Divider,
  ItemBody,
  ItemImage
} from "./balance-crypto-item.styled";


interface IBalanceCryptoItem{
    item: ParsedCrypto
}

const BalanceCryptoItem :FC<IBalanceCryptoItem> = ({item}) => {
    return (
        <BalanceCryptoItemWrapper>
            <div style={{display:'flex'}}>
                <ItemImage>
                    <img alt='Crypto image' src={item.src} style={{borderRadius: '50%'}}/>
                </ItemImage>

                <ItemBody>
                    <CryptoRow> {item.symbol} <Spacer/> { toFixed( item.crypto)} </CryptoRow>
                    <DescriptionRow> {item.name} <Spacer/> {toFixed(Number(item.price))} $ </DescriptionRow>
                </ItemBody>
            </div>
            <Divider/>

        </BalanceCryptoItemWrapper>
    );
};

export default BalanceCryptoItem;