import {makeAutoObservable} from "mobx";

class AppSession{

  deleteSession:boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setDeleteSession(state: boolean){
    this.deleteSession = state
  }

  clear(){
    this.deleteSession = false
  }
}

export default new AppSession()