import styled from "styled-components";

export const LoginFormWrapper = styled.div`
  flex-grow: 1;
  width: 400px;
  margin-right: auto !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
`

export const LoginFormMainTitle = styled.div`
  font-size: 28px;
  color: #253545;
  display: flex;
  font-weight: 700;
`

export const LoginFormSubTitle = styled.div`
  color: rgba(37, 53, 69, 0.6);
  font-size: 16px;
  display: flex;
  text-align: left;
  margin-top: 8px;
`

export const Form = styled.div`
  margin-top: 48px;
  margin-bottom: 90px;
`

export const CopyRight = styled.div`
  color: rgba(37, 53, 69, 0.4);
  font-size: 13px;
  display: flex;
  text-align: left;
  margin-bottom: 3rem;
  margin-top: auto;
`