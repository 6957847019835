import React, {FC} from 'react';
import Plain from "../../../../public/email-verification/plane-img.png";
import {ImageBackground, ImageBlock, MailImage as PlainImg} from "./styled-plain-image";

const PlainImage : FC = () => {
    return (
        <ImageBlock>
            <ImageBackground>
                <PlainImg src={Plain}/>
            </ImageBackground>
        </ImageBlock>
    );
};

export default PlainImage;