import React, {FC} from 'react';
import styled from "styled-components";
import Chart from '../../../../public/wallet/line-chart.png'
import Spacer from "../../../ui/spacer/spacer";
import {IFavoritePair} from "../../../../types/favorite-pairs/favorite-pairs.types";
import { LowercaseCrypto} from "../../../../utils/constants/crypto";
import {ICoin} from "../../../../types/exchange/pair.types";

const PairWrapper = styled.div`
`

const CryptoPairTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: left;
`

const CryptoDescriptionRow = styled.div`
  display: flex;
`

const ChartPart = styled.div`
  display: flex;
  height: 32px;
`

const CryptoRate = styled.div`
    text-align: left;
`

const AmountRate = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: white;
`

interface IPercentRate{
    number: number
}

const PercentRate = styled.div<IPercentRate>`
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.number > 0? '#31BC83' : '#EB5746'};
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #151A1E;
  margin-top: 13px;
  margin-bottom: 14px;
`


interface IPairProps{
    pair: IFavoritePair
}
//TODO fix types
const Pair :FC<IPairProps> = ({pair}) => {
  const {symbol}:ICoin  = pair.tradingPair.toCoin
  const currentRate = `${LowercaseCrypto[symbol]}Price`
  return (
        <PairWrapper>
            <CryptoPairTitle>
                {pair.tradingPair.fromCoin.symbol}/{pair.tradingPair.toCoin.symbol}
            </CryptoPairTitle>
            <CryptoDescriptionRow>
                <CryptoRate>
                  {/*@ts-ignore*/}
                  <AmountRate> {parseFloat( pair.tradingPair.fromCoin[`${currentRate}`] )} </AmountRate>
                    <PercentRate number={2}> 2% </PercentRate>
                    {/*<PercentRate number={pair.percentRate}> {pair.percentRate > 0? "+" : ''}{pair.percentRate}% </PercentRate>*/}
                </CryptoRate>
                <Spacer/>
                <ChartPart><img alt={'Favorite pair chart'} src={Chart} /></ChartPart>
            </CryptoDescriptionRow>
            <Divider/>
        </PairWrapper>
    );
};

export default Pair;