import styled from "styled-components";

export const OptionsWrapper = styled.div`
  display: flex;
  height: 80px;
  padding-left: 14px;
  flex-direction: row;
  background: #1B2228;
  margin-left: 4px;
`

export const CryptoPair = styled.div`
  flex-direction: column;
  margin-right: 27px;
  display: flex;
  justify-content: center;
  height: 80px;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const PairSelect = styled.div`
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: 700;
  background: transparent;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
`

export const SelectBtn = styled.span`
  display: flex;
  align-items: center;
  margin-left: 7px;
`

export const MainCoinName = styled.div`
  color: rgba(255, 255, 255, 0.6);
  display: flex;
`

export const MainCoinImage = styled.span`
  margin-right: 6px;
`

export const Price = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
`

export const CryptoPrice = styled.div`
  color: white;
  font-weight: 700;
  font-size: 18px;
  display: flex;
`

export const CryptoChanges = styled.div`
  display: flex;
  color: #EB5746;
  font-size: 13px;
  font-weight: 600;
`