import React from 'react';

const Setting = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5C11 5.55228 10.5523 6 10 6C9.44772 6 9 5.55228 9 5ZM10 2C11.3062 2 12.4175 2.83481 12.8293 4H14C14.5523 4 15 4.44772 15 5C15 5.55228 14.5523 6 14 6H12.8293C12.4175 7.16519 11.3062 8 10 8C8.69378 8 7.58254 7.16519 7.17071 6H2C1.44772 6 1 5.55228 1 5C1 4.44772 1.44772 4 2 4H7.17071C7.58254 2.83481 8.69378 2 10 2ZM7 11C7 11.5523 6.55228 12 6 12C5.44774 12 5.00004 11.5523 5 11.0001C5 11 5 11 5 11C5 10.4477 5.44771 10 6 10C6.55228 10 7 10.4477 7 11ZM3.17071 10C3.58254 8.83481 4.69378 8 6 8C7.30622 8 8.41746 8.83481 8.82929 10L14 10C14.5523 10 15 10.4477 15 11C15 11.5523 14.5523 12 14 12L8.82929 12C8.41746 13.1652 7.30622 14 6 14C4.69378 14 3.58254 13.1652 3.17071 12H2C1.44772 12 1 11.5523 1 11C1 10.4477 1.44772 10 2 10H3.17071Z" fill="white"/>
        </svg>
    );
};

export default Setting;