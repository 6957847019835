import styled from "styled-components";

export const AppBarWrapper = styled.div`
    display: flex;
    flex: 0 1 100%;
    height: 7vh;
    align-items: center;
    background: #1B2228;
`

export const AppBarLogo = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 21px;
  padding: 0 16px;
`

export const AppBarLogoImage = styled.img`
  display: flex;
`

export const AppBarLogoTitle = styled.span`
  font-weight: 600;
  font-size: 21px;
  margin-left: 9.5px;
  color: white;
`

export const AppBarNavigation = styled.span`
  color: white;
  padding-right: 10px;
  display: flex;
`

export const AppBarNavItem = styled.span`
  font-size: 14px;
  font-weight: 600;
  padding: 0 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
`