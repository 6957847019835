import React, {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import PercentSelector from "../../../../../ui/percent-selector/percent-selector";
import Spacer from "../../../../../ui/spacer/spacer";
import Wallet from "../../../../../../public/icons/wallet";
import {
    BigButton,
    Coins,
    Funds,
    FundsTitle,

    ResetBtn,
    ResetRow, ReviewRow
} from "./styled-market-type";
import Input from "../../../../../global/input/input";
import {useForm} from "react-hook-form";
import Checkbox from "../../../../../ui/checkbox/checkbox";
import Pairs from "../../../../../../store/pairs/pairs";
import {toJS} from "mobx";
import {IPair} from "../../../../../../types/exchange/pair.types";
import Balances from "../../../../../../store/balances/balances";
import {getLastOrder} from "../../../../../../services/api/orders/order-book";
import {toCoin, toCoins} from "../../../../../../utils/tools/calcs";
import {calculateCrypto,  normilizeFloat, symbolsAfterComma} from "../../../../../../utils/math/math";
import {toFixed} from "../../../../../../utils/numbers/toFixed";
import {Coins as CryptoCoins} from "../../../../../../utils/constants/crypto";
import ModalWrapper from "../../../../../ui/modal/modal-wrapper/modal-wrapper";
import ReviewSell from "../../../../../ui/modal/review/review/review";
import {IMarketOrder} from "../../../../../../types/orders/market-order.types";
import {sendMarketOrder} from "../../../../../../services/api/orders/market-order";
import {observer} from "mobx-react-lite";
import {getCoins} from "../../../../../../services/api/exchange/request";
import BigNumber from "bignumber.js";

//TODO fix tsignore

interface IMarketType{
    type: 'buy' | 'sell'
}

const forbiddenNumberInputValues = [
  '+',
  '-',
  'e'
]

interface IFormOrder {
  amount: number;
  total: number;
  checked: boolean;
}

const MarketType :FC<IMarketType> = observer( ({type}) => {

  const [range, setRange] = useState(0)

  // const [sndRange, setSndRange] = useState(1)

  const defaultValues = {
    amount: '0',
    price: '0',
    total: '0',
    checked: false
  }

  const [lastOrderPrice, setLastOrderPrice] = useState<string>('')

  const {register, reset, handleSubmit, setValue, watch} = useForm({
    defaultValues
  })

  const watchAllFields = watch()

  const resetForm = async () => {
    if(currentPair){
      reset(JSON.parse( JSON.stringify(defaultValues)))
      setRange(0)
      const last = await getLastOrder(currentPair.id)
      const lastOrderParsedPrice = toCoin(last.price, currentPair.toCoin.symbol).toFixed()
      setLastOrderPrice(lastOrderParsedPrice)
    }
  }
  useEffect(() => console.log(lastOrderPrice),[lastOrderPrice])

  const [currentPair, setCurrentPair] = useState<IPair>()
  const [currentBalance, setCurrentBalance] = useState<any>()

  const getCurrentBalance = async () => {
    if(Pairs.pairs.length === 0) await Pairs.getPairs()
    const currentPair = toJS(Pairs.currentPair)
    setCurrentPair(currentPair as IPair)
    if(Balances.balances.length === 0) await Balances.getBalances()
    const balances = toJS(Balances.balances)
    if( "fromCoin" in currentPair ){
      const balance = type === 'sell'? balances.find(el => el.name === currentPair.fromCoin.symbol) : balances.find(el => el.name === currentPair.toCoin.symbol)
      await setCurrentBalance(balance)
      console.log('balance')
      console.log(balance)
    }
  }

  const setFunds = (value: number) => {
      if(currentBalance){
        if(type === 'buy'){
          console.log('buy')
          const totalAmount = Number( currentBalance.amount)*value/100
          setValue('total',String( totalAmount))
          setValue('amount', String(totalAmount/Number(lastOrderPrice)))
        }
        if(type === 'sell'){
          console.log('sell')
        }
        setRange(value)
      }
  }

  const handleAmountChange = async (e:ChangeEvent<HTMLInputElement>) => {
    if(!currentPair) return
    if(e.target.value === '' || String(e.target.value)[0] === '.' || String(e.target.value)[0] === ',')  {
      setValue('amount', '0')
      setValue('total', '0')
      return
    }
    forbiddenNumberInputValues.forEach(forbiddenValue => {
      if(e.target.value.includes(forbiddenValue)) {
        setValue('amount', '0')
        setValue('total', '0')
        return
      }
    })
    setValue('amount', normilizeFloat(e.target.value))
    if(Number(e.target.value) < 0) return setValue('amount', '')

    if (lastOrderPrice == '0') {
      const coins = await getCoins();

      const [firstCoin] = coins.filter(c => c.symbol.toUpperCase() == currentPair.fromCoin.symbol.toUpperCase())
      const [secondCoin] = coins.filter(c => c.symbol.toUpperCase() == currentPair.toCoin.symbol.toUpperCase())

      // const ratio = bigIntDivideNumber(toCoins(firstCoin.usdPrice, firstCoin.symbol).toString(), toCoins(secondCoin.usdPrice, secondCoin.symbol).toString());

      const ratio = (new BigNumber(firstCoin.usdPrice)).dividedBy(new BigNumber(secondCoin.usdPrice));

      const total = (new BigNumber(e.target.value)).multipliedBy(ratio);

      console.log(firstCoin.usdPrice, 'divided by', secondCoin.usdPrice, 'ratio', ratio.toFixed(), 'total', total.toFixed())

      setValue('total', total.dp(4).toFixed())
      if (type === 'sell') setRange(parseInt(String(Number(e.target.value) / Number(currentBalance.amount) * 100)))
      if (type === 'buy') setRange(parseInt(String(Number(total.toString()) / Number(currentBalance.amount) * 100)))

    } else {

    const total = calculateCrypto({firstNumber: e.target.value, secondNumber:lastOrderPrice, firstCoin: currentPair.fromCoin.symbol, secondCoin: currentPair.toCoin.symbol }).toString()
    // const total = multiplyNumbers(Number(e.target.value), Number(lastOrderPrice))
    const filteredTotal = total
    console.log(filteredTotal)
    setValue('total', filteredTotal)
    if(type === 'sell') setRange(parseInt( String(Number(e.target.value) / Number(currentBalance.amount) * 100)))
    if(type === 'buy') setRange(parseInt( String(Number(filteredTotal) / Number(currentBalance.amount) * 100)))
    }
  }

  const handleTotalChange = async (e:ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === '' || String(e.target.value)[0] === '.' || String(e.target.value)[0] === ',')  {
      setValue('amount', '0')
      setValue('total', '0')
      return
    }
    forbiddenNumberInputValues.forEach(forbiddenValue => {
      if(e.target.value.includes(forbiddenValue)) {
        setValue('amount', '0')
        setValue('total', '0')
        return
      }
    })
    // const {onChange} = register('total')
    if(Number(e.target.value) < 0) return setValue('total', '')
    // await onChange(e)
    setValue('total', normilizeFloat(e.target.value))
    const amount = Number(e.target.value) / Number(lastOrderPrice)
    const filteredAmount = symbolsAfterComma(toFixed(amount) ) > 7 ? amount.toFixed(7) : String(amount)
    setValue('amount', filteredAmount)
    if(type === 'sell') setRange(parseInt( String(Number(filteredAmount) / Number(currentBalance.amount) * 100)))
    if(type === 'buy') setRange(parseInt( String(Number(e.target.value) / Number(currentBalance.amount) * 100)))
  }

  const [order, setOrder] = useState<IMarketOrder>()
  const [isOpen, setOpen] = useState<boolean>(false)

  const sendMarketTransaction = async (values :IFormOrder ) => {
    if(!currentPair) return

    const amount = String( toCoins(String( values.amount), CryptoCoins[ currentPair.fromCoin.symbol]) )

    const order: IMarketOrder = {
      tradingPairId: currentPair.id,
      case: type === 'buy'? 'BUY' : 'SELL',
      amount : Number(amount)
    }

    console.log(order)
    if(values.checked){
      return sendInstantLimitTransaction(order)
    }
    await setOrder(order)
    await setOpen(true)
  }

  const sendInstantLimitTransaction = async (currentOrder: IMarketOrder ) => {
    if(!currentPair) return
    try{
      if(!currentOrder) currentOrder = order as IMarketOrder
      console.log(currentOrder)
      const res = await sendMarketOrder(currentOrder)
      console.log(res)
      setOpen(false)
      await Balances.getBalances()
      await getCurrentBalance()
    }catch (e){
      console.log(e)
    }
  }

  const isValid = useMemo<boolean>(() => {
    try {
      const fields = watchAllFields
      if(Number( fields.amount) <= 0) return false
      if(Number(fields.total) <= 0 ) return false
      if(type === 'buy'){
        const balances =  toJS( Balances.balances)
        const toCoinBalance =  balances.find(balance => balance.name === currentPair?.toCoin.symbol.toUpperCase())
        if(Number(fields.total) > Number( toCoinBalance.amount)) return false
      }
      if(type === 'sell'){
        const balances = toJS( Balances.balances)
        const fromCoinBalance = balances.find(balance => balance.name === currentPair?.fromCoin.symbol.toUpperCase())
        if(Number(fields.amount) > Number(fromCoinBalance.amount)) return false
      }
      return true
    }catch (e){
      return false
    }
  },[watchAllFields])

  useEffect(() => {
    (async function (){
      await getCurrentBalance()
    })()
  },[Pairs.currentPair])

  useEffect(() => {
    (async function (){
      try {
        await resetForm()
      }catch (e){
        console.log(e)
      }
    })()
  },[currentPair])

  return (
    <>
      {
        isOpen && currentPair &&
        <ModalWrapper isOpened={true} callback={() => console.log('hello')} width={350} height={288}>
          {/*@ts-ignore*/}
          <ReviewSell callback={sendInstantLimitTransaction} price={toCoins( lastOrderPrice, currentPair.toCoin.symbol)} type={type.toUpperCase()} amount={order.amount} closeModal={() => setOpen(false)} pair={currentPair}/>
        </ModalWrapper>
      }
      <form autoComplete="off" onSubmit={handleSubmit( sendMarketTransaction)}>
        <div>
          {/*<Leverage>*/}
          {/*    <LevarageTitle> Leverage </LevarageTitle>*/}
          {/*    <LevarageSelect>*/}
          {/*        <CoefficientSelector range={sndRange} setRange={setSndRange}/>*/}
          {/*    </LevarageSelect>*/}
          {/*</Leverage>*/}
          <div style={{marginTop: '15px'}}/>
          <Funds>
            <FundsTitle>
              <span style={{marginTop:'2px'}}> Funds </span> <Spacer/> <Coins> {currentBalance?.amount} {currentBalance?.name} <span style={{marginTop:'4px', marginLeft:'4px'}}> <Wallet/> </span> </Coins>
            </FundsTitle>
          </Funds>
          <PercentSelector range={range} setRange={setFunds}/>

          <div>
            <Input placeholder={'Amount'} formikProps={{...register('amount'), onChange: handleAmountChange}} endPlaceholder={currentPair?.fromCoin.symbol} type={'number'}/>
          </div>

          <div style={{marginTop:'24px'}}>
            <Input placeholder={'Price'} formikProps={{value: lastOrderPrice}} endPlaceholder={currentPair?.toCoin.symbol} type={'text'} disabled={true}/>
          </div>

          <div style={{marginTop:'24px'}}>
            <Input placeholder={'Total'} formikProps={{...register('total'), onChange: handleTotalChange}} endPlaceholder={currentPair?.toCoin.symbol} type={'number'}/>
          </div>

          <ResetRow>
            <ResetBtn onClick={() => resetForm()}>Reset</ResetBtn>
          </ResetRow>

          <ReviewRow>
            <Checkbox label={'exchange without review'} form={{...register('checked')}}/>
          </ReviewRow>

          <div style={{marginLeft:'16px', marginRight:'16px'}}>
            <BigButton type='submit' pageType={type} disabled={!isValid}> Review and Buy BTC </BigButton>
          </div>

          <div style={{width:'100%', height:'16px', background:'#1B2228'}}/>
        </div>
      </form>
    </>
  )
})

export default MarketType