import React from 'react';

const Wallet = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4">
                <path d="M2 4H13C13.5523 4 14 4.44772 14 5V11C14 11.5523 13.5523 12 13 12H3C2.44772 12 2 11.5523 2 11V4Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2 4C2 3.44772 2.44772 3 3 3H10C10.5523 3 11 3.44772 11 4V4H2V4Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <rect x="10" y="7" width="2" height="2" rx="1" fill="white"/>
            </g>
        </svg>
    );
};

export default Wallet;