import React, {FC, useEffect, useState} from 'react';
import {
  ActiveOrderTable as ActiveOrderTableWrapper,
  ActiveOrderTableBody,
  ActiveOrderTableBodyItem,
  ActiveOrderTableHead,
  ActiveOrderTableHeadItem, NoAvailableItems,
  TableRow
} from "./styled-active-orders-table";
import {IActiveOrder} from "../../../../../types/orders/active-order.types";
import {multiplyNumbers, symbolsAfterComma} from "../../../../../utils/math/math";
import {parseDate} from "../../../../../utils/date/date";
import {cancelLimitOrder, getActiveOrders} from "../../../../../services/api/orders/active-orders";
import MarketOrders from "../../../../../store/market-orders/market-orders";
import {Coins} from "../../../../../utils/constants/crypto";
import {toCoin} from "../../../../../utils/tools/calcs";
import {toFixed} from "../../../../../utils/numbers/toFixed";

interface ITableItem {
    item: IActiveOrder
}

const TableItem :FC<ITableItem> = ({item}) => {
    const cancelOrder = async () => {
        console.log(item.id)
        const res = await cancelLimitOrder(item.id)
        console.log(res)
    }

    const amount = toCoin(String(item.amount), Coins[item.fromCoin.symbol]).toString()
    const price = toCoin(String(item.price), Coins[item.toCoin.symbol]).toString()
    const total =  toFixed( multiplyNumbers(Number( amount), Number( price)) )

    return(
        <TableRow>
            <ActiveOrderTableBodyItem> {item.fromCoin.symbol}/{item.toCoin.symbol}  </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> {item.orderType} </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> {item.exchange} </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> { symbolsAfterComma(Number(amount)) > 7? Number(amount).toFixed(7) : amount} </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> { symbolsAfterComma(Number(price)) > 7? Number(price).toFixed(7) : price} </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> { symbolsAfterComma(item.filled) > 7?  item.filled.toFixed(7): item.filled }% </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> { symbolsAfterComma(Number(total)) > 7? Number(total).toFixed(7) : total} </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> — </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> {parseDate(item.createdAt)} </ActiveOrderTableBodyItem>
            <ActiveOrderTableBodyItem> { item.filled > 0? <span> — </span>  : <span onClick={() => cancelOrder()} style={{color: '#EB5746', fontWeight: 600, cursor: "pointer"}}> Cancel me </span>}  </ActiveOrderTableBodyItem>
        </TableRow>
    )
}

const ActiveOrdersTable :FC = () => {

    const [activeOrders, setActiveOrders] = useState<IActiveOrder[]>()

    const getOrders = async () => {
        const res = await getActiveOrders()
        setActiveOrders(res)
        MarketOrders.activeOrders = res.length
    }

    useEffect(()=> {
        getOrders().then(() => console.log('greetings'))
        const interval = setInterval(() => {
            getOrders().then(() => console.log('greetings'))
        },3000)

        return  function cleanup (){
            clearInterval(interval)
        }
    } ,[])

    return (
        <ActiveOrderTableWrapper>
            <ActiveOrderTableHead>
                <TableRow>
                    <ActiveOrderTableHeadItem>Pair</ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem>Type</ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem>Exchange</ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem>Amount</ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem>Price</ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem>Filled</ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem>Total</ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem> Triggered Condition </ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem>Date</ActiveOrderTableHeadItem>
                    <ActiveOrderTableHeadItem> Action </ActiveOrderTableHeadItem>
                </TableRow>
            </ActiveOrderTableHead>
            <ActiveOrderTableBody>
              {activeOrders?.length && activeOrders.length > 0?
                <span> {activeOrders?.map(order => <TableItem item={order} key={order.createdAt}/>)} </span>
                : <NoAvailableItems > You have no active orders </NoAvailableItems>
              }

            </ActiveOrderTableBody>
        </ActiveOrderTableWrapper>
    );
};

export default ActiveOrdersTable;