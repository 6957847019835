import {action, makeAutoObservable} from "mobx";
import {ICoin} from "../../types/exchange/pair.types";
import {getCoins} from "../../services/api/exchange/request";

class Coins{
    coins: ICoin[] = []

    async getCoins(){
        this.coins = await getCoins()
    }

    clear(){
        this.coins = []
    }
    constructor() {
        makeAutoObservable(this, {
            getCoins: action
        })
    }
}

export default new Coins()