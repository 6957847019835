import React, {FC} from 'react';
import Spacer from "../../../../ui/spacer/spacer";
import {CopyRight, ResetForm, ResetFormMainTitle, ResetFormSubTitle, ResetFormWrapper } from "./reset-password-wrapper.styled";

const ResetPasswordWrapper :FC = ({children}) => {
    return (
        <ResetFormWrapper>
            <ResetFormMainTitle>
                New password
            </ResetFormMainTitle>

            <ResetFormSubTitle>
                Please enter a new strong password. Your new password must be different from previous passwords.
            </ResetFormSubTitle>
            <ResetForm>
                {children}
            </ResetForm>

            <Spacer/>

            <CopyRight>
                © 2023 Unicsoft. All rights reserved.
            </CopyRight>
        </ResetFormWrapper>
    );
};

export default ResetPasswordWrapper;