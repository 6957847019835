import React, {FC, useEffect, useState} from 'react';
import Spacer from "../../../ui/spacer/spacer";
import Select from "../../../../public/icons/select";
import TableTimeItems from "./table-time-items/table-time-items";
import PairsSelect from "../../PairSelect/pairs-select";
import {
  CryptoChanges,
  CryptoPair, CryptoPrice,
  MainCoinImage,
  MainCoinName,
  OptionsWrapper,
  PairSelect,
  Price,
  SelectBtn
} from "./styled-table-options";
import {observer} from "mobx-react-lite";
import Pairs from "../../../../store/pairs/pairs";
import {IPair} from "../../../../types/exchange/pair.types";
import {CryptoNames} from "../../../../utils/constants/crypto";

const TableOptions :FC = observer( () => {
  const [isSelecting, setSelecting] = useState<boolean>(false)
  const [currentPair, setCurrentPair] = useState<IPair>()
  //TODO remove all ts-ignores
  const [isLoading , setLoading] = useState<boolean>(true)

  useEffect(() => {
    (async function (){
      if(Pairs.pairs.length === 0) await Pairs.getPairs()

      setCurrentPair(Pairs.currentPair as IPair)
      setLoading(false)
    })()
  },[Pairs.currentPair])

  if(isLoading){
    return (<div/>)
  }

  return (
    <OptionsWrapper>
      <CryptoPair>
        <PairSelect onClick={() => setSelecting(prev => !prev)}>
          {currentPair?.fromCoin.symbol}/{currentPair?.toCoin.symbol}
          <SelectBtn> <Select/></SelectBtn>
          {isSelecting && <PairsSelect/>}
        </PairSelect>
        <MainCoinName>
          {currentPair?.fromCoin &&
          <span>
            <MainCoinImage>
              <img alt='Image of crypto' style={{height: '12px', width: '12px'}} src={CryptoNames[currentPair.fromCoin.symbol] && CryptoNames[currentPair.fromCoin.symbol].img} />
            </MainCoinImage>
            {CryptoNames[currentPair.fromCoin.symbol].fullname}
          </span>
          }
        </MainCoinName>
      </CryptoPair>
      <Price className="">
        {/*@ts-ignore*/}
        <CryptoPrice> {parseFloat( currentPair?.fromCoin[`${currentPair?.toCoin.symbol.toLowerCase()}Price`] )} {currentPair?.toCoin.symbol}</CryptoPrice>
        <CryptoChanges> (-1,474.55 -3.68%) <span style={{color: "rgba(255, 255, 255, 0.6)", marginLeft: '2px'}}> Today </span>  </CryptoChanges>
      </Price>
      <Spacer/>
      <TableTimeItems/>
    </OptionsWrapper>
  );
});

export default TableOptions;