import React, {FC} from 'react'
import Spacer from "../../../../ui/spacer/spacer"
import {CryptoItem, CryptoItemCoinName, CryptoItemTitle, CryptoLogo} from './styled-crypto-item'

interface ICryptoCoinItem{
    logoSrc: string,
    graphSrc: string,
    title: string,
    name: string,
    opacity: number,
    logoBorderRadius?: string
}

const CryptoCoinItem :FC<ICryptoCoinItem> = ({logoSrc, graphSrc, title, name, opacity, logoBorderRadius}) => {
    return (
        <>
            <CryptoItem style={{opacity}}>
                <CryptoLogo logoBorderRadius={logoBorderRadius} src={logoSrc}/>
                <CryptoItemTitle> {title} </CryptoItemTitle>
                <CryptoItemCoinName> {name} </CryptoItemCoinName>
                <Spacer/>
                <img alt='Crypto item image' src={graphSrc}/>
            </CryptoItem>
        </>
    )
}

export default CryptoCoinItem