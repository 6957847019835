import React, {FC} from 'react';
import LoginLayout from "../../../components/layouts/login/login-layout";
import Mail from "../../../public/email-verification/mailbox-img.png";
import {
    Button,
    CopyRight,
    DescriptionWrapper,
    EmailVerificationWrapper,
    Row,
    Subtitle,
    Title
} from "./styled-success-email-page";
import {useHistory} from "react-router-dom";
import {
    ImageBackground,
    ImageBlock,
    MailImage
} from "../../../components/auth/email-verification/plain-image/styled-plain-image";



const SuccessEmailPage :FC = () => {
    const history = useHistory()

    return (
        <LoginLayout>
            <EmailVerificationWrapper>
                <ImageBlock>
                    <ImageBackground>
                        <MailImage src={Mail}/>
                    </ImageBackground>
                </ImageBlock>
                <DescriptionWrapper>
                    <Title>
                        Thank you!
                    </Title>
                    <Subtitle>
                        Your email address has been successfully verified.
                    </Subtitle>
                    <Row>
                        <Button onClick={() => history.push('/login')}>
                            Login to Unicsoft
                        </Button>
                    </Row>
                </DescriptionWrapper>
                <div style={{marginTop: '30%'}}>
                    <CopyRight>
                        © 2021 Unicsoft. All rights reserved.
                    </CopyRight>
                </div>
            </EmailVerificationWrapper>
        </LoginLayout>
    );
};

export default SuccessEmailPage;