import React, {FC} from 'react';
import styled from "styled-components";
import SidebarMarketBlock from "./sidebar-trade-block/sidebar-trade-block";
import SidebarNewsBlock from "./sidebar-news-block/sidebar-news-block";

const SidebarWrapper = styled.div`
  flex: 0 1 25%;
  height: calc(93vh - 22px);
`

const SideBarContent = styled.div`
  height: calc(93vh - 22px);
  display: block;
  margin-right: 4px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none; //Chrome, Safari and Opera
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

const Sidebar: FC = () => {
    return (
        <SidebarWrapper>
            <SideBarContent>
                <SidebarMarketBlock/>
                <SidebarNewsBlock/>
            </SideBarContent>
        </SidebarWrapper>
    );
};

export default Sidebar;