import styled from "styled-components";

export const HeaderStyle = styled.div`
  display: flex;
  margin-top: 48px;
  flex-grow: 1;
  font-size: 21px;
  margin-bottom: 96px;
  height: 41px;
  font-weight: 600;
`

export const LogoWrapper = styled.span`
  margin-right: 10px;
`