import styled from "styled-components";

export const BalanceCryptoWrapper = styled.div`
  display: block;
  height: 100%;
`

export const BalanceItems = styled.div`
  height: calc(100% - 50px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; 
  }
  -ms-overflow-style: none;  
  scrollbar-width: none;  
`

export const ButtonBlock = styled.div`
  display: flex;
  height: 50px;
  padding: 8px;
`

interface IButton{
    color: string
}

export const Button = styled.button<IButton>`
  border: none;
  outline: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  background: ${props => props.color};
  flex: 0 1 50%;
  border-radius: 4px;
  cursor: pointer;
`