import React from 'react';

const Btc = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.6408 14.9029C22.0379 21.3315 15.5261 25.2438 9.09602 23.6407C2.66858 22.038 -1.24421 15.5266 0.359461 9.09838C1.96163 2.66907 8.47345 -1.24364 14.9016 0.359095C21.3313 1.96183 25.2437 8.47401 23.6408 14.9029Z" fill="#F7931A"/>
            <path d="M17.2896 10.2902C17.5285 8.69351 16.3126 7.83515 14.6501 7.26253L15.1894 5.09955L13.8726 4.77143L13.3476 6.87741C13.0014 6.79116 12.6459 6.70978 12.2926 6.62916L12.8214 4.5093L11.5054 4.18118L10.9657 6.34341C10.6791 6.27816 10.3979 6.21366 10.1248 6.14579L10.1263 6.13904L8.3104 5.68567L7.96011 7.0919C7.96011 7.0919 8.93709 7.31578 8.91646 7.32965C9.44977 7.46277 9.54615 7.81565 9.53003 8.09539L8.91571 10.5595C8.95247 10.5689 9.0001 10.5824 9.0526 10.6034C9.00872 10.5925 8.96184 10.5805 8.91346 10.5689L8.05237 14.0207C7.98711 14.1827 7.82172 14.4257 7.44893 14.3335C7.46206 14.3526 6.49183 14.0946 6.49183 14.0946L5.83813 15.6017L7.55169 16.0288C7.87048 16.1087 8.18288 16.1923 8.49042 16.2711L7.94548 18.4588L9.26075 18.7869L9.80043 16.6224C10.1597 16.7199 10.5085 16.8099 10.8498 16.8947L10.312 19.049L11.6288 19.3772L12.1737 17.1936C14.419 17.6184 16.1075 17.447 16.8182 15.4164C17.3909 13.7815 16.7897 12.8383 15.6083 12.2234C16.4686 12.025 17.1167 11.4591 17.2896 10.2902ZM14.281 14.5086C13.8741 16.1436 11.1209 15.2597 10.2284 15.0381L10.9514 12.1397C11.844 12.3625 14.7063 12.8035 14.281 14.5086ZM14.6883 10.2666C14.317 11.7539 12.0255 10.9982 11.2822 10.813L11.9378 8.18427C12.6811 8.36952 15.075 8.71526 14.6883 10.2666Z" fill="white"/>
        </svg>
    );
};

export default Btc;