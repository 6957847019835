import {makeAutoObservable} from "mobx";
import {ITradeHistoryItem} from "../../types/orders/trade-history.types";
import {getTradeHistory} from "../../services/api/orders/trade-history";

class TradeHistory {

  tradeHistory: ITradeHistoryItem[] = []
  constructor() {
    makeAutoObservable(this)
  }

  async getTradeHistory(dateFrom:Date, dateTo:Date){
    this.tradeHistory = await getTradeHistory(dateFrom, dateTo)
  }
  clear(){
    this.tradeHistory = []
  }
}

export default new TradeHistory()