import {IActiveOrder} from "../../../types/orders/active-order.types";
import {getToken} from "../../app/cookie/cookie";

export async function getActiveOrders():Promise<IActiveOrder[]>{
    const headers = new Headers();
    headers.append("Authorization", getToken());

    const requestOptions = {
        method: 'GET',
        headers: headers,
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/orders/active`, requestOptions)

    return res.json()
}

export async function cancelLimitOrder(id:number){
    const headers = new Headers();
    headers.append("Authorization", getToken());

    const requestOptions = {
        method: 'PATCH',
        headers: headers,
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/limit-order/${id}`, requestOptions)

    return res.json()
}