import React, {FC} from 'react';
import Spacer from "../../spacer/spacer";
import {DangerWrapper, Title, Error, DangerBtn, TotalRow, Value, Purchased, Row, SubTitle} from "./styled-danger-modal";

interface IDangerExchange{
    purchased: number;
    purchasedSymbol: string;
    spent: number;
    spentSymbol: string;
    fee: number;
    feeType: "PERCENTS" | "FIXED";
    closeModal: () => void;
    error: string;
}

const DangerModal :FC<IDangerExchange> = ({spent, closeModal , spentSymbol, purchasedSymbol, fee, feeType, purchased, error }) => {
    return (
        <DangerWrapper>
            <Title> Error transfer </Title>
            <Error>
                {error}
            </Error>
            <Purchased> Purchased {purchased} {purchasedSymbol}  </Purchased>
            <Row>
                <SubTitle> Spent </SubTitle>
                <Spacer/>
                <Value> {spent} {spentSymbol} </Value>
            </Row>
            <Row>
                <SubTitle> Transaction Fee {feeType === 'PERCENTS'&& <span>({fee}%)</span> } </SubTitle>
                <Spacer/>
                <Value> {feeType === 'PERCENTS'? spent * fee : fee} {spentSymbol} </Value>
            </Row>
            <TotalRow>
                <span>Total</span>
                <Spacer/>
                <span> {(spent* fee) + spent} {spentSymbol}</span>
            </TotalRow>

            <Row style={{marginTop: '32px'}}>
                <Spacer/>
                <DangerBtn onClick={() => closeModal()}> Got it </DangerBtn>
            </Row>
        </DangerWrapper>
    );
};

export default DangerModal;