import React, {FC, useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";
import Pairs from "../../../store/pairs/pairs";
import {toJS} from "mobx";
import {Coins} from "../../../utils/constants/crypto";
import {
    HighlightedText,
    PairLastPrice, PairRow,
    PairSelectWrapper,
    PairTitle,
    SearchInput,
    TableHeader
} from "./styled-pair-select";

const PairsSelect :FC = observer( () => {

    const pairs = Pairs.pairs.filter((pair) => {
      return !(pair.fromCoin.symbol === Coins.USD || pair.toCoin.symbol === Coins.USD);
    })

    const [search, setSearch] = useState<string>('')

    const filteredPairs = useMemo(() => {
      return toJS(pairs.filter(el => `${el.fromCoin.symbol}/${el.toCoin.symbol}`.includes(search.toUpperCase())))
    }, [pairs, search])

    const highLightSearchLetters = (pairTitle: string, search: string) => {
        if(search === '') return pairTitle
      search = search.toUpperCase()
        if(pairTitle.includes(search)){
            const array =  pairTitle.split(search)
           return (
               <span>
                   { array[0] }
                   <HighlightedText>{search}</HighlightedText>
                   { array[1]}
               </span>
           )
        }
    }

    useEffect(()=> {
        (async function (){
            if(Pairs.pairs.length === 0){
                await Pairs.getPairs()
            }
        })()

    } ,[])

    return (
        <PairSelectWrapper>
            <div className="">
                <SearchInput onClick={(e) => e.stopPropagation()} placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)}/>
            </div>
            <TableHeader>
                <PairTitle> Pair </PairTitle>
                <PairLastPrice> Last Price </PairLastPrice>
                {/*<PairChange> 24h Change </PairChange>*/}
            </TableHeader>
            {filteredPairs.map(pair =>
                <PairRow onClick={() => {Pairs.currentPair = pair}} key={pair.createdAt}>
                    <PairTitle>
                        {highLightSearchLetters(`${pair.fromCoin.symbol}/${pair.toCoin.symbol}`, search)}
                    </PairTitle>
                    <PairLastPrice>
                        {/*@ts-ignore*/}
                        {pair.fromCoin[`${pair.toCoin.symbol.toLowerCase()}Price`]}
                    </PairLastPrice>
                    {/*<PairChange>*/}
                    {/*    <i>Coming soon</i>*/}
                    {/*</PairChange>*/}
                </PairRow>
            )}
        </PairSelectWrapper>
    );
});

export default PairsSelect;