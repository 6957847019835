import styled from "styled-components";

export const CryptoItem = styled.div`
  background: #27333E;
  box-shadow: 0 19px 20px -13px rgba(16, 16, 16, 0.6);
  border-radius: 8px;
  max-width: 370px;
  padding: 12px 18px 12px 18px;
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`

export const CryptoItemTitle = styled.div`
  margin-left: 18px;
`

export const CryptoItemCoinName = styled.div`
  margin-left: 25px;
  color: rgba(255, 255, 255, 0.6);
`

interface ICryptoLogo{
    logoBorderRadius?: string
}

export const CryptoLogo = styled.img<ICryptoLogo>`
  border-radius: ${props => props.logoBorderRadius? props.logoBorderRadius: ''};
  width: 24px;
  height: 24px;
`
