import styled from "styled-components";

export const TotalBalanceWrapper = styled.div`
  display: block;
  padding: 16px;
  margin-right: 32px;
`

export const TotalBalanceHeader = styled.div`
  display: flex;
  color: rgba(255, 255, 255, 0.4);
  font-size: 18px;
  padding-bottom: 8px;
`

export const TotalBalanceCryptoRow = styled.div`
  display: flex;
`

export const TotalCrypto = styled.div`
  display: flex;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 36px;
`

export const TotalUSD = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-left: 12px;
`

export const RateRow = styled.div`
  display: flex;
`

interface IRateAmount {
  percent: number
}

export const RateAmount = styled.div<IRateAmount>`
  font-size: 18px;
  color: ${props => props.percent > 0? '#31BC83;' : '#EB5746;'};
`

export const RateTime = styled.div`
  font-size: 13px;
  color: #767A7E;
  display: flex;
  align-items: center;
  margin-left: 5px;
`