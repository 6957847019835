import React, {FC, useState} from 'react'
import './custom-select.css'
import {ArrowDown} from "../../../market/sidebar/sidebar-trade-block/buy-block/buy-block"

interface ISelect{
    disabled?: boolean,
    items: string[],
    handleItemClick: (e:any) => void,
    selectedItem: string
}

const CustomSelect :FC<ISelect> = ({disabled, items, handleItemClick, selectedItem}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggleDropdown = () => setOpen(!isOpen);
  if(disabled){
    return (
      <div className='dropdown-disabled'>
        <div className='dropdown-header' onClick={toggleDropdown}>
          Unicsoft USD Wallet
          <div className="fa fa-chevron-right icon"> <ArrowDown isOpened={false}/> </div>
        </div>
      </div>
    )
  }

  return (
    <div className='dropdown'>
      <div className='dropdown-header' onClick={toggleDropdown}>
        {selectedItem && selectedItem}
        <div className="fa fa-chevron-right icon"> <ArrowDown isOpened={isOpen}/> </div>
      </div>
      <div className={`dropdown-body ${isOpen && 'open'}`}>
        {items.map(item => (
          <div key={item} className="dropdown-item" onClick={e => handleItemClick(e)} id={String(item)}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomSelect