import React, {FC} from 'react';
import Spacer from "../../../../ui/spacer/spacer";
import {PairTabItemWrapper} from "../../styled-favorite-pairs";
import {
  ActiveTab,
  TabItemTitle
} from "../../../../market/sidebar/sidebar-trade-block/SidebarTabs/SidebarTabItem/sidebar-tab-item";

interface ITab{
  activeTab: string;
  setActiveTab: (value: 'All'| 'Favorites') => void;
  name: 'All'| 'Favorites'
}

const PairTabItem :FC<ITab> = ({activeTab, setActiveTab, name}) => {
  return(
    <PairTabItemWrapper onClick={() => setActiveTab(name)}>
        <TabItemTitle isActive={activeTab === name}>{name}</TabItemTitle>
        <ActiveTab isActive={activeTab === name}/>
    </PairTabItemWrapper>
  )
}

const Tabs :FC<ITab> = ({activeTab, setActiveTab}) => {
  return (
    <>
      <div style={{display:'flex', marginTop: '10px'}}>
        <Spacer/>

        <PairTabItem name={'All'} activeTab={activeTab} setActiveTab={setActiveTab}/>
        <PairTabItem name={'Favorites'} activeTab={activeTab} setActiveTab={setActiveTab}/>
      </div>
      <div style={{width: '100%', height: '1px', backgroundColor: '#212B33'}}/>
    </>
  );
};

export default Tabs;