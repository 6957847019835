import React, {FC,  useState} from 'react';
import Default from "../../components/layouts/default/default";
import styled from "styled-components";
import Header from "../../components/exchange/header/header";
import Form from "../../components/exchange/form/form-sell/form-sell";
import History from "../../components/exchange/history/history";
import {observer} from "mobx-react-lite";
import FormBuy from "../../components/exchange/form/form-buy/form-buy";
import AppBar from "../../components/global/app-bar/app-bar";
import Content from "../../components/global/content-wrapper/content-wrapper";


const ExchangePageWrapper = styled.div`
  display: flex;
  margin-left: 25%;
  margin-right: 25%;
  height: 100%;
`

const ExchangePageContent = styled.div`
  padding-top: 22px;
  flex: 0 1 100%;
  min-height: 93vh;
  max-height: 100%;
`

type Tabs = 'Buy' | 'Sell'

const ExchangeCrypto :FC = observer( () => {

    const [tab, setTab] = useState<Tabs>('Buy')

    const tabs = ['Buy', 'Sell']

    const setCurrentTab = (value: string) => {
        if(value === 'Buy')  setTab('Buy')
        if(value === 'Sell')  setTab('Sell')
    }

    return (
        <Default>
            <AppBar/>
            <Content>
                <ExchangePageWrapper>
                    <ExchangePageContent>
                        <Header tabs={tabs} currentTab={tab} setCurrentTab={setCurrentTab} />
                        {tab === 'Buy' && <FormBuy/>}
                        {tab === 'Sell' && <Form/>}
                        <History/>
                    </ExchangePageContent>
                </ExchangePageWrapper>
            </Content>
        </Default>
    );
});

export default ExchangeCrypto;