import React, {FC, useState} from 'react';
import {observer} from "mobx-react-lite";
import {DropDownItem} from "../../../../../types/market/dropdown";
import MarketSidebar from "../../../../../store/market-sidebar/market-sidebar";
import Spacer from "../../../../ui/spacer/spacer";
import DropdownSelect from "../dropdown-select/dropdown-select";
import MarketType from "../Shared/market-type/market-type";
import styled from "styled-components";
import LimitType from "../Shared/limit-type/limit-type";
import StopLimitType from "../Shared/stop-limit-type/stop-limit-type";
import StopLossType from "../Shared/stop-loss-type/stop-loss-type";
import TakeProfit from "../Shared/take-profit-type/take-profit";

const CustomSelect = styled.div`
  display: flex;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  margin-top: 24px;
  color:white;
  align-items: center;
  cursor: pointer;
  background: #26323C;
  position: relative;
`

interface IArrowDown{
    isOpened: boolean
}

const ArrowDown = styled.span<IArrowDown>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  display: flex;
  align-items: center;
  margin-left: 11px;
  border-radius: 30%;
  transform: ${props => props.isOpened? 'rotateZ(180deg)' : 'none'};
`

const SellBlock :FC = observer( () => {

    const [isOpened, setOpened] = useState<boolean>(false)

    const handleSelect = (item: DropDownItem) => {
        MarketSidebar.setActiveTypeAction(item)
        setOpened(false)
    }

    return (
        <div>
            <CustomSelect>
                <span>Type</span>
                <Spacer/>
                <span style={{display:'flex', alignItems:'center'}} onClick={() => setOpened(prev => !prev)}>
                    <span>{MarketSidebar.activeTypeAction.title}</span>
                    <ArrowDown isOpened={isOpened} />
                </span>
                { isOpened && <DropdownSelect onSelect={handleSelect}/> }
            </CustomSelect>

            {MarketSidebar.activeTypeAction.value === 'market' && <MarketType type={'sell'}/>}
            {MarketSidebar.activeTypeAction.value === 'limit' && <LimitType type={'sell'}/>}
            {MarketSidebar.activeTypeAction.value === 'stop_limit' && <StopLimitType type={'sell'}/>}
            {MarketSidebar.activeTypeAction.value === 'stop_loss' && <StopLossType type={'sell'}/>}
            {MarketSidebar.activeTypeAction.value === 'take_profit' && <TakeProfit type={'sell'}/>}
        </div>
    );
})

export default SellBlock;