import React, {FC, useEffect} from 'react';
import WalletTotal from "../../../../store/wallet-total/wallet-total";
import {observer} from "mobx-react-lite";
import {toCoin} from "../../../../utils/tools/calcs";
import {Coins} from "../../../../utils/constants/crypto";
import {TotalBalanceCryptoRow, TotalBalanceWrapper, TotalCrypto, TotalBalanceHeader} from "./total-balance.styled";

const TotalBalance :FC = observer( () => {

  const totalBalance = String( toCoin( WalletTotal.totalBalance, Coins.USD) )

  useEffect(() => {
    (async function() {
      await WalletTotal.setTotalBalance()
    })()
  },[])

  return (
    <TotalBalanceWrapper>
      <TotalBalanceHeader>
        Total Balance
      </TotalBalanceHeader>
      <TotalBalanceCryptoRow>
        {/*<TotalCrypto>*/}
        {/*    1.762981 BTC*/}
        {/*</TotalCrypto>*/}
        {/*<TotalUSD>*/}
        {/*    /!*≈ *!/*/}
        {/*    {totalBalance} $*/}
        {/*</TotalUSD>*/}
        <TotalCrypto>
          {totalBalance} $
        </TotalCrypto>
      </TotalBalanceCryptoRow>
      {/*<RateRow>*/}
      {/*    <RateAmount percent={-1}> -3.68% (-2 187.37 $) </RateAmount>*/}
      {/*    <RateTime>Today</RateTime>*/}
      {/*</RateRow>*/}
    </TotalBalanceWrapper>
  );
});

export default TotalBalance;