import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {
    CheckBox,
    Field,
    FieldTitle,
    InputGroup, SignInButton, SignUpRow, StayLoggedIn,
} from "./registration-form.styled";
import {useHistory} from 'react-router-dom'
import Password from "../../../../public/icons/password";
import {RegistrationForm as IRegistrationForm} from "../../../../types/registration/registration-form.types";
import {registerUser} from "../../../../services/api/registration/requests";
import * as Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import EmailVerification from "../../../../store/email-verification/email-verification";

type PasswordType = 'text' | 'password'

const RegistrationFormSchema = Joi.object({
    name: Joi.string().required().min(3).max(40),
    lastName: Joi.string().required().min(3).max(40),
    password: Joi.string().required().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
    confirmPassword: Joi.string().required().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/).valid(Joi.ref('password')),
    terms: Joi.boolean().required().invalid(false),
    privatePolicy: Joi.boolean().required().invalid(false),
    email: Joi.string().email({ tlds: { allow: false } })
})

const RegistrationForm = () => {
    const history = useHistory()

    const {register, handleSubmit, formState} = useForm<IRegistrationForm>({
        resolver: joiResolver(RegistrationFormSchema),
        mode: "onChange"
    })

    const  {errors, isValid} = formState

    const [isBlocked,setBlocked] = useState<boolean>(false)
    useEffect(() => {
        console.log(errors, isValid)
    }, [errors, isValid])

    const onSubmit : SubmitHandler<IRegistrationForm> = async data => {
        try{
            if(data.password !== data.confirmPassword) return
            setBlocked(true)
            await registerUser(data)
            EmailVerification.email = data.email
            await history.push('/email-verification')
        }catch (e){
            console.log(e)
            setTimeout(() => {
                setBlocked(false)
            },1000)
        }
    };

    const [passwordType, setPasswordType] = useState<PasswordType>('password')

    const togglePasswordType = () =>{
        passwordType === 'text' ? setPasswordType('password') : setPasswordType('text')
    }

    const [confirmPasswordType, setConfirmPasswordType] = useState<PasswordType>('password')

    const toggleConfirmPasswordType = () =>{
        confirmPasswordType === 'text' ? setConfirmPasswordType('password') : setConfirmPasswordType('text')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{display:"flex", flexDirection:'row'}}>
                <InputGroup>
                    <FieldTitle>
                        Name
                    </FieldTitle>
                    <Field {...register('name')} type='text' placeholder='Enter name' />
                </InputGroup>
                <InputGroup style={{marginLeft:'5px'}}>
                    <FieldTitle>
                        Last name
                    </FieldTitle>
                    <Field {...register('lastName')} type='text' placeholder='Enter last name' />
                </InputGroup>
            </div>
            <InputGroup>
                <FieldTitle>
                    Email
                </FieldTitle>
                <Field {...register('email')} type='email' placeholder='Enter Email' />
            </InputGroup>

            <InputGroup>
                <FieldTitle>
                    Password
                </FieldTitle>
                <div style={{position:'relative'}}>
                    <Field {...register('password')} type={passwordType} placeholder='Password' />
                    <div className="icon" style={{position:"absolute", right:'12px', top: '12px'}} onClick={() => togglePasswordType()}>
                        <Password/>
                    </div>
                </div>
            </InputGroup>

            <InputGroup>
                <div style={{position:'relative'}}>
                    <Field {...register('confirmPassword')} type={confirmPasswordType} placeholder='Confirm Password' />
                    <div className="icon" style={{position:"absolute", right:'12px', top: '12px'}} onClick={() => toggleConfirmPasswordType()}>
                        <Password/>
                    </div>
                </div>
            </InputGroup>

            <StayLoggedIn>
                    <CheckBox type='checkbox' {...register('terms')}/>
                  <span> I confirm that I act on my own behalf and have read and accept the <span style={{fontWeight:600}}> Unicsoft Terms and conditions </span></span>
            </StayLoggedIn>

            <StayLoggedIn style={{marginTop:'3px'}}>
                <CheckBox type='checkbox' {...register('privatePolicy')}/>
                <span> I confirm that I have read and agree to the  <span style={{fontWeight:600}}> Unicsoft Privacy Policy </span></span>
            </StayLoggedIn>

            <SignInButton type='submit' disabled={!isValid && !isBlocked}>
                Sign up
            </SignInButton>

            <SignUpRow>
                <span> Already have account? <span onClick={() => history.push('/login')} style={{fontWeight: 600, cursor: "pointer"}}>   Sign in   </span> </span>
            </SignUpRow>
        </form>
    );
};

export default RegistrationForm;