import styled from "styled-components";

export const OrderHistoryTable = styled.table`
  display: block;
  width: 100%;
  margin-top: 16px;
`

export const OrderHistoryTableHead = styled.thead`
  display: flex;
`

export const OrderHistoryTableHeadItem = styled.th`
  display: flex;
  flex: 1 1 100%;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  font-weight: 400;
`

export const OrderHistoryTableRow = styled.tr`
  display: flex;
  flex: 0 1 100%;
`

export const OrderHistoryTableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  min-height: 250px;
  padding-bottom: 25px;
`
export const OrderHistoryTableBodyItem = styled.td`
  display: flex;
  flex: 1 1 100%;
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  margin-top: 17px;
  padding-bottom: 4px;
  border-bottom: 1px solid #151A1E;
  padding-left: 3px;
  padding-right: 3px;
  text-align: left;
`

export const NoOrderHistory = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
`