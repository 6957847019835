import React, { FC, useRef, useState} from 'react';
import styled from "styled-components";

const InputWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`

const InputLabel = styled.div`
    color: white;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 8px;
`

interface IDisabled{
    disabled?: boolean,
    hover: boolean
}

const CustomInput = styled.input<IDisabled>`
  padding: 8px 6px 8px 0;
  background: ${props => (props.disabled || props.hover)? '#1B2228' : '#26323C'} ;
  outline: none;
  color: ${props => props.disabled? 'rgba(255, 255, 255, 0.4)' : '#ffffff'} ;
  text-align: right;
  border: none;
  border-top: 1px solid #26323c;
  border-bottom: 1px solid #26323c;
  width: 100%;
  font-size: 13px;
  &::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
`

const CustomInputWrapper = styled.div`
  display: flex;
`
const CustomPlaceholder = styled.span<IDisabled>`
  display: flex;
  color: rgba(255, 255, 255, 0.4);
  background: ${props => (props.disabled || props.hover)? '#1B2228' : '#26323C'};
  border: none;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 8px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font-weight: 600;
  font-size: 13px;
  flex-wrap: nowrap;
  min-width: 30%;
  border-left: 1px solid #26323C;
  border-top: 1px solid #26323C;
  border-bottom: 1px solid #26323C;
`

const EndPlaceholder = styled.span<IDisabled>`
  display: block;
  color: ${props => props.disabled? 'rgba(255, 255, 255, 0.4)' : '#ffffff'};
  background: ${props => (props.disabled || props.hover)? '#1B2228' : '#26323C'};
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 13px;
  text-align: right;
  border-right: 1px solid #26323C;
  border-top: 1px solid #26323C;
  border-bottom: 1px solid #26323C;
`

interface IInput{
    placeholder : string,
    label?: string,
    disabled?: boolean
    endPlaceholder? : string,
    // value: string | number,
    // onInput: (e:ChangeEvent<HTMLInputElement>) => void,
    type: 'text' | 'number',
    formikProps?: any
}

const Input :FC<IInput> = ({placeholder, label, disabled, endPlaceholder, type, formikProps}) => {

    const [isHovered, setHovered] = useState<boolean>(false)
    const ref = useRef<HTMLInputElement>(null)

    const setFocus = () => {
        try{
            if(!!ref) { // @ts-ignore
                ref.current.focus()
            }
        }catch (e){
            console.log(e)
        }

    }

    return (
        <InputWrapper onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {label && <InputLabel> {label} </InputLabel> }
            <CustomInputWrapper>
                <CustomPlaceholder onClick={() => setFocus()} hover={isHovered} disabled={disabled}>{placeholder}</CustomPlaceholder>
                <CustomInput autocomplete="off" onWheel={(e) => {e.preventDefault()}} onScroll={(e) => {e.preventDefault()}} min='0' step='0.00000001' ref={ref} hover={isHovered} {...formikProps} type={type} disabled={disabled}/>
                {endPlaceholder && <EndPlaceholder onClick={() => setFocus()} hover={isHovered} disabled={disabled}>{endPlaceholder}</EndPlaceholder> }
            </CustomInputWrapper>
        </InputWrapper>
    );
};

export default Input;