import React, {FC} from 'react';
import {
    RegistrationCopyRight,
    RegistrationForm,
    RegistrationFormMainTitle, RegistrationFormSubTitle,
    RegistrationFormWrapper

} from "./registration-wrapper.styled";
import Spacer from "../../../../ui/spacer/spacer";

const RegistrationWrapper :FC = ({children}) => {
    return (
        <RegistrationFormWrapper>
            <RegistrationFormMainTitle>
                Registration
            </RegistrationFormMainTitle>

            <RegistrationFormSubTitle>
                Open the door to a world of unlimited cryptocurrency trading opportunities
            </RegistrationFormSubTitle>
            <RegistrationForm>
                {children}
            </RegistrationForm>

            <Spacer/>

            <RegistrationCopyRight>
                © 2023 Unicsoft. All rights reserved.
            </RegistrationCopyRight>
        </RegistrationFormWrapper>
    );
};

export default RegistrationWrapper;