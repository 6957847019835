import React, { FC, useRef, useState} from 'react';
import styled from "styled-components";

const InputWrapper = styled.div`
  //margin-left: 10px;
  //margin-right: 10px;
`

const InputLabel = styled.div`
    color: white;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 8px;
`

interface IDisabled{
    disabled?: boolean,
    hover: boolean
}

const CustomInput = styled.input<IDisabled>`
  padding: 8px 6px 8px 0;
  background: ${props => (props.disabled || props.hover)? '#1B2228' : '#26323C'} ;
  outline: none;
  color: ${props => props.disabled? 'rgba(255, 255, 255, 0.4)' : '#ffffff'} ;
  text-align: right;
  border-left: 1px solid #26323c;
  border-bottom: 1px solid #26323c;
  border-top: 1px solid #26323c;
  border-right: none;
  
  width: 100%;
  font-size: 13px;
  &::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

const CustomInputWrapper = styled.div`
  display: flex;
`

const EndPlaceholder = styled.span<IDisabled>`
  display: block;
  color: ${props => props.disabled? 'rgba(255, 255, 255, 0.4)' : '#ffffff'};
  background: ${props => (props.disabled || props.hover)? '#1B2228' : '#26323C'};
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 13px;
  text-align: right;
  border-right: 1px solid #26323C;
  border-top: 1px solid #26323C;
  border-bottom: 1px solid #26323C;
`


interface IInput{
    label?: string,
    disabled?: boolean
    type: 'text' | 'number',
    endPlaceholder? : string,
    formikProps?: any,
    min? : number,
    max? : number
}

const Input :FC<IInput> = ({ label, disabled, type, formikProps, endPlaceholder, min, max}) => {

    const [isHovered, setHovered] = useState<boolean>(false)
    const ref = useRef<HTMLInputElement>(null)

    return (
        <InputWrapper onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {label && <InputLabel> {label} </InputLabel> }
            <CustomInputWrapper>
                <CustomInput min={min} max={max} ref={ref} hover={isHovered} {...formikProps} type={type} disabled={disabled}/>
                {endPlaceholder && <EndPlaceholder hover={isHovered} disabled={disabled}>{endPlaceholder}</EndPlaceholder> }
            </CustomInputWrapper>
        </InputWrapper>
    );
};

export default Input;