import styled from "styled-components";

export const TableTimeSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

interface IColor{
    color: string
}

export const TableTimeItem = styled.div`
  padding-right: 24px;
  cursor: pointer;
`

export const TableTimeSelectTitle = styled.div<IColor>`
  display: flex;
  flex-direction: column;
  color: ${props => props.color};
  width: 34px;
`

interface ITableTimeSelectedTab{
    isActive: boolean
}

export const TableTimeSelectedTab = styled.div<ITableTimeSelectedTab>`
  background: #F9C510;
  height: ${props => props.isActive? '2px': 0};
  width: 34px;
  display: block;
  flex: 0 1 100%;
  margin-top: ${props => props.isActive? '12px' : '14px'};
`

