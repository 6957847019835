import React, {FC} from 'react';
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import MarketBottomBar from "../../../store/market-orders/market-orders";
import {TABS} from "../../../utils/constants/tab";
import ActiveOrders from "./active-orders/active-orders";
import OrderBook from "./order-book/order-book";
import TradeHistory from "./TradeHistory/trade-history";
import OrderTabs from "./order-tabs/order-tabs";
import OrderHistory from "./order-history/order-history";

const OrdersWrapper = styled.div`
  background: #1B2228;
  width: 100%;
  display: flex;
  flex: 0 1 100%;
  flex-direction: column;
`

const Orders: FC = observer(() => {

    return (
        <OrdersWrapper>
            <OrderTabs/>
            {MarketBottomBar.currentTab.value === TABS.active.value && <ActiveOrders/>}
            {MarketBottomBar.currentTab.value === TABS.order.value && <OrderBook/>}
            {MarketBottomBar.currentTab.value === TABS.trade.value && <TradeHistory/>}
            {MarketBottomBar.currentTab.value === TABS.orderHistory.value && <OrderHistory/>}
        </OrdersWrapper>
    );
});

export default Orders;