import styled from "styled-components";

export const Funds = styled.div`
  display: block;
  margin-top: 2px;
  margin-left: 10px;
  margin-right: 10px;
`

export const FundsTitle = styled.div`
  display: flex;
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
`

export const Coins = styled.span`
    display: flex;
    align-items: center;
`

export const Leverage = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: 22px;
`

export const LevarageTitle = styled.span`
  flex: 0 1 20%;
  font-size: 13px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 8px;
`

export const LevarageSelect = styled.span`
  flex: 0 1 80%;
`

export const ResetRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 16px 8px;
`

export const ResetBtn = styled.span`
  color: #D9AF20;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
`

export const ReviewRow = styled.div`
  display: flex;
  color: white;
  font-size: 13px;
  margin-bottom: 1rem;
`

export const CustomCheckBox = styled.input`
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
  background: #26323C !important;
  margin-right: 5px;
  outline: none;
  border: none;
  color: #26323c;
`

interface IButton{
    pageType: 'buy' | 'sell'
}

export const BigButton = styled.button<IButton>`
  outline: none;
  border: none;
  border-radius: 4px;
  color: white;
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  background: ${props => props.pageType === 'buy'? '#31BC83' : '#EB5746'};
  cursor: pointer;

  &:disabled{
    background: #EAEAEA;
    color: rgba(0, 0, 0, 0.2);
  }
`

