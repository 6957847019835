// import {toFixed} from "../numbers/toFixed";
import {toFixed} from "../numbers/toFixed";
import {Coins} from "../constants/crypto";
import {bigIntMultiplyNumber, toCoin, toCoins} from "../tools/calcs";

export function symbolsAfterComma(number: number) : number {
  if (number.toString().includes('.')) {
    return number.toString().split('.')[1].length
  }
  return 0
}

const cutString = (value:string, symbolsAmount: number):string =>{
  if(value.length < symbolsAmount) return value
  return String( value).slice(0, symbolsAmount)
}

//TODO make work with one of two integers
export const multiplyNumbers = (firstNumber:number, secondNumber:number) => {

  const firstNumberSymbolsAfterComma = symbolsAfterComma(firstNumber)
  const secondNumberSymbolsAfterComma = symbolsAfterComma(secondNumber)
  const deciminal = Math.pow(10, Math.max(firstNumberSymbolsAfterComma, secondNumberSymbolsAfterComma))
  console.log((firstNumber * deciminal) * (secondNumber * deciminal))
  const number = ((firstNumber * deciminal) * (secondNumber * deciminal))/(deciminal*deciminal)
  return number

  // try {
  //   const floatingPoint = String( number).split('.')[1]
  //   const intLen = String( number).split('.')[0].length
  //   let count = 0
  //   let tmp = 0
  //   while (tmp < floatingPoint.length){
  //     if(floatingPoint[tmp] === '0' &&
  //       floatingPoint[tmp + 1] === '0' &&
  //       floatingPoint[tmp + 2] === '0' &&
  //       floatingPoint[tmp + 3] === '0' &&
  //       floatingPoint[tmp + 4] === '0'){
  //       count = tmp
  //       break
  //     }
  //     tmp+=1
  //   }
  //   const res = cutString(toFixed(firstNumber * secondNumber),intLen + 1 + count )
  //   return count === 0? toFixed( firstNumber * secondNumber ) :  res
  // }catch (e){
  //   console.log(e)
  //   return number
  // }


}

export const normilizeFloat = (number: string) => {
  if(Number(number) === 0) return String(number)
  if(Number.isInteger(Number( number))) return String(Number(number))
  const integerPart = String( parseInt( String(number).split('.')[0]) )
  const floatPart = String(number).split('.')[1]
  return integerPart + '.' + floatPart
}

interface ICalculateCrypto{
  firstNumber: string;
  firstCoin: Coins;
  secondNumber: string,
  secondCoin: Coins
}
export const calculateCrypto = ({firstNumber, firstCoin, secondNumber, secondCoin}:ICalculateCrypto) => {
  const firstCryptoCoins = toCoins(firstNumber, firstCoin)
  const secondCryptoCoins = toCoins(secondNumber, secondCoin)
  const result = bigIntMultiplyNumber(firstCryptoCoins.toString(), secondCryptoCoins.toString())
  return (toCoin( toCoin(result.toString(), secondCoin).toFixed(), firstCoin).toFixed())
}
