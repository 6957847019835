import React, {FC} from 'react';
import SettingsRow from "./settings-row/settings-row";
import {TradeHistoryWrapper} from "./styled-order-history";
import OrderHistoryTable from "./order-history-table/order-history-table";

const OrderHistory :FC = () => {

    return (
        <TradeHistoryWrapper>
            <SettingsRow/>
            <OrderHistoryTable/>
        </TradeHistoryWrapper>
    );
};

export default OrderHistory;