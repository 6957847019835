import {makeAutoObservable} from "mobx";
import {getCookie} from "../../services/app/cookie/cookie";


class User{
    user: string = getUser()

    constructor() {
        makeAutoObservable(this)
    }

    setUser(user : string){
        this.user = user
    }

    checkForUser(){
        this.user = getUser()
    }

}

function getUser(){
    const token =  getCookie('token')
    console.log(token)
    return token || ''
}

export default new User()