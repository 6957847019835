import styled from "styled-components"

export const InputGroup = styled.div`
  display: block;
  font-size: 13px;
  color: #253545;

`

export const FieldTitle = styled.div`
  display: flex;
  text-align: left;
  font-weight: 600;
`

export const ForgotPassword = styled.span`
  color: #D9AF20;
  font-weight: 600;
`

export const Field = styled.input`
  border-radius: 4px;
  background: #ffffff;
  color: rgba(37, 53, 69, 0.6);
  padding: 12px;
  display: flex;
  width: 100%;
  border: 1px solid rgba(37, 53, 69, 0.2);
  outline: none;
  margin-top: 8px;
  margin-bottom: 1.5rem;
  
  &:focus{
    box-shadow: 0 0 4px 4px rgba(217, 175, 32, 0.4);
    border-radius: 4px;
  }
`

export const StayLoggedIn = styled.div`
  display: flex;
  flex-grow: 1;
  text-align: left;
  font-size: 13px;
`

export const CheckBox = styled.input`
  margin-right: 9px;
  margin-top: 2px;
`

export const SignInButton = styled.button`
  all:revert;
  width: 100%;
  background: #1B2228;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  
  &:hover{
    background: #1B2228;
  }
  
  &:disabled{
    background: #EAEAEA;
    color: rgba(0, 0, 0, 0.2);
  }
`

export const SignUpRow = styled.div`
    font-size: 13px;
    display: flex;
`