import styled from "styled-components";

export const SettingRow = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
`

export const Period = styled.div`
  display: flex;
  background: #151A1E;
  padding: 1px;
  border-radius: 4px;
  margin-right: 8px;
`

export const SelectDateForm = styled.div`
  display: flex;
  background: #151A1E;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  margin-right: 4px;
`

export const SearchBtn = styled.button`
  border: none;
  outline: none;
  color: #ffffff;
  background: #26323c;
  padding: 6px 12px;
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
`