import React, {FC} from 'react';
import MockedChart from '../../../../public/wallet/mocked_chart.png'
import {CoinRow, CoinRowCrypto, Coins, FilledCircle, TotalChartWrapper} from "./StyledTotalChart";

interface ITotalChartCoin{
    color: string,
    crypto: string,
    percent: number
}

const TotalChart :FC = () => {

    const MOCKED_DATA : ITotalChartCoin[] = [
        {color: '#F7931A', crypto: 'BTC', percent:55.7 },
        {color: '#3495FF', crypto: 'ETH', percent:42.6 },
        {color: '#31BC83', crypto: 'USDC', percent:1.7 },
        {color: '#A5A5A5', crypto: 'Other', percent:0.01 },
    ]

    return (
        <TotalChartWrapper>
            <div style={{width:'1px', height: '100%', background: 'black', marginRight:'24px'}}/>
            <div style={{display:'flex', alignItems:'center'}}>
                <img alt={'Total chart'} src={MockedChart}/>
            </div>
            <Coins>
                {MOCKED_DATA.map(data => <CoinRow key={data.crypto}> <FilledCircle color={data.color}/> <CoinRowCrypto> {data.crypto} </CoinRowCrypto>  {data.percent}% </CoinRow> )}

            </Coins>
        </TotalChartWrapper>
    );
};

export default TotalChart;