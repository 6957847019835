import React, {FC, ReactChild, ReactNode} from 'react';
import {
    LoginFormWrapper as LoginForm,
    LoginFormSubTitle,
    LoginFormMainTitle,
    Form,
    CopyRight
} from "./LoginFormWrapperStyled";
import Spacer from "../../../../ui/spacer/spacer";

interface ILoginFormWrapper{
    children: ReactChild | ReactNode
}

const LoginFormWrapper :FC<ILoginFormWrapper> = ({children}) => {

  return (
    <LoginForm>
      <LoginFormMainTitle>
        Welcome back!
      </LoginFormMainTitle>
      <LoginFormSubTitle>
        Log In to invest and trade cryptocurrency easily and  securely
      </LoginFormSubTitle>
      <Form>
        {children}
      </Form>
      <Spacer/>
      <CopyRight>
        © 2023 Unicsoft. All rights reserved.
      </CopyRight>
    </LoginForm>
  );
};

export default LoginFormWrapper;

