import {action, makeAutoObservable, toJS} from "mobx";
import {Coins} from "../../utils/constants/crypto";
import {toCoin} from "../../utils/tools/calcs";
import CoinStore from '../coins/coins'
import {getUserBalances} from "../../services/api/exchange/request";

const balances = new Map([
    ['btc', {name: Coins.BTC}],
    ['eth', {name: Coins.ETH}],
    ['usdc', {name: Coins.USDC}],
    ['usdt', {name: Coins.USDT}],
    ['cex', {name: Coins.CEX}],
    ['ada', {name: Coins.ADA}],
    ['xrp', {name: Coins.XRP}],
    ['bnb', {name: Coins.BNB}],
    ['doge', {name: Coins.DOGE}],
    ['dot', {name: Coins.DOT}],
    ['uni', {name: Coins.UNI}],
    ['usd', {name: Coins.USD}]
])

const parseBalances = async () => {
    const parsedBalances = []
    try{
        const res = await getUserBalances()

        if(CoinStore.coins === []) await CoinStore.getCoins()

        for(const [key, value] of Object.entries(res)){
            const coinObject = balances.get(key)

            // @ts-ignore
            const coinInfo = toJS(CoinStore.coins).find(el => el.symbol === coinObject.name)
            // @ts-ignore
            const amount = String( toCoin(value, coinObject.name))

            parsedBalances.push(Object.assign({amount} ,coinObject, coinInfo))
        }
    }catch (e){
        console.log(e)
    }

    return parsedBalances
}

class Balances{

    balances:any[] = []

    async getBalances(){
       this.balances = await parseBalances()
        console.log(toJS(this.balances))
    }

    clear(){
        this.balances = []
    }

    constructor() {
        makeAutoObservable(this, {getBalances: action})
    }
}

export default new Balances()