import React, {FC, ReactNode, ReactChild} from 'react';
import {
    BrowserRouter as Router
} from "react-router-dom";

interface IRouterLayout{
    children: ReactNode| ReactChild
}

const RouterLayout :FC<IRouterLayout> = ({children}) => {
    return (
        <>
            <Router>
                {children}
            </Router>
        </>
    );
};

export default RouterLayout;