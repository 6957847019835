export async function setNewPassword (code: string, password: string){
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "code": code,
        "password": password
    });

    const requestOptions = {
        method: 'POST',
        headers,
        body: raw,
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/auth/set-new-password`, requestOptions)

    return res.status
}