import React, {FC} from 'react';
import styled from "styled-components/macro";
import Spacer from "../../spacer/spacer";

const SuccessWrapper = styled.div`
  display: block;
`

const Title = styled.div`
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 16px;
  text-align: left;
`

interface IPurchased{
    purchasedSymbol: string
}

const Purchased = styled.div<IPurchased>`
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    color: ${props => props.purchasedSymbol === 'USD'? "#31BC83" : "#EB5746" } ;
`

const Row = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 400;
`

const SubTitle = styled.span`
  color: rgba(255, 255, 255, 0.4);
`

const Value = styled.span`
  color: #ffffff;
`

const TotalRow = styled.div`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
`

const SuccessBtn = styled.button`
  background: #D9AF20;
  color: #ffffff;
  padding: 8px 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
`

interface ISuccessExchange{
    purchased: number,
    purchasedSymbol: string,
    spent: number,
    spentSymbol: string,
    fee: number,
    feeType: "PERCENTS" | "FIXED",
    closeModal: () => void
}

const SuccessExchange : FC<ISuccessExchange> = ({purchased, feeType,  fee, purchasedSymbol, spent, spentSymbol, closeModal}) => {
    return (
        <SuccessWrapper>
            <Title> Successful transfer </Title>
            <Purchased purchasedSymbol={purchasedSymbol}> Purchased {purchased} {purchasedSymbol}  </Purchased>
            <Row>
                <SubTitle> Spent </SubTitle>
                <Spacer/>
                <Value> {spent} {spentSymbol} </Value>
            </Row>
            <Row>
                <SubTitle> Transaction Fee {feeType === 'PERCENTS'&& <span>({fee}%)</span> } </SubTitle>
                <Spacer/>
                <Value> {feeType === 'PERCENTS'? spent * fee : fee} {spentSymbol} </Value>
            </Row>
            <TotalRow>
                <span>Total</span>
                <Spacer/>
                {/*<span> {calculateValueAndFee(spent, feeType, fee)} {spentSymbol}</span>*/}
                <span> {(spent* fee) + spent} {spentSymbol}</span>
            </TotalRow>

            <Row style={{marginTop: '32px'}}>
                <Spacer/>
                <SuccessBtn onClick={() => closeModal()}> Got it </SuccessBtn>
            </Row>
        </SuccessWrapper>
    );
};

export default SuccessExchange;