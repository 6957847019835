import React, {useEffect, useState} from 'react';
import Spacer from "../../../../ui/spacer/spacer";
import {Period, SearchBtn, SettingRow as SettingRowWrapper} from "./styled-settings-row";
import PeriodItem from "./period-item/period-item";
import DatepickerSelect from "../../../../ui/datepicker/datepicker";
import TradeHistory from '../../../../../store/trade-history/trade-history'
import {DateGetter} from "../../../../../utils/date/date";
import {PeriodItems} from "../../../../../utils/constants/period-items";

const SettingsRow = () => {

  const [activePeriodItemValue, setActivePeriodItemValue] = useState('day')

  const handlePeriodSelect = (value: string) => {
    setActivePeriodItemValue(value)
  }

  const [activeTab, setActiveTab] = useState<'tab'|'select'>()

  const handleAction = (action:'tab'|'select') => {
    if(activeTab === action) return
    setActiveTab(action)
    if(action === 'select') setActivePeriodItemValue('none')
    return;
  }

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const getTradeHistory = async () => {
    await TradeHistory.getTradeHistory(new Date( date[0].startDate.toDateString()), new Date( date[0].endDate.getTime() + 1000*60*60*23))
    console.log('got')
  }

  useEffect(() =>{
    (async function (){
      const tomorrow = new Date( new Date().getTime() - 1000*60*60*24 )
      await TradeHistory.getTradeHistory(tomorrow, new Date( date[0].endDate.getTime() + 1000*60*60*23))
    })()
  },[])

  return (
    <SettingRowWrapper>
      <Spacer/>
      <Period>
        {
          PeriodItems.map((periodItem) =>
            <PeriodItem key={periodItem.value} active={activeTab === 'tab'} dates={DateGetter} onAction={handleAction} name={'tab'} activeValue={activePeriodItemValue} title={periodItem.title} value={periodItem.value} onSelect={handlePeriodSelect}/>
          )
        }
      </Period>
      <DatepickerSelect active={activeTab === 'select'} onAction={handleAction} name={'select'} date={date} setDate={setDate}/>
      <SearchBtn onClick={() => getTradeHistory()}> Search </SearchBtn>
    </SettingRowWrapper>
  )
}

export default SettingsRow