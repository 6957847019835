import React, {FC, useEffect, useState} from 'react';
import {
    InputGroup,
    Field,
    FieldTitle,
    ForgotPassword,
    StayLoggedIn,
    CheckBox,
    SignInButton,
    SignUpRow, ErrorRow
} from "./styled-login-form";
import Spacer from "../../../ui/spacer/spacer";
import {useForm, SubmitHandler} from "react-hook-form";
import {ILoginForm} from "../../../../types/login/login-form.types";
import {login} from "../../../../services/api/login/requests";
import Password from "../../../../public/icons/password";
import {useHistory} from 'react-router-dom'
import * as Joi from "joi";
import {joiResolver} from "@hookform/resolvers/joi";
import Error from "../../../../public/icons/error";

type PasswordType = 'password' | 'text'

const LoginFormSchema = Joi.object({
    password: Joi.string().required().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
    email: Joi.string().email({ tlds: { allow: false } }),
    stayLoggedIn: Joi.boolean().required()
})

const LoginForm :FC = () => {

    const history = useHistory()
    const [error, setError] = useState<boolean>(false)

    const {register, handleSubmit, formState, reset} = useForm({
        resolver: joiResolver(LoginFormSchema ),
        mode: "onChange"
    })
    const onSubmit : SubmitHandler<ILoginForm> = async data => {
        setBlocked(true)
        try {
            const status = await login(data)
            reset()
            console.log(formState.isValid)
            setBlocked(false)
            if(status === 405) return await history.push('/email-verification')
            if(status === 401) return setError(true)
        }catch (e){
            setTimeout(() => {
                setBlocked(false)
            },1000)
        }
    };

    const {isValid, errors, isDirty} = formState

    useEffect(() => {
        console.log(errors, isValid)
    }, [errors, isValid, isDirty])

    const [isBlocked, setBlocked] = useState<boolean>(false)

    const [passwordType, setPasswordType] = useState<PasswordType>('password')

    const togglePasswordType = () => {
        passwordType === 'password'? setPasswordType('text'): setPasswordType('password')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
                <FieldTitle>
                    Login
                </FieldTitle>
                <Field {...register('email')} type={'email'} placeholder='Email' />
            </InputGroup>

            <InputGroup>
                <FieldTitle>
                    Password
                    <Spacer/>
                    <ForgotPassword onClick={() => history.push('/forgot-password')}>
                        Forgot Password?
                    </ForgotPassword>
                </FieldTitle>
                <div style={{position:'relative'}}>
                    <Field {...register('password')} type={passwordType} placeholder='Password' />
                    <div className="icon" style={{position:"absolute", right:'12px', top: '12px'}} onClick={() => togglePasswordType()}>
                        <Password/>
                    </div>
                </div>
            </InputGroup>
            {error && <ErrorRow> <span style={{marginRight: '6px'}}> <Error/> </span> Incorrect email or password </ErrorRow>}

            <StayLoggedIn>
                <CheckBox {...register('stayLoggedIn')} type='checkbox'/>
                <span> Stay logged in </span>
            </StayLoggedIn>

            <SignInButton type='submit' disabled={  !isValid && !isBlocked}>
                Sign in
            </SignInButton>

            <SignUpRow>
                <span> Don't have an account? <span style={{cursor: 'pointer'}}> <b onClick={() => history.push('/registration')}> Sign up </b> </span> </span>
            </SignUpRow>
        </form>
    );
};

export default LoginForm;