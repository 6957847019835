import React, {FC} from 'react';
import {ActiveOrdersWrapper} from "./styled-active-orders";
import ActiveOrdersTable from "./active-orders-table/active-orders-table";

const ActiveOrders :FC = () => {
    return (
        <ActiveOrdersWrapper>
            <ActiveOrdersTable/>
        </ActiveOrdersWrapper>
    )
}

export default ActiveOrders;