import styled from "styled-components";

interface ITabItem{
    isActive: boolean
}

export const TabItem = styled.div<ITabItem>`
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${props => props.isActive? '#F9C510' : '#ffffff'};
  cursor: pointer;
`

export const TabItemTitle = styled.div<ITabItem>`
  margin-bottom: ${props => props.isActive? '12px': '13px'};
`

export const ActiveTab = styled.div<ITabItem>`
  width: 100%;
  height: 2px;
  background: #F9C510;
  display: ${props => props.isActive? 'block': 'none'};
`

