import React, {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import CustomSelect from "../custom-select/custom-select";
import Wallet from "../../../../public/icons/wallet";
import Input from "../form-input/input";
import Spacer from "../../../ui/spacer/spacer";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import Balances from "../../../../store/balances/balances";
import {IPair} from "../../../../types/exchange/pair.types";
import Pairs from "../../../../store/pairs/pairs";
import {Coins as COINS} from '../../../../utils/constants/crypto'
import {toCoin, toCoins} from "../../../../utils/tools/calcs";
import {
    ActionButton,
    Fee,
    FeeAmount,
    FeeTitle,
    FormRow,
    FormWrapper,
    InputGroup,
    InputLabel,
    Rate,
    TotalBalance
} from "./styled-form-sell";
import {toFixed} from "../../../../utils/numbers/toFixed";
import {IExchange} from "../../../../types/exchange/exchange.types";
import {symbolsAfterComma} from "../../../../utils/math/math";
import ModalWrapper from "../../../ui/modal/modal-wrapper/modal-wrapper";
import {calculateValueAndFee} from "../../../../utils/math/feeCalculations";
import SuccessExchange from "../../../ui/modal/success-exchange/success-exchange";
import DangerModal from "../../../ui/modal/danger-modal/danger-modal";
import {exchangeCrypto} from "../../../../services/api/exchange/request";

interface ICheck{
    purchased: number,
    purchasedSymbol: string,
    spent: number,
    spentSymbol: string,
    fee: number,
    feeType: "PERCENTS" | "FIXED",
}

interface ICalculateReceive{
    crypto: number,
    rate: number
}
const calculateReceiveCoins = ( {crypto, rate} : ICalculateReceive  ) : number => {
    return (crypto * rate )
}

interface ICalculateSpend{
    usd: number,
    rate: number
}

const calculateSpendCoins = ( {usd, rate} : ICalculateSpend  ) :number => {
    return (usd)/ rate;
}

const data  = Object.values(COINS);
const COINS_ARRAY  = data.slice(0, data.length - 1);
//TODO fix all ts-ignore
const Form :FC = observer( () => {

    const [cryptoSelect, setCryptoSelect] = useState<string>('BTC')

    const [currentCrypto, setCurrentCrypto] = useState<any>()

    const [currentPair, setCurrentPair] = useState<IPair>()

    const handleItemClick = async (e: any) => {
        const id = e.target.id
        // @ts-ignore
        await setCryptoSelect( COINS_ARRAY.find(item => item === id) );
        await setSpend('0')
        await setReceive('0')
    }

    const getBalances = async () => {
            if(Balances.balances.length === 0) await Balances.getBalances()
            const res = toJS(Balances.balances).find(el => el.name === cryptoSelect)
            await setCurrentCrypto(res)
    }

    useEffect(() => {
        (async function (){
            try{
                await getBalances()
            }catch (e){
                console.log(e)
            }
        })()

    },[cryptoSelect])

    useEffect(() => {
        try{
            (async function (){
                if(Pairs.pairs.length === 0) await Pairs.getPairs()
                const element = toJS(Pairs.pairs).find(el => el.fromCoin.symbol === cryptoSelect && el.toCoin.symbol === 'USD')
                await setCurrentPair(element)
            })()
        }catch(e){
            console.log(e)
        }

    },[ cryptoSelect])

    const currentFee = useMemo(() => {
        if(!currentPair) return {
            feeType: '',
            fee: 0,
            title: '',
            min: 0,
            max: 0
        }

        const min = Number( toCoin(currentPair?.min, currentPair?.fromCoin.symbol) )
        const max = Number( toCoin(currentPair?.max, currentPair?.fromCoin.symbol) )

        if(currentPair?.feeType === 'PERCENTS'){
            const parsedFee = Number(currentPair.fee) * 100
            const parsedFeeTitle = `${parsedFee}%`
            return {
                feeType: currentPair.feeType,
                fee: Number( currentPair.fee),
                title: parsedFeeTitle,
                min,
                max
            }
        }

        const parsedFee = toCoin(currentPair?.fee,currentPair?.fromCoin.symbol)
        const parsedFeeTitle = `${parsedFee}${currentPair?.fromCoin.symbol}`
        return {
            feeType: currentPair?.feeType,
            fee: Number(currentPair?.fee),
            title: parsedFeeTitle,
            min,
            max
        }
    },[currentPair])

    const [spend, setSpend] = useState<string>('0')
    const handleSpend = async (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value
        if(symbolsAfterComma(Number(value)) > 8) value = String(Number(value).toFixed(8))
        if(!currentPair?.fee) return console.log(currentPair)
        try{
            const receiveVal = calculateReceiveCoins({crypto: Number( value), rate: Number (currentPair.fromCoin.usdPrice) })
            console.log(receiveVal)
            setSpend(String(value))
            if(symbolsAfterComma(receiveVal) > 2) return setReceive(String( receiveVal.toFixed(2)))
            setReceive( String( receiveVal) )

        }catch (e){
            console.log(e)
        }
    }

    const [receive, setReceive] = useState<string>('0')
    const handleReceive = async (e: ChangeEvent<HTMLInputElement>) => {
        if(parseFloat( e.target.value) === 0) return  setReceive(e.target.value)
        const value = Number(e.target.value)

        if(!currentPair) return setReceive('')
        try{
            const spendValue = calculateSpendCoins({usd: value, rate: Number (currentPair.fromCoin.usdPrice) })
            if(symbolsAfterComma(value) > 2) setReceive(String(value.toFixed(2)))
            setReceive(String(value))
            setSpend(toFixed( String( spendValue)))
        }catch (e){
            console.log(e)
        }
    }

    const spendBlock = useMemo(() => {
        return{
            value: spend,
            onChange: handleSpend
        }
    },[spend, receive, currentCrypto, currentPair, currentFee])

    const receiveBlock = useMemo(() => {
        return{
            value: receive,
            onChange: handleReceive
        }
    },[receive, spend, currentCrypto, currentPair, currentFee])

    const [errors, setErrors] = useState<string[]>([])

    const isDisabled = useMemo<boolean>(() => {
        const errors:string[] = []
        if(Number(spend) <= 0) errors.push('Spend cannot be 0')
        if(Number(spend) > currentFee?.max) errors.push(`Receive amount is higher than pair max ${String(currentFee.max)} ${currentPair?.fromCoin.symbol}`)
        if(Number(spend) < currentFee.min) errors.push(`Receive amount is lower than pair min ${String(currentFee.min)}` )
        // @ts-ignore
        if(calculateValueAndFee(Number( spend), currentFee.feeType, currentFee.fee) > Number( currentCrypto?.amount) ) errors.push('Receive amount + fee is higher than your balance');

        console.log('errors')
        console.log(errors)
        setErrors(errors)
        return errors.length > 0
    },[spend])

    useEffect(() => {
        console.log('disabled')
        console.log(String( isDisabled))
    },[isDisabled])

    const sellCrypto = async () => {
        if(!currentPair) return
        if(Number( spend) === 0 || Number(receive) === 0) return console.log('values should not equal 0')
        const amount = toCoins( spend, currentPair.fromCoin.symbol)

        const raw :IExchange = {
            "amount": String( amount),
            "tradingPairId": currentPair?.id,
            "feeType": currentPair?.feeType,
            "fee": Number( currentPair?.fee),
            "rate": Number( currentPair?.fromCoin.usdPrice)
        };
        console.log(raw)

        try {
            if(!currentPair) return
            const {message} = await exchangeCrypto(raw)
            await Balances.getBalances()
            await getBalances()
            const purchased = calculateReceiveCoins({crypto: Number( spend), rate: Number (currentPair.fromCoin.usdPrice) })
            setCheck({
                purchasedSymbol: currentPair.toCoin.symbol,
                purchased: purchased,
                spent: Number(spend),
                spentSymbol: currentPair.fromCoin.symbol,
                fee: currentFee.fee,
                // @ts-ignore
                feeType: currentFee.feeType,
            })

            if(message !== ''){
                await setError(message)
                await setFailModal(true)
                return
            }
            setSuccessModalOpened(true)
        }catch (e){
            console.log(e)
        }
    }

    const [successModalOpened, setSuccessModalOpened] = useState<boolean>(false)
    const closeSuccessModal = async () => {
        await setSuccessModalOpened(false)
    }

    const [error, setError] = useState<string>('')

    const [failModal, setFailModal] = useState<boolean>(false)
    const closeFailModal = async () => {
        await setFailModal(false)
    }

    const [check, setCheck] = useState<ICheck>()

    return (
        <FormWrapper>
            <div>
                <FormRow>
                    <InputGroup>
                        <InputLabel> Coin </InputLabel>
                        <CustomSelect handleItemClick={handleItemClick} items={COINS_ARRAY} selectedItem={cryptoSelect}/>
                    </InputGroup>
                    <InputGroup style={{marginLeft: '16px'}}>
                        <InputLabel> Deposit into </InputLabel>
                        <CustomSelect handleItemClick={handleItemClick} items={COINS_ARRAY} disabled={true} selectedItem={cryptoSelect}/>
                    </InputGroup>
                </FormRow>
            </div>
            <div>
                <FormRow>
                    <TotalBalance> <Wallet/> <span style={{marginLeft: '4px'}}> Balance: </span> <span style={{marginLeft: '4px'}}> {currentCrypto?.amount} {currentCrypto?.name} </span>  </TotalBalance>
                </FormRow>
            </div>
            <div>
                <Rate> 1 {currentCrypto?.name} ≈ {Number( currentPair?.fromCoin.usdPrice)} USD </Rate>
            </div>
            <div>
                <FormRow>
                    <InputGroup>
                        <Input type={'number'}  label={'Spend'} formikProps={{...spendBlock}} endPlaceholder={currentCrypto?.name} />
                    </InputGroup>
                    <InputGroup style={{marginLeft: '16px'}}>
                        <Input type={'number'}  label={'Receive'} formikProps={{...receiveBlock}} endPlaceholder={'USD'}/>
                    </InputGroup>
                </FormRow>
            </div>
            {errors && <div style={{marginTop: '12px', color: "rgba(0, 0, 0, 0.4)", textAlign: 'left'}}>
                {errors.map(error => <div style={{color: "#D32F2F"}} key={error}>{error}</div>)}
                        </div>
            }
            <div style={{marginTop: '48px', display:'flex'}}>
                <Fee>
                    <FeeTitle>Transaction Fee:</FeeTitle>
                    <FeeAmount>  ({currentFee.title}) {currentFee.feeType === 'PERCENTS'? <span> {(Number(spend) * currentFee.fee)} {currentPair?.fromCoin.symbol} </span> : <span> {currentFee.fee} {currentPair?.fromCoin.symbol} </span> } </FeeAmount>

                </Fee>
                <Spacer/>
                <ActionButton onClick={sellCrypto} disabled={isDisabled} > Sell {currentCrypto?.name} </ActionButton>
            </div>

            {successModalOpened && check &&
            <ModalWrapper isOpened={successModalOpened} callback={() => console.log(2)} >
                <SuccessExchange purchased={check.purchased} purchasedSymbol={check.purchasedSymbol} spent={check.spent} spentSymbol={check.spentSymbol} fee={check.fee} feeType={check.feeType} closeModal={closeSuccessModal} />
            </ModalWrapper>
            }
            {
                failModal && check && <ModalWrapper isOpened={failModal} callback={() => console.log('2')}>
                    <DangerModal purchased={check.purchased} purchasedSymbol={check.purchasedSymbol} spent={check.spent} spentSymbol={check.spentSymbol} fee={check.fee} feeType={check.feeType} closeModal={closeFailModal} error={error}/>
                </ModalWrapper>
            }
        </FormWrapper>
    );
});

export default Form;