import {getToken} from "../../app/cookie/cookie";
import {ICoin, IPair} from "../../../types/exchange/pair.types";
import {IExchange} from "../../../types/exchange/exchange.types";
import {ITransactionsData} from "../../../types/exchange/transactions.types";

export async function getPairs(): Promise< IPair[]> {
    const headers = new Headers()
    headers.append("Authorization", getToken())

    const requestOptions = {
        method: 'GET',
        headers: headers
    }

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/trading-pairs`, requestOptions)

    return await res.json()
}

export async function getCoins() : Promise<ICoin[]>{
    const headers = new Headers()
    headers.append("Authorization", getToken())

    const requestOptions = {
        method: 'GET',
        headers: headers
    }

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/coins/trading-coins`, requestOptions)

    return await res.json()
}

interface IError{
    message: string
}

export async function exchangeCrypto( data: IExchange) :Promise<IError> {
    const myHeaders = new Headers()
    myHeaders.append("Authorization", getToken())
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify( data),
    }

    console.log(data)

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/crypto/exchange`, requestOptions)

    const textRes = await res.text()
    console.log(textRes)
    if(!res.ok) return JSON.parse(textRes)
    return {message: ''}
}


export async function getUserBalances() {
    const myHeaders = new Headers()
    myHeaders.append("Authorization", getToken())
    myHeaders.append("Content-Type", "application/json")

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
    }

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/me`, requestOptions)
    const data :any = await res.json()

    return {
        ada: data.balances.ada,
        bnb: data.balances.bnb,
        btc: data.balances.btc,
        cex: data.balances.cex,
        doge: data.balances.doge,
        dot: data.balances.dot,
        eth: data.balances.eth,
        uni: data.balances.uni,
        usd: data.balances.usd,
        usdc: data.balances.usdc,
        usdt: data.balances.usdt,
        xrp: data.balances.xrp
    }
}

export async function getTransactions(){
    const myHeaders = new Headers()
    myHeaders.append("Authorization", getToken())

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
    }

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/transactions?coin=USD&skip=0&take=20`, requestOptions)
    const data :ITransactionsData = await res.json()
    return data.list
}