import React, {FC, useState} from "react";
import {AppBarNavItem} from "../styled-app-bar";
import Setting from "../../../../public/icons/setting";
import Logout from "../../../../public/icons/logout";
import styled from "styled-components/macro";
//@ts-ignore
import {deleteCookie} from "../../../../services/app/cookie/cookie";
import User from "../../../../store/user/user";
import {clearAllStores} from "../../../../store/clear";

interface HooverAccount{
    hovered: boolean
}

const HoveredAccount = styled.div<HooverAccount>`
  position: absolute;
  width: 200px;
  background: transparent;
  margin-top: 100px;
  padding-top: 25px;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  left: -70px;
  display: ${props => props.hovered? 'block': 'none'};
  border-radius: 4px;
`

const Menu = styled.div`
  width: 100%;
  background: #1B2228;
`

const MenuItem = styled.div`
  display: flex;
  padding: 16px;
`

const MenuIcon = styled.span`
  margin-right: 9px;
`

const Account :FC = () => {

    const [isAccountHoovered, setAccountHoovered] = useState<boolean>(false)

    const logout = () => {
        deleteCookie('token')
        clearAllStores()
        User.checkForUser()
    }

    return(
        <AppBarNavItem style={{position: 'relative'}} onMouseEnter={() => setAccountHoovered(true)} onMouseLeave={() => setAccountHoovered(false)}>
            Account
            <HoveredAccount hovered={isAccountHoovered}>
                <Menu>
                    <MenuItem>
                        <MenuIcon>
                            <Setting/>
                        </MenuIcon>
                        <span>Settings</span>
                    </MenuItem>
                    <MenuItem onClick={() => logout()}>
                        <MenuIcon>
                            <Logout/>
                        </MenuIcon>
                        <span>Logout</span>
                    </MenuItem>
                </Menu>
            </HoveredAccount>
        </AppBarNavItem>
    )
}

export default Account