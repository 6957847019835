import React from 'react';

const Error = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.35429 1.93775C5.86908 1.06657 7.12956 1.06657 7.64435 1.93775L11.8136 8.9934C12.3375 9.88 11.6984 11 10.6686 11H2.33007C1.30025 11 0.661142 9.88 1.18504 8.9934L5.35429 1.93775ZM7.24927 8.75001C7.24927 9.16423 6.91348 9.50001 6.49927 9.50001C6.08505 9.50001 5.74927 9.16423 5.74927 8.75001C5.74927 8.3358 6.08505 8.00001 6.49927 8.00001C6.91348 8.00001 7.24927 8.3358 7.24927 8.75001ZM6.49927 4.00001C6.08505 4.00001 5.74927 4.3358 5.74927 4.75001V6.25001C5.74927 6.66423 6.08505 7.00001 6.49927 7.00001C6.91348 7.00001 7.24927 6.66423 7.24927 6.25001V4.75001C7.24927 4.3358 6.91348 4.00001 6.49927 4.00001Z" fill="#EB5746"/>
    </svg>
  );
};

export default Error;