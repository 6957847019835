import React, {FC} from 'react';
import './checkbox.css';

interface ICheckbox{
    label: string,
    form: any
}

const Checkbox :FC<ICheckbox> = ({label, form}) => {
    return (
        <div>
            <label className="checkboxRow">{label}
                <input {...form} type="checkbox"/>
                <span className="checkmark"/>
            </label>
        </div>
    );
};

export default Checkbox;