import React, {FC} from 'react';
import SidebarTabs from "./SidebarTabs/sidebar-tabs";
import {observer} from "mobx-react-lite";
import MarketSidebar from "../../../../store/market-sidebar/market-sidebar";
import styled from "styled-components";
import BuyBlock from "./buy-block/buy-block";
import SellBlock from "./SellBlock/sell-block";

const SidebarMarketBlockWrapper = styled.div`
  background: #1B2228;
  padding-right: 14px;
  padding-left: 14px;
`

const SidebarMarketBlock : FC = observer( () => {
    return (
        <SidebarMarketBlockWrapper>
            <SidebarTabs/>
            {MarketSidebar.activeAction === 'Buy' && <BuyBlock/> }
            {MarketSidebar.activeAction === 'Sell' && <SellBlock/> }
        </SidebarMarketBlockWrapper>
    )
})

export default SidebarMarketBlock;