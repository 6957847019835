import React, {FC} from 'react';
import TableTimeItem from "../../../market/table/table-options/table-time-items/table-time-item/table-time-item";
import {ITimeTableItem} from "../../../../types/market/time-table-item";
import {TableTimeSelector} from "../../../market/table/table-options/table-time-items/styled-table-time-items";

type TimeTableMap = Record<string, ITimeTableItem>

const TIME_TABLE_VALUES: TimeTableMap = {
    '1D': {title: '1D', value: '1D'},
    '1W': {title: '1W', value: '1W'},
    '1M': {title: '1M', value: '1M'}
}

const WalletTabs :FC = () => {
    return (
        <TableTimeSelector>

            <TableTimeItem item={TIME_TABLE_VALUES['1D']}/>

            <TableTimeItem item={TIME_TABLE_VALUES['1W']}/>

            <TableTimeItem item={TIME_TABLE_VALUES['1M']}/>

        </TableTimeSelector>
    )
}

export default WalletTabs;