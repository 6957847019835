import {PeriodDate} from "../../types/orders/period-tabs.types";

export const parseDate = (date: string) => {
    const customDate = new Date(date)
    const day = getDate(customDate)
    const month = getMonth(customDate)
    const time = getTime(customDate)
    return `${month}-${day} ${time}`
}

const getDate = (date: Date) => {
    const day = date.getDate()
    console.log(day > 10)
    return day >= 10? String( day ): '0' + day
}

const getMonth = (date: Date) => {
    const month = date.getMonth() + 1
    return month >= 10? String(month) : '0' + month
}


const getTime = (date: Date) => {
    const time = date.toLocaleTimeString()
    return String( time)
}

const DAY_IN_MILLISECONDS = 86400000


export const subtractDaysFromCurrentDate = (days: number) => {
    const currentDate = new Date()
    const currentDateInMilliseconds = currentDate.getTime()
    const daysInMilliseconds = days * DAY_IN_MILLISECONDS
    const date = new Date(currentDateInMilliseconds - daysInMilliseconds)

    return {
        dateTo: new Date() ,
        dateFrom: new Date(date.toDateString())
    }
}

export const DateGetter:Record<PeriodDate, {dateFrom: Date, dateTo:Date}> = {
    day: subtractDaysFromCurrentDate(0),
    week: subtractDaysFromCurrentDate(7),
    one_month: subtractDaysFromCurrentDate(30),
    three_month: subtractDaysFromCurrentDate(90)
}