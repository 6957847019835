import React, {FC} from 'react';
import SettingsRow from "./settings-row/settings-row";
import {TradeHistoryWrapper} from "./styled-trade-history";
import TradeHistoryTable from "./trade-history-table/trade-history-table"



const TradeHistory :FC = () => {

    return (
        <TradeHistoryWrapper>
            <SettingsRow/>
            <TradeHistoryTable/>
        </TradeHistoryWrapper>
    );
};

export default TradeHistory;