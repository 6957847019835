import React, {useEffect} from 'react'
import './App.css'
import {createGlobalStyle} from "styled-components"
import AuthLayout from "./components/layouts/auth/auth-layout";
import UserLayout from "./components/layouts/user/user-layout.";
import {observer} from "mobx-react-lite";
import User from "./store/user/user";
import AppSession from './store/app-session/app-session'
import {getCookie, setCookie} from "./services/app/cookie/cookie";
import {toJS} from "mobx";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`

const App = observer(() => {
  const time = 1000 * 60 * 60 * 3

  const setExpiresCookies = (date: Date) => {
    const token = getCookie('token')
    if (token) {
      setCookie('date', date, {
        expires: date
      })

      setCookie('token', token, {
        expires: date
      })
    }

  }

  const setExpiresSession = () => {
    if (toJS(AppSession.deleteSession)) {
      const currentTime = new Date().getTime() + time
      const date = new Date(currentTime)
      setExpiresCookies(date)
    }
  }

  const refreshSessionToken = () => {
    const date = getCookie('date')
    // @ts-ignore
    const expiredDate = new Date(date)
    const currentDate = new Date()
    const hoursBetweenDates = (expiredDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60)
    if (hoursBetweenDates < 3) {
      const currentTime = new Date().getTime() + time
      const date = new Date(currentTime)
      setExpiresCookies(date)
    }
  }

  useEffect(() => {
    User.checkForUser()
  }, [])

  useEffect(() => {
    setExpiresSession()

    refreshSessionToken()

    const interval = setInterval(() => {
      refreshSessionToken()
    }, (time - 1000 * 60 * 10))

    window.addEventListener('unload', () => {
      refreshSessionToken()
    })

    return () => {
      clearInterval(interval)
    }
  }, [AppSession.deleteSession])

  return (
    <div className="App">
      <GlobalStyles/>
      {User.user ? <UserLayout/> : <AuthLayout/>}
    </div>
  );
})

export default App;
