import React, {FC} from 'react';
import styled from "styled-components";

const ContentWrapper = styled.div`
    min-height: 93vh;
    max-height: 100%;
    width: 100%;
    background: #151A1E;
`


const Content: FC = ({children}) => {
    return (
        <ContentWrapper>
            {children}
        </ContentWrapper>
    );
};

export default Content;