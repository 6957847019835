import {useEffect, useRef} from "react";

export function useIntersectionObserver  (parentRef: any, childRef : any, callback: any) :void {
    const observer = useRef<any>()

    useEffect(() => {
        const options = {
            root: parentRef.current,
            rootMargin: '0px',
            threshold: 1
        };
        observer.current = new IntersectionObserver(([target]) => {
            if(target.isIntersecting) {
                callback(true)
            }else {
                callback(false)
            }
        }, options)

        observer.current.observe(childRef.current)

        return function cleanup(){
            observer.current.unobserve(childRef.current)
        }
    },[callback, childRef, parentRef])
}