import React, {FC, useEffect} from 'react';
import styled from "styled-components";
import {DropDownItem} from "../../../../../types/market/dropdown";
import {DROPDOWN_ITEMS} from "../../../../../utils/constants/market-dropdown";

const DropdownSelectWrapper = styled.div`
  position: absolute;
  top: 110%;
  background: #26323C;
  width: 100%;
  left: 0;
  z-index: 999999;
`

const DropdownSelectItem = styled.div`
  display: flex;
  padding: 8px 12px 8px 12px;
  
  &:hover{
    color: #F9C510;
    background: rgba(255, 255, 255, 0.05);
  }
`

interface IDropDown{
    onSelect: (item: DropDownItem) => void
}

const DropdownSelect: FC<IDropDown> = ({ onSelect}) => {

    useEffect(()=> {
        console.log(DROPDOWN_ITEMS)
    },[])

    return (
        <DropdownSelectWrapper>
            {DROPDOWN_ITEMS.map(item =>
                   <DropdownSelectItem onClick={()=>onSelect(item)} key={item.value}>
                       {item.title}
                    </DropdownSelectItem>
            )}

        </DropdownSelectWrapper>
    );
};

export default DropdownSelect;