import React, {FC, useState} from 'react';
import Spacer from "../../../ui/spacer/spacer";
import {
    News,
    NewsBlock,
    SidebarNewsTitle,
    SidebarNewsWrapper,
    TabItem,
    TabItemTitle,
    ViewAll
} from "./styled-sidebar-news-block";
import {ActiveTab} from "../sidebar-trade-block/SidebarTabs/SidebarTabItem/sidebar-tab-item";
import {Coins, CryptoNames} from "../../../../utils/constants/crypto";
import styled from "styled-components";

interface IMockedCoin{
  name: string;
  symbol: keyof typeof Coins;
  img: string;
  price: string;
  rate: string;
}

export const BalanceCryptoItemWrapper = styled.div`
  display: block;
  padding: 5px 0;
  background: #1B2228;
`

export const ItemImage = styled.div`
  flex: 1 1 10%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemBody = styled.div`
  flex: 1 1 85%;
`

export const CryptoRow = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
`

export const DescriptionRow = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  display: flex;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #151A1E;
  margin-top: 14px;
  margin-bottom: 14px;
`

interface IMockedData {
  Buying: IMockedCoin[],
  Sales: IMockedCoin[]
}
const mocked_data:IMockedData = {
  Buying: [
    {
      name: CryptoNames.BTC.fullname,
      symbol: 'BTC',
      img: CryptoNames.BTC.img,
      price: '38,743.00',
      rate: '+3.13%'
    },
    {
      name: CryptoNames.ETH.fullname,
      symbol: 'ETH',
      img: CryptoNames.ETH.img,
      price: '22213',
      rate: '-2,21%'
    },
    {
      name: CryptoNames.USDT.fullname,
      symbol: 'USDT',
      img: CryptoNames.USDT.img,
      price: '1',
      rate: '+1,21%'
    },
    {
      name: CryptoNames.USDC.fullname,
      symbol: 'USDC',
      img: CryptoNames.USDC.img,
      price: '1',
      rate: '+5,21%'
    },
  ],
  Sales:[
    {
      name: CryptoNames.ETH.fullname,
      symbol: 'ETH',
      img: CryptoNames.ETH.img,
      price: '$22213',
      rate: '-2,21%'
    },
    {
      name: CryptoNames.BTC.fullname,
      symbol: 'BTC',
      img: CryptoNames.BTC.img,
      price: '$38,743.00',
      rate: '+3.13%'
    },
    {
      name: CryptoNames.USDC.fullname,
      symbol: 'USDC',
      img: CryptoNames.USDC.img,
      price: '$1',
      rate: '+5,21%'
    },
    {
      name: CryptoNames.USDT.fullname,
      symbol: 'USDT',
      img: CryptoNames.USDT.img,
      price: '$1',
      rate: '+1,21%'
    },
  ]
}

const SidebarNewsBlock :FC = () => {

  const [tab, setTab] = useState<'Buying'|'Sales'>('Buying')

  return (
    <SidebarNewsWrapper>
      <SidebarNewsTitle>
        <News>Top Coins</News>
          <Spacer/>
          <ViewAll>
            <TabItem onClick={() => setTab('Buying')}>
              <TabItemTitle isActive={tab === 'Buying'}> Buying </TabItemTitle>
              <ActiveTab isActive={tab === 'Buying'}/>
            </TabItem>
            <TabItem onClick={() => setTab('Sales')}>
              <TabItemTitle isActive={tab === 'Sales'}> Sales </TabItemTitle>
              <ActiveTab isActive={tab === 'Sales'}/>
            </TabItem>
          </ViewAll>
      </SidebarNewsTitle>
        <NewsBlock>
          {
            tab === 'Buying' && mocked_data[tab].map(coin =>
              <BalanceCryptoItemWrapper>
              <div style={{display:'flex'}}>
                <ItemImage>
                  <img alt='Crypto image' src={coin.img} style={{borderRadius: '50%'}}/>
                </ItemImage>

                <ItemBody>
                  <CryptoRow> {coin.symbol} <Spacer/> { coin.price}$ </CryptoRow>
                  <DescriptionRow> {coin.name} <Spacer/> <span style={coin.rate[0] === '+'? {color: '#31BC83'}: {color: '#EB5746'}}> {coin.rate} </span>   </DescriptionRow>
                </ItemBody>
              </div>
              <Divider/>

            </BalanceCryptoItemWrapper>
            )
          }

          {
            tab === 'Sales' && mocked_data[tab].map(coin =>
              <BalanceCryptoItemWrapper>
                <div style={{display:'flex'}}>
                  <ItemImage>
                    <img alt='Crypto image' src={coin.img} style={{borderRadius: '50%'}}/>
                  </ItemImage>

                  <ItemBody>
                    <CryptoRow> {coin.symbol} <Spacer/> { coin.price}$ </CryptoRow>
                    <DescriptionRow> {coin.name} <Spacer/> <span style={coin.rate[0] === '+'? {color: '#31BC83'}: {color: '#EB5746'}}> {coin.rate} </span>   </DescriptionRow>
                  </ItemBody>
                </div>
                <Divider/>

              </BalanceCryptoItemWrapper>
            )
          }

        </NewsBlock>
    </SidebarNewsWrapper>
  )
}

export default SidebarNewsBlock