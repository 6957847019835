import React from 'react';

const Password = () => {
    return (
        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="3" fill="#253545" fillOpacity="0.6"/>
            <path d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8" stroke="#253545" strokeOpacity="0.6" strokeWidth="2"/>
        </svg>
    );
};

export default Password;