import React, {FC} from 'react'
import LoginLayout from "../../components/layouts/login/login-layout";
import LoginForm from "../../components/auth/login/login-form/login-form";
import LoginFormWrapper from "../../components/auth/login/ui/LoginFormWrapper/LoginFormWrapper";


const Login: FC = () => {
    return (
        <LoginLayout>
            <LoginFormWrapper>
                <LoginForm/>
            </LoginFormWrapper>
        </LoginLayout>
    );
};

export default Login;