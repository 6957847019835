import React from 'react';

const Logout = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2 2C1.44772 2 1 2.44772 1 3V13C1 13.5523 1.44772 14 2 14H8C8.55228 14 9 13.5523 9 13V12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12H3V4H7C7 4.55228 7.44772 5 8 5C8.55228 5 9 4.55228 9 4V3C9 2.44772 8.55228 2 8 2H2ZM11.2929 9.29289C10.9024 9.68342 10.9024 10.3166 11.2929 10.7071C11.6834 11.0976 12.3166 11.0976 12.7071 10.7071L14.7071 8.70711C14.8946 8.51957 15 8.26522 15 8C15 7.73478 14.8946 7.48043 14.7071 7.29289L12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289C10.9024 5.68342 10.9024 6.31658 11.2929 6.70711L11.5858 7L6 7C5.44772 7 5 7.44771 5 8C5 8.55228 5.44771 9 6 9L11.5858 9L11.2929 9.29289Z" fill="white"/>
        </svg>
    );
};

export default Logout;