import {makeAutoObservable} from "mobx";

class EmailVerification{

    email = ''

    error = ''

    clear(){
        this.email = ''
        this.error = ''
    }
    constructor() {
        makeAutoObservable(this)
    }
}

export default new EmailVerification()