import styled from "styled-components/macro";

export const FormWrapper = styled.div`
    display: block;
    flex: 0 1 100%;
    background: #1b2228;
    padding: 16px;
    margin-bottom: 4px;
`

export const FormRow = styled.div`
  display: flex;
  flex-grow: 1;
`

export const InputGroup = styled.div`
  display: block;
  flex: 0 1 50%;
`

export const InputLabel = styled.div`
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  width: 100%;
  display: flex;
  padding-bottom: 8px;
`

export const TotalBalance = styled.span`
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 90px;
`

export const Rate = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
  margin-bottom: 16px;
`

export const ActionButton = styled.button`
  padding: 11px 53px;
  border: none;
  outline: none;
  color: #ffffff;
  background: #EB5746;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  
  &:disabled{
    background: #EAEAEA !important;
    color: rgba(0, 0, 0, 0.2);
  }
`

export const Fee = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  text-align: left;

`

export const FeeTitle = styled.div`
  font-weight: 400;
`

export const FeeAmount = styled.div`
  font-weight: 700;
`