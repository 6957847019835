import {action, makeAutoObservable} from "mobx";
import {getPairs} from "../../services/api/exchange/request";
import {IPair} from "../../types/exchange/pair.types";

class Pairs{

    pairs: IPair[] = []

    currentPair: IPair | {} = {}

    constructor() {
        makeAutoObservable(this,{
            getPairs: action
        })
    }

    async getPairs(){
       this.pairs = await getPairs()
       this.currentPair = this.pairs[0]
    }

    clear(){
        this.pairs = []
        this.currentPair = {}
    }
}

export default new Pairs()