import styled from "styled-components/macro";

export const ImageBlock = styled.div`
  margin-top: 150px;
`

export const ImageBackground = styled.div`
    width: 264px;
    height: 82px;
    background: rgba(27, 34, 40, 0.03);
    position: relative;
`

export const MailImage = styled.img`
  position: absolute;
  left: 60px;
  top: -20px;
`