import React, {FC} from 'react';
import {useHistory} from "react-router-dom";
import LoginLayout from "../../../components/layouts/login/login-layout";
import {
    Button,
    CopyRight,
    DescriptionWrapper,
    EmailVerificationWrapper, Row,
    Subtitle,
    Title
} from "../success-email-page/styled-success-email-page";

import Ufo from "../../../public/email-verification/ufo-img.png";
import {observer} from "mobx-react-lite";
import EmailVerification from "../../../store/email-verification/email-verification";
import {
    ImageBackground,
    ImageBlock,
    MailImage
} from "../../../components/auth/email-verification/plain-image/styled-plain-image";


const FailEmailPage :FC = observer( () => {
    const history = useHistory()

    return (
        <LoginLayout>
            <EmailVerificationWrapper style={{paddingTop: 0}}>
                <ImageBlock>
                    <ImageBackground>
                        <MailImage style={{left: '20px'}} src={Ufo}/>
                    </ImageBackground>
                </ImageBlock>
                <DescriptionWrapper>
                    <Title style={{color: '#EB5746'}}>
                        Verification failed
                    </Title>
                    <Subtitle>
                        {EmailVerification.error}
                    </Subtitle>
                    <Row>
                        <Button onClick={() => history.push('/login')}>
                            Login to Unicsoft
                        </Button>
                    </Row>
                </DescriptionWrapper>
                <div style={{marginTop: '50%'}}>
                    <CopyRight>
                        © 2023 Unicsoft. All rights reserved.
                    </CopyRight>
                </div>
            </EmailVerificationWrapper>
        </LoginLayout>
    );
});

export default FailEmailPage;