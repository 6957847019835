import {IResetPassword} from "../../../types/reset-password/reset-password.types";

export const resetPassword = async (data : IResetPassword) :Promise<void> =>{
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "email": data.email
    });

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/auth/reset-password`, requestOptions)

    const resJSON = await res.json()

    console.log(resJSON)
}