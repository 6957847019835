import React, {FC, ReactChild, ReactNode} from 'react'
import styled from "styled-components"

interface IDefaultProps {
    children: ReactChild | ReactNode
}

const DefaultWrapper = styled.div`
  min-height: 100vh;
  min-width: 100vh;
  
`

const Default: FC<IDefaultProps> = ({children}) => {
    return (
        <DefaultWrapper>
            {children}
        </DefaultWrapper>
    );
};

export default Default;