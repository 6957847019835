import React, {ChangeEvent, FC, useEffect, useMemo, useState} from "react";
import { BigButton, Coins, Funds, FundsTitle, ResetBtn, ResetRow, ReviewRow} from "../market-type/styled-market-type";
import Spacer from "../../../../../ui/spacer/spacer";
import Wallet from "../../../../../../public/icons/wallet";
import PercentSelector from "../../../../../ui/percent-selector/percent-selector";
import Input from "../../../../../global/input/input";
import {useForm} from "react-hook-form";
import Checkbox from "../../../../../ui/checkbox/checkbox";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import Pairs from "../../../../../../store/pairs/pairs";
import Balances from "../../../../../../store/balances/balances";
import {calculateCrypto, multiplyNumbers, normilizeFloat, symbolsAfterComma} from "../../../../../../utils/math/math";
import {ILimitOrder} from "../../../../../../types/orders/limit-order.types";
import {IPair} from "../../../../../../types/exchange/pair.types";
import ReviewSell from "../../../../../ui/modal/review/review/review";
import ModalWrapper from "../../../../../ui/modal/modal-wrapper/modal-wrapper";
import {toCoin, toCoins} from "../../../../../../utils/tools/calcs";
import {Coins as CryptoCoins} from '../../../../../../utils/constants/crypto'
import {sendLimitOrder} from "../../../../../../services/api/orders/limit-order";
import {toFixed} from "../../../../../../utils/numbers/toFixed";
import {getLastOrder} from "../../../../../../services/api/orders/order-book";

interface ILimitType{
    type: 'buy' | 'sell'
}

interface IFormOrder {
    amount: number;
    stopPrice: number;
    total: number;
    checked: boolean;
}

const forbiddenNumberInputValues = [
  '+',
  '-',
  'e'
]

//TODO fix numbers in total

const LimitType :FC<ILimitType> = observer( ({type}) => {

    const [range, setRange] = useState(0)

    const setFunds = (value : number) => {
        if(currentBalance){
            //TODO if wont work leave sell behavior for all
            if(type === 'sell'){
                const amount = Number( currentBalance.amount)*value/100
                const filteredAmount =  symbolsAfterComma(amount) > 7?  amount.toFixed(7)  :  amount.toFixed()
                setValue('amount', filteredAmount)

                const totalAmount = amount * Number( getValues('stopPrice') )
                const filteredTotalAmount = symbolsAfterComma(totalAmount) > 7?  totalAmount.toFixed(7)  : totalAmount.toFixed()
                setValue('total', filteredTotalAmount)
                setRange(value)
            }else {
                const total = Number( currentBalance.amount)*value/100
                const filteredTotal =  symbolsAfterComma(total) > 7?  total.toFixed(7)  :  total.toFixed()
                setValue('total', filteredTotal)

                const amount = total / Number( getValues('stopPrice') )
                const filteredAmount = symbolsAfterComma(amount) > 7?  amount.toFixed(7)  : amount.toFixed()
                setValue('amount', filteredAmount)
                setRange(value)
            }

        }
    }
    // const [sndRange, setSndRange] = useState(1)

    const defaultValues = {
        amount: '0',
        stopPrice: '0',
        total: '0',
        checked: false
    }

    const resetForm = () => {
        reset(JSON.parse( JSON.stringify(defaultValues)))
        setRange(0)
        // setSndRange(1)
    }

    const {register, reset, setValue, watch, handleSubmit, getValues} = useForm({
        defaultValues
    })

    const watchAllFields = watch()

    const handleTotalChange =  async (e:ChangeEvent<HTMLInputElement>) => {
        if(e.target.value === '' || String(e.target.value)[0] === '.' || String(e.target.value)[0] === ',')  {
            setValue('amount', '0')
            setValue('total', '0')
            return
        }
        forbiddenNumberInputValues.forEach(forbiddenValue => {
            if(e.target.value.includes(forbiddenValue)) {
                // setValue('stopPrice', '0')
                setValue('amount', '0')
                setValue('total', '0')
                return
            }
        })
        setValue('total', normilizeFloat(e.target.value))
        const amount = Number(e.target.value) / Number( watchAllFields.stopPrice)
        const filteredAmount = symbolsAfterComma(amount) > 7? amount.toFixed(7) : amount.toString()
        if(type === 'sell') setRange(parseInt( String(Number(filteredAmount) / Number(currentBalance.amount) * 100)))
        if(type === 'buy') setRange(parseInt( String(Number(e.target.value) / Number(currentBalance.amount) * 100)))
        setValue('amount', filteredAmount)
    }

    const handleAmountChange = async (e: ChangeEvent<HTMLInputElement>) => {
        try{
            if(!currentPair) return
            if(e.target.value === '' || String(e.target.value)[0] === '.' || String(e.target.value)[0] === ',')  {
                setValue('amount', '0')
                setValue('total', '0')
                return
            }
            forbiddenNumberInputValues.forEach(forbiddenValue => {
                if(e.target.value.includes(forbiddenValue)) {
                    setValue('amount', '0')
                    setValue('total', '0')
                    return
                }
            })
            setValue('amount', normilizeFloat(e.target.value))
            // const totalAmount = multiplyNumbers(Number(e.target.value), Number(watchAllFields.stopPrice))
            const totalAmount =  calculateCrypto({firstNumber: e.target.value, secondNumber:watchAllFields.stopPrice, firstCoin: currentPair.fromCoin.symbol, secondCoin: currentPair.toCoin.symbol })
            console.log('crypto')

            const filteredTotalAmount = toFixed(totalAmount)
            if(type === 'sell') setRange(parseInt( String(Number(e.target.value) / Number(currentBalance.amount) * 100)))
            if(type === 'buy') setRange(parseInt( String(Number(totalAmount) / Number(currentBalance.amount) * 100)))
            setValue('total', filteredTotalAmount)
        }catch (e) {
            console.log(e)
        }
    }

    const handlePriceChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if(!currentPair) return
        if(e.target.value === '' || String(e.target.value)[0] === '.' || String(e.target.value)[0] === ',')  {
            setValue('stopPrice', '0')
            setValue('total', '0')
            return
        }
        forbiddenNumberInputValues.forEach(forbiddenValue => {
            if(e.target.value.includes(forbiddenValue)) {
                setValue('stopPrice', '0')
                setValue('total', '0')
                return
            }
        })
        setValue('stopPrice', normilizeFloat(e.target.value))
        // const totalAmount = multiplyNumbers(Number(e.target.value), Number(watchAllFields.amount))
        const totalAmount = calculateCrypto({firstNumber: e.target.value, secondNumber:watchAllFields.amount, firstCoin: currentPair.fromCoin.symbol, secondCoin: currentPair.toCoin.symbol })
        const filteredTotalAmount = toFixed(totalAmount)
        console.log(totalAmount)

        if(type === 'buy') setRange(parseInt( String(Number(totalAmount) / Number(currentBalance.amount) * 100)))
        setValue('total', filteredTotalAmount)
    }

    const [currentBalance, setCurrentBalance] = useState<any>()

    const [currentPair, setCurrentPair] = useState<IPair>()

    const getCurrentBalance = async () => {
        if(Pairs.pairs.length === 0) await Pairs.getPairs()
        const currentPair = toJS(Pairs.currentPair)
        console.log('24')
        setCurrentPair(currentPair as IPair)
        if(Balances.balances.length === 0) await Balances.getBalances()
        console.log('25')
        const balances = toJS(Balances.balances)
        if( "fromCoin" in currentPair ){
            const balance = type === 'sell'? balances.find(el => el.name === currentPair.fromCoin.symbol) : balances.find(el => el.name === currentPair.toCoin.symbol)
            await setCurrentBalance(balance)
            console.log('26')
        }
    }
    useEffect(() => {
        (async function (){
            await getCurrentBalance()
        })()
    },[Pairs.currentPair])

    const [order, setOrder] = useState<ILimitOrder>()
    const [isOpen, setOpen] = useState<boolean>(false)

    const sendLimitTransaction = async (values :IFormOrder ) => {
        if(!currentPair) return

        const amount = String( toCoins(String( values.amount), CryptoCoins[ currentPair.fromCoin.symbol]) )
        const price = String( toCoins(String( values.stopPrice), CryptoCoins[ currentPair.toCoin.symbol]) )

        const order: ILimitOrder = {
            tradingPairId: currentPair.id,
            case: type === 'buy'? 'BUY' : 'SELL',
            price: Number( price),
            amount : Number(amount)
        }

        console.log(order)
        if(values.checked){
            return sendInstantLimitTransaction(order)
        }
        await setOrder(order)
        await setOpen(true)
    }

    const sendInstantLimitTransaction = async (currentOrder: ILimitOrder ) => {
        if(!currentPair) return
        try{
            if(!currentOrder) currentOrder = order as ILimitOrder
            const res = await sendLimitOrder(currentOrder)
            console.log(res)
            setOpen(false)
            await Balances.getBalances()
            await getCurrentBalance()
        }catch (e){
            console.log(e)
        }
    }

    const isValid = useMemo<boolean>(() => {
        try {
            const fields = watchAllFields
            if(Number( fields.amount) <= 0) return false
            if(Number(fields.stopPrice) <= 0) return false
            if(Number(fields.total) <= 0 ) return false
            if(type === 'buy'){
                const balances =  toJS( Balances.balances)
                const toCoinBalance =  balances.find(balance => balance.name === currentPair?.toCoin.symbol.toUpperCase())
                if(Number(fields.total) > Number( toCoinBalance.amount)) return false
            }
            if(type === 'sell'){
                const balances = toJS( Balances.balances)
                const fromCoinBalance = balances.find(balance => balance.name === currentPair?.fromCoin.symbol.toUpperCase())
                if(Number(fields.amount) > Number(fromCoinBalance.amount)) return false
            }
            return true
        }catch (e){
            return false
        }
    },[watchAllFields])

    useEffect(() => {
        (async function (){
            try {
                resetForm()
                if(currentPair){
                    const last = await getLastOrder(currentPair.id)
                    const lastOrderParsedPrice = toCoin(last.price, currentPair.toCoin.symbol).toFixed()
                    console.log(lastOrderParsedPrice)
                    setValue('stopPrice', lastOrderParsedPrice)
                }
            }catch (e){
                console.log(e)
            }
        })()
    },[currentPair])

    return (
      <span>
            {
                isOpen &&
                <ModalWrapper isOpened={true} callback={() => console.log('hello')} width={350} height={288}>
                    {/*@ts-ignore*/}
                    <ReviewSell callback={sendInstantLimitTransaction} price={order.price as ILimitOrder} type={type.toUpperCase()} amount={order.amount} closeModal={() => setOpen(false)} pair={currentPair}/>
                </ModalWrapper>
            }

          <form autoComplete="off" onSubmit={handleSubmit(sendLimitTransaction) }>
                {/*<Leverage>*/}
              {/*    <LevarageTitle> Leverage </LevarageTitle>*/}
              {/*    <LevarageSelect>*/}
              {/*        <coefficient-selector range={sndRange} setRange={setSndRange}/>*/}
              {/*    </LevarageSelect>*/}
              {/*</Leverage>*/}
              <div style={{marginTop:'30px'}}/>

                <Funds>
                    <FundsTitle>
                        <span style={{marginTop:'2px'}}> Funds </span>
                        <Spacer/>
                        <Coins>
                            {currentBalance?.amount} {currentBalance?.name}
                            <span style={{marginTop:'4px', marginLeft:'4px'}}> <Wallet/> </span>
                        </Coins>
                    </FundsTitle>
                </Funds>
                <PercentSelector range={range} setRange={setFunds}/>

                <div>
                    <Input placeholder={'Amount'} formikProps={{...register('amount'), onChange: handleAmountChange}} endPlaceholder={currentPair?.fromCoin.symbol} type='number' />
                </div>

                <div style={{marginTop:'24px'}}>
                    <Input placeholder={'Price'} formikProps={{...register('stopPrice'), onChange: handlePriceChange}} endPlaceholder={currentPair?.toCoin.symbol} type={'number'} />
                </div>

                <div style={{marginTop:'24px'}}>
                    <Input placeholder={'Total'} formikProps={{...register('total'), onChange:handleTotalChange}} endPlaceholder={currentPair?.toCoin.symbol} type={'number'} />
                </div>

                <ResetRow>
                    <ResetBtn onClick={() => resetForm()}>Reset</ResetBtn>
                </ResetRow>

                <ReviewRow>
                    <Checkbox label={'exchange without review'} form={{...register('checked')}}/>
                </ReviewRow>

                <div style={{marginLeft:'16px', marginRight:'16px'}}>
                    <BigButton pageType={type} type="submit" disabled={!isValid}> Review and {type === 'buy'? 'Buy': 'Sell'} {currentPair?.fromCoin.symbol} </BigButton>
                </div>

                <div style={{width:'100%', height:'16px', background:'#1B2228'}}/>
            </form>
        </span>
    );
});

export default LimitType;