import {makeAutoObservable} from "mobx";
import {IOrderHistoryItem} from "../../types/orders/order-history.types";
import {getOrderHistory} from "../../services/api/orders/order-history";

class OrderHistory{

  items:IOrderHistoryItem[] = []

  constructor() {makeAutoObservable(this)}

  async getItems(dateFrom: Date, dateTo: Date){
    this.items = await getOrderHistory(dateFrom, dateTo)
  }
  clear(){
    this.items = []
  }
}

export default new OrderHistory()