import {RegistrationForm} from "../../../types/registration/registration-form.types";

export const registerUser = async (data : RegistrationForm) => {
    try{
        const  headers = new Headers();
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email": data.email,
            "password": data.password
        });

        const requestOptions :any = {
            method: 'POST',
            headers,
            body: raw
        };

        const res = await fetch(`${process.env.REACT_APP_API_PATH}/users`, requestOptions)
        const resJSON = await res.json()
        console.log(resJSON)
    }catch (e){
        console.log(e)
    }
}