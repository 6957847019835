import React, {FC} from 'react';
import LoginLayout from "../../components/layouts/login/login-layout";
import ResetPasswordWrapper
  from "../../components/auth/reset-password/ui/reset-password-wrapper/reset-password-wrapper";
import ResetForm from "../../components/auth/reset-password/reset-form/reset-form";

const ResetPassword :FC= () => {
    return (
        <LoginLayout>
            <ResetPasswordWrapper>
                <ResetForm/>
            </ResetPasswordWrapper>
        </LoginLayout>
    );
};

export default ResetPassword;