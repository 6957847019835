import {makeAutoObservable} from "mobx";

type tabItem = {
    title: string,
    value: string
}

class MarketOrders {

    currentTab:tabItem = {title: 'Active', value: 'active'}

    activeOrders: number = 0

    constructor() {
        makeAutoObservable(this)
    }

    clear(){
        this.currentTab = {title: 'Active', value: 'active'}
        this.activeOrders = 0
    }

    setCurrentTab(tab: tabItem){
        this.currentTab = tab
    }
}

export default new MarketOrders()