export const HOUR_IN_MILLISECONDS = 60 * 60* 24

export enum STATUS_CODES {
  OK= 200,
  Created = 201,
  NoContent = 204,
  Unauthorized = 401,
  BadRequest = 400,
  Forbidden = 403,
  NotFound = 404,
  NotAllowed = 405,
  Conflict = 409,
  Gone= 410,
  ServerError= 500
}