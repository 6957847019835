import React, {FC} from 'react';
import styled from "styled-components";
import {observer} from "mobx-react-lite";
import MarketSidebar from "../../../../../../store/market-sidebar/market-sidebar";

const SidebarTabItemWrapper = styled.div`
  margin-right: 16px;
  cursor: pointer;
`

interface ITabItem {
    isActive: boolean
}

export const TabItemTitle = styled.div<ITabItem>`
  margin-bottom: ${props => props.isActive? '12px': '13px'};
  color: ${props => props.isActive? '#F9C510': '#ffffff'};
  padding-left: 7px;
  padding-right: 7px;
`

export const ActiveTab = styled.div<ITabItem>`
  width: 100%;
  height: 2px;
  background: #F9C510;
  display: ${props => props.isActive? 'block': 'none'};
`

interface ISidebarTabItem{
    value: string
}

const SidebarTabItem:FC<ISidebarTabItem> = observer( ({value}) => {

    const isActive = MarketSidebar.activeAction === value

    const handleTabSelect = () => {
        MarketSidebar.setActiveAction(value)
    }

    return (
        <SidebarTabItemWrapper onClick={handleTabSelect}>
            <TabItemTitle isActive={isActive}>{value}</TabItemTitle>
            <ActiveTab isActive={isActive}/>
        </SidebarTabItemWrapper>
    );
});

export default SidebarTabItem;