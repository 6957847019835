import {PeriodDate} from "../../types/orders/period-tabs.types";

export interface IPeriodItem{
  value: PeriodDate;
  title: string;
}
export const PeriodItems:IPeriodItem[] = [
  {
    value: 'day',
    title: '1 Day'
  },
  {
    value: 'week',
    title: '1 Week'
  },
  {
    value: 'one_month',
    title: '1 Month'
  },
  {
    value: 'three_month',
    title: '3 Month'
  }
]
