import React, {FC, useEffect, useState} from 'react';
import {
    HistoryTable as HistoryTableWrapper,
    HistoryTableBody,
    HistoryTableBodyItem,
    HistoryTableHead,
    HistoryTableHeadItem,
    HistoryTableRow
} from './styled-history-table'
import {ITransactionType} from "../../../../types/exchange/transactions.types";
import {getTransactions} from "../../../../services/api/exchange/request";
import {toCoin} from "../../../../utils/tools/calcs";
import {Coins} from "../../../../utils/constants/crypto";

interface IItem{
    item: ITransactionType
}

const Item :FC<IItem> = ({item}) => {
    const isBuying = item.fromCurrency === 'USD'
    const amountFrom = toCoin(item.amountFromCurrency, item.fromCurrency)
    const amountTo = toCoin(item.amountToCurrency, item.toCurrency)

    return(
        <HistoryTableRow>
          <HistoryTableBodyItem> {isBuying? item.toCurrency : item.fromCurrency} </HistoryTableBodyItem>
          <HistoryTableBodyItem> {isBuying? 'Buy' : 'Sell'} </HistoryTableBodyItem>
          <HistoryTableBodyItem> Unicsoft wallet </HistoryTableBodyItem>
          <HistoryTableBodyItem> { isBuying?  String(amountTo) : String(amountFrom) } </HistoryTableBodyItem>
          <HistoryTableBodyItem> {isBuying? `${toCoin( item.amountFromCurrency, Coins.USD)}` : `${toCoin(item.amountToCurrency, Coins.USD)}`} $ </HistoryTableBodyItem>
          <HistoryTableBodyItem> {`${new Date(item.createdAt).toLocaleString()}`} </HistoryTableBodyItem>
        </HistoryTableRow>
    )
}

const HistoryTable :FC = () => {

    const [items, setItems] = useState<ITransactionType[]>([])

    useEffect(() => {
        (async function (){
            const res: ITransactionType[] = await getTransactions()
            setItems(res)
        })()
    },[])

    return (
        <HistoryTableWrapper>
            <HistoryTableHead>
                <HistoryTableRow>
                    <HistoryTableHeadItem> Currency </HistoryTableHeadItem>
                    <HistoryTableHeadItem> Side </HistoryTableHeadItem>
                    <HistoryTableHeadItem> Wallet </HistoryTableHeadItem>
                    <HistoryTableHeadItem> Amount </HistoryTableHeadItem>
                    <HistoryTableHeadItem> Price </HistoryTableHeadItem>
                    <HistoryTableHeadItem> Date </HistoryTableHeadItem>
                </HistoryTableRow>
            </HistoryTableHead>
            <HistoryTableBody>
                {items.map(item => <Item item={item} key={item.id}/>)}
            </HistoryTableBody>
        </HistoryTableWrapper>
    );
};

export default HistoryTable;