import {DropDownItem} from "../../types/market/dropdown";

export const DROPDOWN_ITEMS: DropDownItem[] = [
    {
        title: 'Market',
        value: 'market'
    },
    {
        title: 'Limit',
        value: 'limit'
    },
    {
        title: 'Stop loss',
        value: 'stop_loss'
    },
    {
        title: 'Take profit',
        value: 'take_profit'
    },
    {
        title: 'Stop limit',
        value: 'stop_limit'
    }
]