interface IError{
    message: string
}

export async function activateEmail(code: string): Promise<IError>{
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        "code": code
    });

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/auth/activate-email`, requestOptions)

    const data = await res.text()
    if(data.length > 0) return JSON.parse(data)
    return { message: ''}
}