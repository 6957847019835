import React, {FC, useEffect} from 'react';
import styled from "styled-components/macro";
import {IPair} from "../../../../../types/exchange/pair.types";
import Spacer from "../../../spacer/spacer";
import {toCoin} from "../../../../../utils/tools/calcs";
import {Coins} from "../../../../../utils/constants/crypto";
import {toFixed} from "../../../../../utils/numbers/toFixed";

const ReviewWrapper = styled.div`
  border-radius: 8px;
`

const ReviewTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
  text-align: left;
`

const Type = styled.div`
  padding-bottom: 16px;
  padding-top: 16px;
  color: rgba(255, 255, 255, 0.4);
  text-align: left;
`

const Row = styled.div`
  display: flex;
`

const ButtonRow = styled(Row)`
  padding-top: 32px;
`

const Btn = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 4px;
`

const CancelButton = styled(Btn)`
  background: #1B2228;
  margin-right: 12px;
  border: 2px solid #171C21;
`

interface IActionBtn{
    btnType: 'BUY' | 'SELL'
}

const SuccessButton = styled.button<IActionBtn>`
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  border-radius: 4px;
  border: 2px solid ${props => props.btnType === 'BUY' ? '#1DAA70' : '#EB5746'};
  background: ${props => props.btnType === 'BUY' ? '#1DAA70' : '#EB5746'};
`

const Subtitle = styled.span`
  font-size: 13px;
  color: rgba(255, 255, 255, 0.4);
`

const Value = styled.span`
  text-align: right;
  color: #fff;
`

interface IReview{
    amount: number|string;
    closeModal: () => void;
    pair: IPair,
    type: 'BUY' | 'SELL',
    price: number|string,
    callback: () => void
}

const Review :FC<IReview> = ({amount, pair, closeModal, type, price, callback}) => {

    const parsedAmount = toCoin(String(amount), Coins[ pair.fromCoin.symbol]).toString()
    const parsedPrice = toCoin(String(price), Coins[ pair.toCoin.symbol]).toString()

    useEffect(() => {
      console.log(price)
    },[])
    return (
        <ReviewWrapper>
            <ReviewTitle> Confirm operation exchange {pair?.fromCoin.symbol}/{pair?.toCoin.symbol} </ReviewTitle>
            <Type> Market type </Type>
            <Row>
                <Subtitle>Amount</Subtitle>
                <Spacer/>
                <Value> {parsedAmount} {pair.fromCoin.symbol} </Value>
            </Row>
            <Row>
                <Subtitle>Price</Subtitle>
                <Spacer/>
                <Value> {parsedPrice} {pair.toCoin.symbol} </Value>
            </Row>
            <Row>
                <Subtitle>Transaction Fee</Subtitle>
                <Spacer/>
                <Value style={{color: '#EB5746'}}>
                    {
                        pair.feeType === 'FIXED'?
                        <span> { pair.fee} {pair.fromCoin.symbol} </span>
                        : <span>({pair.fee}%) {Number(pair.fee) * Number( amount)} {pair.fromCoin.symbol}</span>
                    }
                </Value>
            </Row>
            <Row>
                <Subtitle>Total</Subtitle>
                <Spacer/>
                <Value> {toFixed( Number( parsedPrice) * Number( parsedAmount))} {pair.toCoin.symbol} </Value>
            </Row>
            <ButtonRow>
                <Spacer/>
                <CancelButton onClick={() => closeModal()}>
                    Cancel
                </CancelButton>
                <SuccessButton btnType={type} onClick={ () => callback()}>
                    {type === 'BUY'? <span> Buy </span> : <span> Sell </span>} {pair.fromCoin.symbol}
                </SuccessButton>
            </ButtonRow>

        </ReviewWrapper>
    );
};

export default Review;