import {getToken} from "../../app/cookie/cookie";
import {IMarketOrder} from "../../../types/orders/market-order.types";

export async function sendMarketOrder( order : IMarketOrder ):Promise<any>{
  const headers = new Headers();
  headers.append("Authorization", getToken());
  headers.append("Content-Type", "application/json");
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(order)
  }

  console.log('order')
  console.log(order)

  const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/market-order`, requestOptions)

  const resJSON = await res.json()

  console.log(resJSON)
  return resJSON
}