import React, {FC} from 'react';
import useWindowDimensions from "../../../hooks/use-window-dimension";
import Default from "../default/default";
import {
    LeftCol,
    RightCol, RightColContentTitle,
    RightColContentWrapper,
    RightElipce,
    RowWrapper,
    TopElipce, WhiteDots
} from "./styled-login-layout";
import TopElipces from "../../../public/login/top_elipce.png";
import RightElipces from "../../../public/login/right_elipce.png";
import Header from "../../auth/login/ui/Header/Header";
import Btc from "../../../public/crypto/btc";
import Spacer from "../../ui/spacer/spacer";
import Btc_Graph from "../../../public/crypto_graph/btc_graph.png";
import Eth from "../../../public/crypto/eth.png";
import Eth_Graph from "../../../public/crypto_graph/eth_graph.png";
import Chz from "../../../public/crypto/chz.png";
import Chillz_Graph from "../../../public/crypto_graph/chillz_graph.png";
import WhiteDotsImage from "../../../public/login/white_dots_row.png";
import Fil from "../../../public/crypto/fil.png";
import Fil_Graph from "../../../public/crypto_graph/fil_graph.png";
import Ada from "../../../public/crypto/ada.png";
import Ada_Graph from "../../../public/crypto_graph/ada_graph.png";
import {CryptoItem, CryptoItemCoinName, CryptoItemTitle} from "../../auth/login/ui/crypto-item/styled-crypto-item";
import CryptoCoinItem from "../../auth/login/ui/crypto-item/crypto-item";

const LoginLayout :FC = ({children}) => {
    const {width} = useWindowDimensions()

    return (
        <Default>
            <TopElipce src={TopElipces}/>
            <RightElipce style={width > 1200? {}: {display:'none'}} src={RightElipces}/>
            <RowWrapper>
                <LeftCol width={2} style={{minWidth: '580px'}}>
                    <Header/>
                    {children}
                </LeftCol>
                <RightCol width={2}>
                    <RightColContentWrapper>
                        <RightColContentTitle>
                            A wide range of cryptocurrencies is waiting for you!
                        </RightColContentTitle>
                        <div style={width > 1200? {marginTop:'100px'}: {marginTop:'40px'}}/>

                        <CryptoItem>
                            <Btc/>
                            <CryptoItemTitle> BTC </CryptoItemTitle>
                            <CryptoItemCoinName> Bitcoin </CryptoItemCoinName>
                            <Spacer/>
                            <img alt='BTC' src={Btc_Graph}/>
                        </CryptoItem>

                        <CryptoCoinItem logoSrc={Eth} graphSrc={Eth_Graph} title={'ETH'} name={'Ethereum'} opacity={0.7}/>

                        <CryptoCoinItem logoSrc={Chz} graphSrc={Chillz_Graph} title={'CHZ'} name={'Chiliz'} opacity={0.5}/>

                        <div style={{width:'100%', height:'1px', background:'transparent', position:'relative'}}>
                            <WhiteDots alt='White dots' top={0} src={WhiteDotsImage}/>
                            <WhiteDots alt='White dots' top={30} src={WhiteDotsImage}/>
                            <WhiteDots alt='White dots' top={60} src={WhiteDotsImage}/>
                            <WhiteDots alt='White dots' top={90} src={WhiteDotsImage}/>
                            <WhiteDots alt='White dots' top={120} src={WhiteDotsImage}/>
                            <WhiteDots alt='White dots' top={150} src={WhiteDotsImage}/>
                        </div>

                        <CryptoCoinItem logoSrc={Fil} graphSrc={Fil_Graph} title={'FIL'} name={'Filecoin'} opacity={0.25}/>

                        <CryptoCoinItem logoSrc={Ada} graphSrc={Ada_Graph} title={'ADA'} name={'Cardano'} opacity={0.15} logoBorderRadius={'50%'}/>

                    </RightColContentWrapper>
                </RightCol>
            </RowWrapper>

        </Default>
    );
};


export default LoginLayout ;