import React, {FC} from 'react';
import styled from "styled-components/macro";
import {IOrderBookItem} from "../../../../../types/orders/order-book.types";
import {toCoin} from "../../../../../utils/tools/calcs";
import {Coins} from "../../../../../utils/constants/crypto";
import {IPair} from "../../../../../types/exchange/pair.types";
import Pairs from "../../../../../store/pairs/pairs";
import {observer} from "mobx-react-lite";
import {toFixed} from "../../../../../utils/numbers/toFixed";

const ColumnTitle = styled.div`
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
`

const TableHeaderRow = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 16px;
`

const TableHeaderItem = styled.span`
  flex: 0 1 33%;
  text-align: left;
  color: rgba(255, 255, 255, 0.2);;
`

const BuyItemWrapper = styled.div`
  display: flex;
  font-size: 13px;
  margin-bottom: 4px;
  color: #ffffff;
`

const BuyItemField = styled.span`
  flex: 0 1 33%;
  text-align: left;
`

interface ISellItem{
    item: IOrderBookItem,
    currentPair: IPair
}

const cutString = (value:string, symbolsAmount: number) =>{
    if(value.length < symbolsAmount) return value
    return String( value).slice(0, symbolsAmount)
}

const SellItem :FC<ISellItem> =  ({item, currentPair}) => {

    const parsedAmount = toCoin(item.amount, Coins[currentPair.fromCoin.symbol]).toString()
    const parsedPrice = toCoin(item.price, Coins[currentPair.toCoin.symbol]).toString()
    const total = Math.floor( Number(parsedPrice) * Number(parsedAmount) * 10000 ) /10000

    return(
        <BuyItemWrapper>
            <BuyItemField style={{color:'#EB5746'}}>
                {cutString( toFixed( parsedPrice), 12)}
            </BuyItemField>
            <BuyItemField>
                {cutString( toFixed( parsedAmount), 12)}
            </BuyItemField>
            <BuyItemField  style={{textAlign:"right"}}>
                {cutString( toFixed( total  ), 12 )}
            </BuyItemField>
        </BuyItemWrapper>
    )
}

interface ISell{
    items: IOrderBookItem[]
}

const Sell :FC<ISell> = observer( ({items}) => {
    const currentPair = Pairs.currentPair as IPair

    return (
        <div>
            <ColumnTitle>Sell</ColumnTitle>
            <TableHeaderRow>
                <TableHeaderItem>Price({currentPair.toCoin.symbol.toUpperCase()})</TableHeaderItem>
                <TableHeaderItem>Amount({currentPair.fromCoin.symbol.toUpperCase()})</TableHeaderItem>
                <TableHeaderItem style={{textAlign:'right'}}>Total</TableHeaderItem>
            </TableHeaderRow>
            {
                items.map((item,id) => <SellItem currentPair={currentPair} item={item} key={id}/>)
            }
        </div>
    );
});

export default Sell;