import React, {FC} from 'react';
import styled from "styled-components";
import SidebarTabItem from "./SidebarTabItem/sidebar-tab-item";

const SidebarTabsWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #151A1E;
  height: 45px;
  display: flex;
  align-items: flex-end;
`


const SidebarTabs : FC =  () => {
    return (
        <SidebarTabsWrapper>
            <SidebarTabItem value={'Buy'}/>
            <SidebarTabItem value={'Sell'}/>
        </SidebarTabsWrapper>
    );
};

export default SidebarTabs;