import {getToken} from "../../app/cookie/cookie";
import {ILimitOrder} from "../../../types/orders/limit-order.types";

export async function sendLimitOrder( order : ILimitOrder ):Promise<any>{
    const headers = new Headers();
    headers.append("Authorization", getToken());
    headers.append("Content-Type", "application/json");
    const requestOptions = {
        method: 'POST',
        headers,
        body: JSON.stringify(order)
    }

    console.log('order')
    console.log(order)

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/limit-order`, requestOptions)

    const resJSON = await res.json()

    console.log(resJSON)
    return resJSON
}