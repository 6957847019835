import React, {FC} from 'react';
import {IFavoritePair, IFavoritePairs} from "../../../../../types/favorite-pairs/favorite-pairs.types";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import FavoritePairsStore from '../../../../../store/favorite-pairs/favorite-pairs'
import {toJS} from "mobx";
import arrayMove from "array-move";
import Movable from "../../../../../public/icons/movable";
import Delete from "../../../../../public/icons/delete";
import {changePairsOrder, deleteFavoritePair} from "../../../../../services/api/favorite-pairs/favorite-pairs";
import {STATUS_CODES} from "../../../../../utils/constants/time";
import styled from "styled-components/macro";

const DragHandle = SortableHandle(() => <span style={{cursor: 'move'}}> <Movable /> </span> );


const ListItem = styled.li`
  width: 100%;
  padding: 5px;
  z-index: 999;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
  
  &:hover{
    color: #F5C210;
  }
`

interface IFavoritePairItem{
  favoritePair: IFavoritePair,
  search: string
}

const Item: FC<IFavoritePairItem> = ({favoritePair, search}) => {

  const handleDelete = async () :Promise<void> => {
    const status =  await deleteFavoritePair(favoritePair.id)
    if(status === STATUS_CODES.OK) await FavoritePairsStore.getFavoritePairs()
  }

    return(
        <li style={{ width: '100%',  padding: '5px', zIndex: 999, color: '#ffffff', fontSize: '13px', fontWeight: 600}}>
          <div style={{display:'flex'}}>
            <div style={{flex: '1 1 5%'}}>
              {search === ''?
                <DragHandle />:
                <span> <Movable  color={'#515B63'}/> </span>
              }
            </div>
            <div style={{flex: '1 1 30%', textAlign:'left'}}>
              {favoritePair.tradingPair.fromCoin.symbol}/{favoritePair.tradingPair.toCoin.symbol}
            </div>
            <div style={{flex: '1 1 30%', textAlign:'left'}}>
              {/*@ts-ignore*/}
              {favoritePair.tradingPair.fromCoin[`${favoritePair.tradingPair.toCoin.symbol.toLowerCase()}Price`]}
            </div>
            <div style={{flex: '1 1 30%', textAlign:'center'}}>
              <i>Coming soon</i>
            </div>
            <div style={{flex: '1 1 5%', cursor: 'pointer'}} onClick={() => handleDelete()}>
              <Delete/>
            </div>

          </div>
          </li>
    )
}

const SortableItem = SortableElement(Item);

const Components :FC = ({children})  => {
  return <div>
    <ul style={{listStyle: 'none'}}>{children}</ul>
  </div>;
}
const SortableContainers = SortableContainer(Components);

interface ISortableFavoriteList{
  favoritePairs: IFavoritePairs,
  search: string
}
const SortableComponent :FC<ISortableFavoriteList> = ({favoritePairs, search}) => {
  const onSortEnd = async ({oldIndex, newIndex} : {oldIndex:number, newIndex: number}) => {
    const newArray = arrayMove(toJS(FavoritePairsStore.favoritePairs), oldIndex, newIndex)
    console.log(newArray)
    let counter:number = -1
    const indexedArray : Array<{ pairId: number, positionIndex: number }> = newArray.map(el => {
      counter += 1
      return{
          pairId: el.id,
          positionIndex: counter
        }
      }
    )
    console.log('indexed array')
    console.log(indexedArray)
    FavoritePairsStore.setFavoritePairs(newArray)
    const isPatched = await changePairsOrder({favouriteCryptoPairOrderData: indexedArray})
    if(isPatched){
      await FavoritePairsStore.getFavoritePairs()
    }
  };

  return(
    <SortableContainers onSortEnd={onSortEnd} useDragHandle>
      {favoritePairs.map((pair, id) =>
        <SortableItem favoritePair={pair} index={id} key={pair.id} search={search}/>
      )}
    </SortableContainers>
  )
}

export default SortableComponent