import React, {FC} from 'react'
import UnicsoftLogo from "../../../../../public/unicsoft_logo.png"
import {HeaderStyle, LogoWrapper} from "./StyledHeader"

const Header: FC = () => {
    return (
        <HeaderStyle>
            <LogoWrapper> <img alt='Unicsoft logo' src={UnicsoftLogo}/> </LogoWrapper>
            <span> Unicsoft </span>
        </HeaderStyle>
    )
}

export default Header