import React from 'react';
import styled from "styled-components";

const StyledSpacer = styled.span`
    flex-grow: 1;
`

const Spacer = () => {
    return (
        <StyledSpacer/>
    );
};

export default Spacer;