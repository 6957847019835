import React, {FC, useEffect, useState} from 'react';
import styled from "styled-components/macro";
import {observer} from "mobx-react-lite";
import EmailVerification from "../../../../store/email-verification/email-verification";
import {useHistory} from "react-router-dom";
import {toJS} from "mobx";
import {resendEmail} from "../../../../services/api/resend-email/resend-email";

const DescriptionWrapper = styled.div`
    margin-top: 32px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: #253545;
  text-align: left;
`

const Subtitle = styled.div`
  margin-top: 8px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: rgba(37, 53, 69, 0.6);
`

const Row = styled.div`
  display: flex;
  margin-top: 58px;
`

const Button = styled.button`
  border: none;
  outline: none;
  color: #ffffff;
  padding: 10px 32px;
  background: #1B2228;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`


const Description :FC = observer( () => {

    const history = useHistory()

    const [isLoading, setLoading] = useState<boolean>(true)

    const resendUserEmail = async () => {
        const status = await resendEmail(toJS(EmailVerification.email))
        if(status < 300) await history.push('/login')
    }


    useEffect(() => {
        console.log(EmailVerification.email)
        if(toJS( EmailVerification.email) === '') return  history.go(-1)
        setLoading(false)
    },[])

    if(isLoading){
        return (<div>Loading...</div>)
    }

    return (
        <DescriptionWrapper>
            <Title>
                Verify your email address
            </Title>
            <Subtitle>
                We sent a confirmation email to {toJS(EmailVerification.email)}
                Check your email and click on the confirmation link to continue.
            </Subtitle>
            <Row>
                <Button onClick={() => resendUserEmail()}>
                    Resend email
                </Button>
            </Row>

        </DescriptionWrapper>
    );
});

export default Description;