import React, {FC, useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";
import Pairs from "../../../../store/pairs/pairs";
import {toJS} from "mobx";
import {Coins} from "../../../../utils/constants/crypto";
import {
  HighlightedText, PairChange,
  PairLastPrice, PairRow,
  PairSelectWrapper,
  PairTitle,
  SearchInput,
  TableHeader,
} from "./styled-pair-select";
import Add from "../../../../public/icons/add";
import {IPair} from "../../../../types/exchange/pair.types";
import FavoritePairsStore from '../../../../store/favorite-pairs/favorite-pairs';
import Delete from "../../../../public/icons/delete";
import {addFavoritePair, deleteFavoritePair} from "../../../../services/api/favorite-pairs/favorite-pairs";
import {IFavoritePair} from "../../../../types/favorite-pairs/favorite-pairs.types";
import {STATUS_CODES} from "../../../../utils/constants/time";
import SortableFavoriteList from "./sortable-favorite-list/sortable-favorite-list";
import Tabs from "./tabs/tabs";

interface IPairItem{
  pair: IPair,
  search: string
}
//TODO fix ts-ignore

const PairItem :FC<IPairItem> = observer( ({pair, search}) => {

  const [isChosen, setChosen] = useState<boolean>(false)
  const [currentFavoritePair, setCurrentFavoritePair] = useState<IFavoritePair>()
  useEffect(() => {
    const neededPair =  FavoritePairsStore.favoritePairs.find(item => item.tradingPair.id === pair.id)
    if(neededPair !== undefined){
      setChosen(true)
      setCurrentFavoritePair(neededPair)
    }
  },[FavoritePairsStore.favoritePairs])

  const highLightSearchLetters = (pairTitle: string, search: string) => {
    if(search === '') return pairTitle
    search = search.toUpperCase()
    if(pairTitle.includes(search)){
      const array =  pairTitle.split(search)
      return (
        <span>
          {array[0]}
          <HighlightedText>{search}</HighlightedText>
          {array[1]}
        </span>
      )
    }
  }

  const handleAction = async () :Promise<void> => {
    if(isChosen && currentFavoritePair){
      const status =  await deleteFavoritePair(currentFavoritePair?.id)
      if(status === STATUS_CODES.OK) setChosen(false)
    }
    if(!isChosen) {
      const status = await addFavoritePair(pair.id)
      if(status === STATUS_CODES.OK) setChosen(true)
    }
    await FavoritePairsStore.getFavoritePairs()
  }

  return(
    <PairRow onClick={() => handleAction()}>
      <PairTitle>
        {highLightSearchLetters(`${pair.fromCoin.symbol}/${pair.toCoin.symbol}`, search)}
      </PairTitle>
      <PairLastPrice>
        {/*@ts-ignore*/}
        {pair.fromCoin[`${pair.toCoin.symbol.toLowerCase()}Price`]}
      </PairLastPrice>
      <PairChange>
        <i>Coming soon</i>
      </PairChange>
      <div style={{flex: '0 1 10%', cursor: 'pointer'}}>
        {isChosen? <Delete/> : <Add/>}
      </div>
    </PairRow>
  )
})

const PairsSelect :FC = observer( () => {

    const [tab, setTab] = useState<'All'| 'Favorites'>('All')

    const pairs = Pairs.pairs.filter((pair) => {
      return !(pair.fromCoin.symbol === Coins.USD || pair.toCoin.symbol === Coins.USD);
    })

    const [search, setSearch] = useState<string>('')

    const filteredPairs = useMemo(() => {
      if(tab === 'Favorites') return toJS(FavoritePairsStore.favoritePairs.filter(el => `${el.tradingPair.fromCoin.symbol}/${el.tradingPair.toCoin.symbol}`.includes(search.toUpperCase())))
      return toJS(pairs.filter(el => `${el.fromCoin.symbol}/${el.toCoin.symbol}`.includes(search.toUpperCase())))
    }, [pairs, search, tab, FavoritePairsStore.favoritePairs])

    useEffect(()=> {
        (async function (){
            if(Pairs.pairs.length === 0){
                await Pairs.getPairs()
            }
        })()
    } ,[])

  return (
        <PairSelectWrapper>
            <div className="">
                <SearchInput onClick={(e) => e.stopPropagation()} placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)}/>
            </div>
            <Tabs name={'All'} activeTab={tab} setActiveTab={setTab}/>
            <TableHeader>
                <PairTitle> Pair </PairTitle>
                <PairLastPrice> Last Price </PairLastPrice>
                <PairChange> 24h Change </PairChange>
                <div style={{flex: '0 1 10%'}}>

                </div>
            </TableHeader>
          {
            tab === 'All' &&
            <>
              {filteredPairs.map(pair =>
                // @ts-ignore
                <PairItem key={pair.createdAt} search={search}  pair={pair}/>
              )}
            </>
          }
          {
            tab === 'Favorites' &&
            // @ts-ignore
            <SortableFavoriteList favoritePairs={filteredPairs} search={search}/>
          }
        </PairSelectWrapper>
    )
})

export default PairsSelect