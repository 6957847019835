import React, {FC} from 'react'
import { TableTimeSelector} from "./styled-table-time-items"
import TableTimeItem from "./table-time-item/table-time-item"
import {ITimeTableItem} from "../../../../../types/market/time-table-item";

type TimeTableMap = Record<string, ITimeTableItem>

const TIME_TABLE_VALUES: TimeTableMap = {
        '15M' : {title: '15M', value: '15'},
        '1H' : {title: '1H', value: '60'},
        '1D': {title: '1D', value: 'D'},
        '1W': {title: '1W', value: 'W'},
        '1M': {title: '1M', value: 'M'}
}


const TableTimeItems : FC= () => {
    return (
        <TableTimeSelector>
            <TableTimeItem item={TIME_TABLE_VALUES['15M']}/>

            <TableTimeItem item={TIME_TABLE_VALUES['1H']}/>

            <TableTimeItem item={TIME_TABLE_VALUES['1D']}/>

            <TableTimeItem item={TIME_TABLE_VALUES['1W']}/>

            <TableTimeItem item={TIME_TABLE_VALUES['1M']}/>

        </TableTimeSelector>
    );
};

export default TableTimeItems;