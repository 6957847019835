import React, {FC, useEffect} from 'react';
import styled from "styled-components/macro";
import {IOrderBookItem} from "../../../../../types/orders/order-book.types";
import {observer} from "mobx-react-lite";
import Pairs from '../../../../../store/pairs/pairs'
import {IPair} from "../../../../../types/exchange/pair.types";
import {toCoin} from "../../../../../utils/tools/calcs";
import {Coins} from "../../../../../utils/constants/crypto";
import {toFixed} from "../../../../../utils/numbers/toFixed";

const ColumnTitle = styled.div`
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
`

const TableHeaderRow = styled.div`
  display: flex;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 16px;
`

const TableHeaderItem = styled.span`
  flex: 0 1 33%;
  text-align: left;
  color: rgba(255, 255, 255, 0.2);;
`

const BuyItemWrapper = styled.div`
  display: flex;
  font-size: 13px;
  margin-bottom: 4px;
  color: #ffffff;
`

const BuyItemField = styled.span`
  flex: 0 1 33%;
  text-align: left;
  
`

const cutString = (value:string, symbolsAmount: number) =>{
  if(value.length < symbolsAmount) return value
  return String( value).slice(0, symbolsAmount)
}

interface IBuyItem{
    item: IOrderBookItem,
    currentPair: IPair
}

const BuyItem :FC<IBuyItem> =  ({item, currentPair}) => {

    const parsedAmount = toCoin(item.amount, Coins[currentPair.fromCoin.symbol]).toString()
    const parsedPrice = toCoin(item.price, Coins[currentPair.toCoin.symbol]).toString()
    const total = Math.floor( Number(parsedPrice) * Number(parsedAmount) * 10000 ) /10000

    return(
        <BuyItemWrapper>
            <BuyItemField style={{}}>
              {cutString( toFixed( total  ), 12 )}
            </BuyItemField>
            <BuyItemField>
              {cutString( toFixed( parsedAmount), 12)}
            </BuyItemField>
            <BuyItemField style={{textAlign:"right", color:'#31BC83'}}>
              {cutString( toFixed( parsedPrice), 12)}
            </BuyItemField>
        </BuyItemWrapper>
    )
}

interface IBuy{
    items: IOrderBookItem[]
}

const Buy :FC<IBuy> = observer(  ({items}) => {
    const currentPair = Pairs.currentPair as IPair

    useEffect(() => {
      (async function (){
        if(Pairs.pairs.length === 0) await Pairs.getPairs()
        console.log(Pairs.currentPair)
      })()

    })

    return (
        <div>
            <ColumnTitle style={{textAlign:'right'}}>Buy</ColumnTitle>
            <TableHeaderRow>
              <TableHeaderItem >Total</TableHeaderItem>

              <TableHeaderItem>Amount({currentPair.fromCoin.symbol})</TableHeaderItem>
              <TableHeaderItem style={{textAlign:'right'}}>Price({currentPair.toCoin.symbol})</TableHeaderItem>

            </TableHeaderRow>
            {
                items.map((item,id) => <BuyItem currentPair={currentPair} item={item} key={id}/>)
            }
        </div>
    );
});

export default Buy;