import React from 'react';
import LoginLayout from "../../components/layouts/login/login-layout";
import ForgotPasswordWrapper
  from "../../components/auth/forgot-password/ui/forgot-password-wrapper/forgot-password-wrapper";
import ForgotForm from "../../components/auth/forgot-password/forgot-form/forgot-form";

const ForgotPassword = () => {
    return (
        <LoginLayout>
            <ForgotPasswordWrapper>
                <ForgotForm/>
            </ForgotPasswordWrapper>
        </LoginLayout>
    );
};

export default ForgotPassword;