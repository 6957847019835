import React, {ChangeEvent, FC} from 'react';
import styled from "styled-components";
import './percent-selector.css'

interface IContainer{
    range: number
}

const Container = styled.div<IContainer>`
  position: relative;
  height: 4px;
  margin-bottom: 32px;
  background: linear-gradient(
          90deg,
          #fff ${props => props.range}%,
          #26323c ${props => props.range}%
  )
`

interface IPercentSelector{
    range: number,
    setRange: (range: number) => void
}

const PercentSelector :FC<IPercentSelector> = ({range, setRange}) => {

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setRange(Number( e.target.value))
    }

    return (
        <div className="main">
            <Container className="container" range={range}>
                <input type="range" min="0" max="100" value={range} onChange={ handleChange} className="range"/>
                <div className="super__dot one active" onClick={() => setRange(0)}>
                    <span className="percent">0%</span>
                    <span className='super__minidot'/>
                </div>
                <div className={`dot two ${range >= 25? 'active' : ''}`} onClick={() => setRange(25)} >
                    <span className="percent">25%</span>
                </div>
                <div className={`dot three ${range >= 50? 'active' : ''}`} onClick={() => setRange(50)}>
                    <span className="percent">50%</span>
                </div>
                <div className={`dot four ${range >= 75? 'active' : ''}`} onClick={() => setRange(75)}>
                    <span className="percent">75%</span>
                </div>
                <div className={`dot five ${range >= 100? 'active' : ''}`} onClick={() => setRange(100)} >
                    <span className="percent">100%</span>
                </div>
            </Container>
        </div>
    );
};

export default PercentSelector;