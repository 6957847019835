import {getToken} from "../../app/cookie/cookie";

export async function getOrderHistory(dateFrom:Date, dateTo:Date){
  const headers = new Headers();
  headers.append("Authorization", getToken());

  const requestOptions = {
    method: 'GET',
    headers: headers,
  }
  console.log(dateFrom)
  console.log(dateTo)
  const params = new URLSearchParams({dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString()})
  console.log(params.toString())
  const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/order-history?${params.toString()}`, requestOptions)
  return await res.json()
}