import {ILoginForm} from "../../../types/login/login-form.types";
import {setCookie} from "../../app/cookie/cookie";
import User from "../../../store/user/user";
import AppSession from "../../../store/app-session/app-session";
import EmailVerification from "../../../store/email-verification/email-verification";
import {STATUS_CODES, HOUR_IN_MILLISECONDS} from "../../../utils/constants/time";

interface ILoginData {
    token: string,
    role: string
}

export const login = async (data: ILoginForm) :Promise<number> => {
    try{
        const  headers = new Headers();
        headers.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email": data.email,
            "password": data.password
        });

        const requestOptions = {
            method: 'POST',
            headers,
            body: raw
        };

        const res = await fetch(`${process.env.REACT_APP_API_PATH}/auth/login`, requestOptions)
        if(!res.ok) {
            if(res.status === STATUS_CODES.NotAllowed){
                EmailVerification.email = data.email
                return STATUS_CODES.NotAllowed
            }
            if(res.status === STATUS_CODES.Unauthorized) return STATUS_CODES.Unauthorized
            return 0
        }
        const resJSON: ILoginData = await res.json()
        console.log(resJSON)
        setCookie('token', resJSON.token, {secure: true, 'max-age': HOUR_IN_MILLISECONDS})
        User.checkForUser()
        if(!data.stayLoggedIn){
            AppSession.setDeleteSession(true)
            console.log('yes')
        }
        return STATUS_CODES.OK
    }catch (e){
        console.log(e)
        return STATUS_CODES.ServerError
    }
}