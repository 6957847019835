import CoinStore from "../../../store/coins/coins";

import {toJS} from "mobx";
import {toCoin} from "../../../utils/tools/calcs";
import {Coins} from "../../../utils/constants/crypto";
import Btc from "../../../public/crypto/bitcoin.png";
import Eth from "../../../public/crypto/eth.png";
import Usdt from "../../../public/crypto/usdt.png";
import Cex from "../../../public/crypto/cex.png";
import Ada from "../../../public/crypto/ada.png";
import Usdc from "../../../public/crypto/usdc.png";
import Xrp from "../../../public/crypto/xrp.png";
import Uni from "../../../public/crypto/uni.png";
import Dot from "../../../public/crypto/dot.png";
import Bnb from "../../../public/crypto/bnb.png";
import Doge from "../../../public/crypto/dogee.png";
import {getUserBalances} from "../../api/exchange/request";


export type ParsedCrypto = {
    symbol: string,
    name: string,
    src: string,
    crypto: string,
    price: number
    // price: string
}

const balances = new Map([
    ['btc', {symbol: Coins.BTC, name: 'Bitcoin', src: Btc }],
    ['eth', {symbol: Coins.ETH, name: 'Ethereum', src: Eth }],
    ['usdt', {symbol: Coins.USDT, name: 'USD Tether', src: Usdt }],
    ['cex', {symbol: Coins.CEX, name: 'CentExge', src: Cex }],
    ['ada', {symbol: Coins.ADA, name: 'Cardano', src: Ada }],
    ['usdc', {symbol: Coins.USDC, name: 'USD Coin', src: Usdc }],
    ['doge', {symbol: Coins.DOGE, name: 'Doge Coin', src: Doge }],
    ['xrp', {symbol: Coins.XRP, name: 'Ripple', src: Xrp }],
    ['bnb', {symbol: Coins.BNB, name: 'Binance', src: Bnb }],
    ['dot', {symbol: Coins.DOT, name: 'DOT', src: Dot }],
    ['uni', {symbol: Coins.UNI, name: 'Universal coin', src: Uni }],
])

export async function parseCoins() {
    const balanceArray = []
    await CoinStore.getCoins()
    const res = await getUserBalances()
    for(const [key, value] of Object.entries( res)){
        try{
            const crypto =  balances.get(key)
            // @ts-ignore
            const symbol = crypto.symbol
            const price = toJS( CoinStore.coins).find(el => el.name === symbol)
            // @ts-ignore
            const cryptoCoin = String( toCoin(value, Coins[crypto.symbol]) )

            const parsedCrypto = Object.assign({
                crypto: cryptoCoin,
                price: Number( Number( cryptoCoin) * Number( price?.usdPrice) )
            },crypto)

            balanceArray.push(parsedCrypto)
        }catch (e){
            console.log(e)
        }

    }
    return balanceArray
}