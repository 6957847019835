import React, {FC} from 'react';
import styled from "styled-components";
import HistoryTable from "./history-table/history-table";

const HistoryWrapper = styled.div`
  background: #1b2228;
  padding: 16px;

`

const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
  margin-bottom: 8px;
`

const History :FC = () => {
    return (
        <HistoryWrapper>
            <Title>
                Buy/Sell history
            </Title>
            <HistoryTable/>
        </HistoryWrapper>
    );
};

export default History;