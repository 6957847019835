import React, {FC} from 'react';
import LoginLayout from "../../components/layouts/login/login-layout";
import styled from "styled-components/macro";
import {useHistory} from "react-router-dom";
import PlainImage from "../../components/auth/email-verification/plain-image/plain-image";
import Description from "../../components/auth/email-verification/description/Description";


const EmailVerificationWrapper = styled.div`
  margin-left: 70px;
`

const SignInRow = styled.div`
  font-size: 13px;
  text-align: left;
`

const CopyRight = styled.div`
  color: rgba(37, 53, 69, 0.4);
  font-size: 13px;
  text-align: left;
  margin-top: 32px;
`

const EmailVerification :FC = () => {

    const history = useHistory()
    return (
        <LoginLayout>
            <EmailVerificationWrapper>
                <PlainImage/>
                <Description/>
                <div style={{marginTop: '40%'}}>
                    <SignInRow>
                        Already have an account? <span style={{fontWeight: 600, cursor: 'pointer'}} onClick={() => history.push('/login')}> Sign in </span>
                    </SignInRow>
                    <CopyRight>
                        © 2021 Unicsoft. All rights reserved.
                    </CopyRight>
                </div>
            </EmailVerificationWrapper>
        </LoginLayout>
    );
};

export default EmailVerification;