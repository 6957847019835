import styled from "styled-components";

export const HistoryTable = styled.table`
  display: block;
  width: 100%;
  margin-top: 16px;
  flex: 1 1 100%;
  
`

export const HistoryTableHead = styled.thead`
  display: flex;
`

export const HistoryTableHeadItem = styled.th`
  display: flex;
  flex: 1 1 100%;
  color: rgba(255, 255, 255, 0.2);
  font-size: 12px;
  font-weight: 400;
`

export const HistoryTableRow = styled.tr`
  display: flex;
  flex-grow: 1;
`

export const HistoryTableBody = styled.tbody`
  display: flex;
  flex-direction: column;
`
export const HistoryTableBodyItem = styled.td`
  display: flex;
  flex: 1 1 100%;
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  margin-top: 17px;
  padding-bottom: 4px;
  border-bottom: 1px solid #151A1E;
`

export default {}