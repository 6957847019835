import React, {FC, useEffect, useState} from 'react';
import { DateRange } from 'react-date-range';
import styled from "styled-components/macro";
import './datepicker-styles.css'
import './datepicker-theme.css'

const Picker = styled.div`
  background: #151A1E;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  margin-right: 4px;
  position: relative;
  min-width: 140px;
  text-align: left;
  cursor: pointer;
`

interface IDatePickerWrapper{
  open: boolean,
}

const DatePickerWrapper = styled.div<IDatePickerWrapper>`
  display: ${props => props.open? 'block' : 'none'};
  position: absolute;
  bottom: 40px;
  right: 0;
`

interface IDatePicker{
  open: boolean,
  setOpen: (value: boolean) => void,
  date: any,
  setDate: (date: any) => void
}

const DatePicker : FC<IDatePicker> = ({open, setOpen, date, setDate}) => {

  const handleClick = () => {
    if(open) setOpen(false)
  };

  useEffect(() => {
    if(open){
      window.addEventListener('click',  handleClick)
    }

    return () => {
      window.removeEventListener('click', handleClick)
    }
  },[handleClick])

  return(
    <DatePickerWrapper onClick={event => event.stopPropagation()} open={open}>
      {/*// @ts-ignore*/}
      <DateRange editableDateInputs={true} onChange={item => setDate([item.selection])} moveRangeOnFirstSelection={false} ranges={date} maxDate={new Date()} color={'black'}/>
    </DatePickerWrapper>
  )
}

interface IDatePickerSelect{
  active: boolean;
  onAction: (action:'tab'|'select') => void;
  name: 'tab'|'select'
  date: any,
  setDate: any
}

const DatepickerSelect :FC<IDatePickerSelect> = ({active, onAction, name, date, setDate}) => {

  const [open, setOpen] = useState<boolean>(false)

  const openModal = (e:React.MouseEvent) => {
    e.stopPropagation()
    onAction(name)
    setOpen(prevState => !prevState)
  }


  return (
    <Picker onClick={(e) => openModal(e)} >
      <span style={active? {color: '#ffffff'}:{}} > {date[0].startDate.toLocaleDateString()} - {date[0].endDate.toLocaleDateString()} </span>
      <DatePicker open={open} setOpen={setOpen} date={date} setDate={setDate} />
    </Picker>
  );
};

export default DatepickerSelect;