import React from 'react';
import LoginLayout from "../../components/layouts/login/login-layout";
import RegistrationForm from "../../components/auth/registration/registration-form/registration-form";
import RegistrationWrapper from "../../components/auth/registration/ui/registration-wrapper/registration-wrapper";

const Registration = () => {
    return (
        <LoginLayout>
            <RegistrationWrapper>
                <RegistrationForm/>
            </RegistrationWrapper>
        </LoginLayout>
    );
};

export default Registration;