import BigNumber from "bignumber.js";
import {decimals} from "./decimals";
import {Coins} from "../constants/crypto";

export function toCoin(coins: string, coin: Coins): BigNumber {
    const decimal = decimals[coin]
    return new BigNumber(coins).div(new BigNumber(decimal))
}

export function toCoins(coinAmount: string, coin: Coins): bigint {
    const decimal = decimals[coin]
    return BigInt(new BigNumber(coinAmount).times(new BigNumber(decimal)).toFixed())
}

export function calculateCoinPriceInCoins(coins: string, pricePerCoin: number, coin: Coins): bigint {
    const decimal = decimals[coin]
    BigNumber.config({ROUNDING_MODE: BigNumber.ROUND_FLOOR})
    const priceInCoins = new BigNumber(pricePerCoin * 100)
    return BigInt(new BigNumber(coins).times(priceInCoins).div(new BigNumber(decimal)).dp(0).toFixed())
}

export function calculateCoinsByFiatCoins(fiatCoins: bigint, pricePerCoin: number, coin: Coins): bigint {
    const decimal = decimals[coin]
    BigNumber.config({ROUNDING_MODE: BigNumber.ROUND_FLOOR})
    const priceInCoins = new BigNumber(pricePerCoin * 100)
    return BigInt(new BigNumber(fiatCoins.toString()).times(new BigNumber(decimal)).div(priceInCoins).dp(0).toFixed())
}

export function bigIntMultiplyNumber(firstCoinAmount: string, secondCoinAmount: string): bigint {
    return BigInt(new BigNumber(firstCoinAmount).times(new BigNumber(secondCoinAmount)).toFixed())
}

