import React, {FC, useEffect, useState} from 'react';
import BalanceCryptoItem from "./balance-crypto-item/balance-crypto-item";
import {BalanceCryptoWrapper, BalanceItems, Button, ButtonBlock} from "./balance-crypto.styled";
import {observer} from "mobx-react-lite";
import {parseCoins, ParsedCrypto} from "../../../services/app/coins-parser/coins-parser";
import Balances from "../../../store/balances/balances";
import {useHistory} from 'react-router-dom'

const BalanceCrypto :FC = observer( () => {

    const history = useHistory()

    const [cryptoArray, setCryptoArray] = useState<ParsedCrypto[]>([])

    useEffect(() => {

        (async function (){
            if(Balances.balances.length === 0) await Balances.getBalances()
            const res: ParsedCrypto[] = await parseCoins()
            setCryptoArray(res)
            console.log('aois')
            console.log(res)

        })()
    },[])

    return (
        <BalanceCryptoWrapper>
            <BalanceItems>
                {
                    cryptoArray.map(item => <BalanceCryptoItem item={item} key={item.symbol}/>)
                }
            </BalanceItems>
            <ButtonBlock>
                <Button color={'#1DAA70'} style={{marginRight: '8px'}} onClick={() => history.push('/exchange')}>Deposit</Button>
                <Button color={'#EB5746'}  onClick={() => history.push('/exchange')}>Withdraw</Button>
            </ButtonBlock>
        </BalanceCryptoWrapper>
    );
});

export default BalanceCrypto;