import {getToken} from "../../app/cookie/cookie";
import {ILastOrder, IOrderBook} from "../../../types/orders/order-book.types";

export async function getOrderBook(id: number):Promise<IOrderBook> {
    const headers = new Headers();
    headers.append("Authorization", getToken());

    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/order-book/${id}`, requestOptions)
    return await res.json()
}

export async function getLastOrder(id: number):Promise<ILastOrder>{
    const headers = new Headers();
    headers.append("Authorization", getToken());

    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/last-trade?tradingPairId=${id}`, requestOptions)
    return await res.json()
}