import styled from "styled-components";

export const BalanceCryptoItemWrapper = styled.div`
  display: block;
  padding: 16px;
  background: #1B2228;
`

export const ItemImage = styled.div`
  flex: 1 1 15%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemBody = styled.div`
  flex: 1 1 85%;
`

export const CryptoRow = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
`

export const DescriptionRow = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  display: flex;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #151A1E;
  margin-top: 14px;
  margin-bottom: 14px;
`