import React, {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import {
    ActionButton,
    Fee,
    FeeAmount,
    FeeTitle,
    FormRow,
    FormWrapper,
    InputGroup,
    InputLabel,
    Rate,
    TotalBalance
} from "../form-sell/styled-form-sell";
import CustomSelect from "../custom-select/custom-select";
import Wallet from "../../../../public/icons/wallet";
import Input from "../form-input/input";
import Spacer from "../../../ui/spacer/spacer";
import {ICoin, IPair} from "../../../../types/exchange/pair.types";
import Balances from "../../../../store/balances/balances";
import {toJS} from "mobx";
import Pairs from "../../../../store/pairs/pairs";
import {toCoin, toCoins} from "../../../../utils/tools/calcs";
import {toFixed} from "../../../../utils/numbers/toFixed";
import {IExchange} from "../../../../types/exchange/exchange.types";
import {exchangeCrypto} from "../../../../services/api/exchange/request";
import {Coins as COINS} from "../../../../utils/constants/crypto";
import {observer} from "mobx-react-lite";
import {symbolsAfterComma} from "../../../../utils/math/math";
import ModalWrapper from "../../../ui/modal/modal-wrapper/modal-wrapper";
import SuccessExchange from "../../../ui/modal/success-exchange/success-exchange";
import DangerModal from "../../../ui/modal/danger-modal/danger-modal";

interface ICheck{
    purchased: number,
    purchasedSymbol: string,
    spent: number,
    spentSymbol: string,
    fee: number,
    feeType: "PERCENTS" | "FIXED",
}

interface ICalculateReceive{
    crypto: number,
    rate: number
}
const calculateReceiveCoins = ( {crypto, rate} : ICalculateReceive  ) : number => {
    return crypto * rate;
}

interface ICalculateSpend{
    usd: number,
    rate: number
}

const calculateSpendCoins = ( {usd, rate} : ICalculateSpend  ) :number => {
    return usd/ rate;
}

const getCryptoPrice = (field: string, coin: ICoin) => {
    const searchString = `${field.toLowerCase()}Price`
    // @ts-ignore
    return coin[searchString]
}

const data  = Object.values(COINS);
const COINS_ARRAY  = data.slice(0, data.length - 1);


//TODO remove all ts-ignore

const FormBuy :FC = observer( () => {

    const [cryptoSelect, setCryptoSelect] = useState<string>('BTC')

    const [currentCrypto, setCurrentCrypto] = useState<any>()

    const [currentPair, setCurrentPair] = useState<IPair>()

    const handleItemClick = async (e: any) => {
        const id = e.target.id
        // @ts-ignore
        await setCryptoSelect( COINS_ARRAY.find(item => item === id) );
        await setSpend('0')
        await setReceive('0')
    }

    const getBalances = async () => {
        if(Balances.balances.length === 0) await Balances.getBalances()
        const res = toJS(Balances.balances).find(el => el.name === 'USD')
        console.log(toJS(Balances.balances))
        await setCurrentCrypto(res)
    }

    useEffect(() => {
        (async function (){
            try{
                await getBalances()
            }catch (e){
                console.log(e)
            }
        })()

    },[cryptoSelect])


    useEffect(() => {
        try{
            (async function (){
                if(Pairs.pairs.length === 0) await Pairs.getPairs()
                const element = toJS(Pairs.pairs).find(el => el.fromCoin.symbol === 'USD' && el.toCoin.symbol === cryptoSelect)
                await setCurrentPair(element)
            })()
        }catch(e){
            console.log(e)
        }

    },[ cryptoSelect])


    const currentFee = useMemo(() => {
        // @ts-ignore
        const min = Number( toCoin(currentPair?.min, currentPair?.fromCoin.symbol) )
        // @ts-ignore
        const max = Number( toCoin(currentPair?.max, currentPair?.fromCoin.symbol) )

        if(currentPair?.feeType === 'PERCENTS'){
            const parsedFee = Number(currentPair.fee) * 100
            const parsedFeeTitle = `${parsedFee}%`

            return {
                feeType: currentPair.feeType,
                fee: Number( currentPair.fee),
                title: parsedFeeTitle,
                min,
                max
            }
        }
        // @ts-ignore
        const parsedFee = toCoin(currentPair?.fee,currentPair?.fromCoin.symbol)
        const parsedFeeTitle = `${parsedFee}${currentPair?.fromCoin.symbol}`
        return {
            feeType: currentPair?.feeType,
            fee: Number(currentPair?.fee),
            title: parsedFeeTitle,
            min,
            max
        }
    },[currentPair])

    const [spend, setSpend] = useState<string>('0')
    const handleSpend = async (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const numericValue = Number(value)
        console.log(value)

        if(!currentPair?.fee) return console.log(currentPair)
        try{
            const rate =  Number (currentPair.toCoin.usdPrice)
            // @ts-ignore
            const receive = calculateReceiveCoins({crypto: Number( value), rate })
            console.log(receive)
            setSpend(String(value))
            if(symbolsAfterComma(receive) > 2) return setReceive(receive.toFixed(2))
            setReceive(String(receive))

        }catch (e){
            console.log(e)
            setSpend(String(numericValue.toFixed(8)))
            // @ts-ignore
            setReceive(calculateReceiveCoins({crypto: Number( value), rate: Number (currentCrypto.usdPrice) }).toFixed(2))

        }
    }

    const [receive, setReceive] = useState<string>('0')
    const handleReceive = async (e: ChangeEvent<HTMLInputElement>) => {
        let value = Number(e.target.value)
        if(symbolsAfterComma(value) > 2) value = Number( value.toFixed(2) )
        try{
            if(!currentPair) return console.log('no pair')
            const rate =  Number (currentPair.toCoin.usdPrice)
            // @ts-ignore
            const spendValue =  calculateSpendCoins({usd: value, rate, fee: Number(currentPair.fee), feeType:  currentPair.feeType })
            console.log(spendValue)
            if(!spendValue) return setReceive('')
            setSpend(toFixed( String( spendValue)))
            setReceive(String(value))
        }catch (e){
            console.log(e)
        }
    }

    const receiveBlock  = useMemo(() => {
        return{
            value: spend,
            onChange: handleSpend
        }
    },[spend, receive, currentCrypto, currentPair, currentFee])

    const spendBlock  = useMemo(() => {
        return{
            value: receive,
            onChange: handleReceive
        }
    },[receive, spend, currentCrypto, currentPair, currentFee])

    const [errors, setErrors] = useState<string[]>([])
    const isDisabled = useMemo<boolean>(() => {
        const errors:string[] = []
        if(Number(receive) <= 0) errors.push('Spend cannot be 0')
        if(Number(receive) > currentFee?.max) errors.push(`Receive amount is higher than pair max ${String(currentFee.max)} ${currentPair?.fromCoin.symbol}`)
        if(Number(receive) < currentFee.min) errors.push(`Receive amount is lower than pair min ${String(currentFee.min)}` )
        if(Number(receive) > Number( currentCrypto?.amount)) errors.push('Receive amount + fee is higher than your balance');

        console.log('errors')
        console.log(errors)
        setErrors(errors)
        return errors.length > 0
    },[receive])

    useEffect(()=> {
        console.log('disabled')
        console.log(String(isDisabled))
    } ,[isDisabled])

    const buyCrypto = async () => {
        if(Number( spend) === 0|| Number(receive) === 0) return console.log('values should not equal 0')
        // @ts-ignore
        const amount = toCoins( receive, currentPair?.fromCoin.symbol)
        // @ts-ignore
        const rate = Number(  getCryptoPrice(currentPair?.toCoin.symbol, currentPair?.fromCoin ) )

        const raw :IExchange = {
            "amount": String( amount),
            "tradingPairId": currentPair?.id,
            "feeType": currentPair?.feeType,
            "fee": Number( currentPair?.fee),
            "rate": rate
        };
        try{
            if(!currentPair) return
            const purchased = calculateReceiveCoins({crypto: Number( spend), rate: Number (currentPair.fromCoin.usdPrice) })
            const fixedPurchased = symbolsAfterComma(purchased) > 12? Number(purchased.toFixed(12)) : purchased
            await setCheck({
                purchasedSymbol: currentPair.toCoin.symbol,
                purchased:  fixedPurchased,
                spent: Number(receive),
                spentSymbol: currentPair.fromCoin.symbol,
                fee: currentFee.fee,
                // @ts-ignore
                feeType: currentFee.feeType,
            })
            const {message} = await exchangeCrypto(raw)
            await Balances.getBalances()
            await getBalances()
            if(message !== ''){
                await setError(message)
                return setFailModal(true)
            }
            await setSuccessModalOpened(true)
        }catch (e){
            console.log(e)
        }
    }

    const [successModalOpened, setSuccessModalOpened] = useState<boolean>(false)
    const closeSuccessModal = async () => {
        await setSuccessModalOpened(false)
    }

    const [error, setError] = useState<string>('')

    const [failModal, setFailModal] = useState<boolean>(false)
    const closeFailModal = async () => {
        await setFailModal(false)
    }

    const [check, setCheck] = useState<ICheck>()

    return (
        <FormWrapper>
            <div>
                <FormRow>
                    <InputGroup>
                        <InputLabel> Coin </InputLabel>
                        <CustomSelect handleItemClick={handleItemClick} items={COINS_ARRAY} selectedItem={cryptoSelect}/>
                    </InputGroup>
                    <InputGroup style={{marginLeft: '16px'}}>
                        <InputLabel> Deposit into </InputLabel>
                        <CustomSelect handleItemClick={handleItemClick} items={COINS_ARRAY} disabled={true} selectedItem={cryptoSelect}/>
                    </InputGroup>
                </FormRow>
            </div>
            <div>
                <FormRow>
                    <TotalBalance> <Wallet/> <span style={{marginLeft: '4px'}}> Balance: </span> <span style={{marginLeft: '4px'}}> {currentCrypto?.amount} {currentCrypto?.name} </span>  </TotalBalance>
                </FormRow>
            </div>
            <div>
                <Rate> 1 {currentPair?.toCoin.symbol} ≈ {currentPair?.toCoin &&  parseFloat(currentPair?.toCoin?.usdPrice)} USD </Rate>
                <FormRow>
                    <InputGroup>
                        <Input type={'number'} label={'Spend'} formikProps={{...spendBlock}}  endPlaceholder={'USD'}/>
                    </InputGroup>
                    <InputGroup style={{marginLeft: '16px'}}>
                    <Input
                        type={'number'} label={'Receive'}  formikProps={{...receiveBlock}} endPlaceholder={currentPair?.toCoin.symbol} />
                </InputGroup>
            </FormRow>
            </div>
            {errors && <div style={{marginTop: '12px', color: "rgba(0, 0, 0, 0.4)", textAlign: 'left'}}>
                {errors.map(error => <div key={error} style={{color: "#D32F2F"}}>{error}</div>)}
            </div>
            }
            <div style={{marginTop: '48px', display:'flex'}}>
                <Fee>
                    <FeeTitle>Transaction Fee:</FeeTitle>
                    <FeeAmount>  ({currentFee.title}) </FeeAmount>
                </Fee>
                <Spacer/>
                <ActionButton onClick={buyCrypto} style={{background: '#1DAA70'}} disabled={isDisabled}> Buy {currentPair?.toCoin.symbol} </ActionButton>
            </div>

            {
                successModalOpened && check && <ModalWrapper isOpened={successModalOpened} callback={() => console.log(2)} >
                    <SuccessExchange purchased={check.purchased} purchasedSymbol={check.purchasedSymbol} spent={check.spent} spentSymbol={check.spentSymbol} fee={check.fee} feeType={check.feeType} closeModal={closeSuccessModal} />
                </ModalWrapper>
            }
            {
                failModal && check && <ModalWrapper isOpened={failModal} callback={() => console.log('2')}>
                    <DangerModal purchased={check.purchased} purchasedSymbol={check.purchasedSymbol} spent={check.spent} spentSymbol={check.spentSymbol} fee={check.fee} feeType={check.feeType} closeModal={closeFailModal} error={error}/>
                </ModalWrapper>
            }
        </FormWrapper>
    );
});

export default FormBuy;