import React, {FC, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    BigButton,
    Coins,
    Funds,
    FundsTitle,
    LevarageSelect,
    LevarageTitle,
    Leverage, ResetBtn,
    ResetRow, ReviewRow
} from "../market-type/styled-market-type";
import CoefficientSelector from "../../../../../ui/coefficient-selector/сoefficient-selector";
import Spacer from "../../../../../ui/spacer/spacer";
import Wallet from "../../../../../../public/icons/wallet";
import PercentSelector from "../../../../../ui/percent-selector/percent-selector";
import Input from "../../../../../global/input/input";
import Checkbox from "../../../../../ui/checkbox/checkbox";

interface ITakeProfitType{
    type: 'buy' | 'sell'
}

const TakeProfitType :FC<ITakeProfitType> = ({type}) => {

    const [range, setRange] = useState(0)

    const [sndRange, setSndRange] = useState(1)

    const defaultValues = {
        amount: 0,
        profitPrice: 0,
        total: 0,
        checked: false
    }

    const {register, reset, handleSubmit} = useForm({
        defaultValues
    })

    const resetForm = () => {
        reset(JSON.parse( JSON.stringify(defaultValues)))
        setRange(0)
        setSndRange(1)
    }

    return (
        <div>
            <Leverage>
                <LevarageTitle> Leverage </LevarageTitle>
                <LevarageSelect>
                    <CoefficientSelector range={sndRange} setRange={setSndRange}/>
                </LevarageSelect>
            </Leverage>

            <Funds>
                <FundsTitle>
                    <span style={{marginTop:'2px'}}> Funds </span> <Spacer/> <Coins> 1.00 BTC <span style={{marginTop:'4px', marginLeft:'4px'}}> <Wallet/> </span> </Coins>
                </FundsTitle>
            </Funds>
            <PercentSelector range={range} setRange={setRange}/>

            <div>
                <Input placeholder={'Amount'} formikProps={{...register('amount')}} endPlaceholder={'BTC'} type={'number'}/>
            </div>

            <div style={{marginTop:'24px'}}>
                <Input placeholder={'Profit price'} formikProps={{...register('profitPrice')}} endPlaceholder={'USDT'} type={'number'} />
            </div>

            <div style={{marginTop:'24px'}}>
                <Input placeholder={'Total'} formikProps={{...register('total')}} endPlaceholder={'USDT'} type={'number'}/>
            </div>

            <ResetRow>
                <ResetBtn onClick={() => resetForm()}>Reset</ResetBtn>
            </ResetRow>

            <ReviewRow>
                <Checkbox label={'exchange without review'} form={{...register('checked')}}/>
            </ReviewRow>

            <div style={{marginLeft:'16px', marginRight:'16px'}}>
                <BigButton pageType={type}> Review and Buy BTC </BigButton>
            </div>

            <div style={{width:'100%', height:'16px', background:'#1B2228'}}/>

        </div>
    );
};

export default TakeProfitType;