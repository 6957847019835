import styled from "styled-components";

interface ICol{
    width: number
}

export const LeftCol = styled.div<ICol>`
  flex: 0 1 ${props => 100/props.width}%;
  display: block;
  height: 100vh;
  flex-direction: row;
  padding-left: 90px;
  padding-right: 90px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; //Chrome, Safari and Opera
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export const RowWrapper = styled.div`
  display: flex;
  overflow: hidden;
`

export const RightCol = styled.div<ICol>`
  flex: 0 1 ${props => 100/props.width}%;
  display: block;
  height: 100vh;
  flex-direction: row;
  padding-left: 127px;
  background: linear-gradient(176.16deg, #1B2228 -3.19%, #101010 113.01%);
  color: white;
`

export const RightColContentWrapper = styled.div`
  position: relative;
  display: block;
  justify-content: start;
  padding-top: 160px;
  padding-bottom: 160px;
  height: 100vh;
`

export const RightColContentTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: #ffffff;
  margin-right: 127px;
  text-align: left;
  display: flex;
`

export const TopElipce = styled.img`
  position: fixed;
  width: 542px;
  height: 250px;
  left: 40%;
  top: 0;
  z-index: 5;
`

export const RightElipce = styled.img`
  position: fixed;
  width: 250px;
  height: 286px;
  right: 0;
  bottom: 0;
  z-index: 5;
`

interface IWhiteDot {
    top :number
}

export const WhiteDots = styled.img<IWhiteDot>`
  height: 6px;
  width: 200px;
  position: absolute;
  top: ${props => String( props.top + 1)}px;
  left: 300px;
`

export default {}