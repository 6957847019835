import React, {FC} from 'react';
import styled from "styled-components";
import {PeriodDate} from "../../../../../../types/orders/period-tabs.types";
import TradeHistory from '../../../../../../store/trade-history/trade-history'
interface IPeriodItemWrapper{
    active: boolean
}

const PeriodItemWrapper = styled.div<IPeriodItemWrapper>`
  color: ${props => props.active? '#ffffff': 'rgba(255, 255, 255, 0.4)'} ;
  background: ${props => props.active? "#1b2228" : "#151A1E"} ;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
`

interface IPeriodItem{
    active: boolean;
    activeValue: string;
    title: string;
    value: PeriodDate;
    onSelect: (value: string) => void;
    onAction: (action:'tab'|'select') => void;
    name: 'tab'|'select';
    dates: Record<PeriodDate, {dateFrom: Date, dateTo:Date}>
}

const PeriodItem :FC<IPeriodItem> = ({activeValue, title, value, onSelect, onAction,  name, dates}) => {

    const selectValue = async () => {
        onAction(name)
        onSelect(value)
        const dateParams = dates[value]
        await TradeHistory.getTradeHistory(dateParams.dateFrom, dateParams.dateTo)
    }

    return (
        <PeriodItemWrapper  active={value === activeValue} onClick={() => selectValue()}>{title}</PeriodItemWrapper>
    );
};

export default PeriodItem;