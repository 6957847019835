import {getToken} from "../../app/cookie/cookie";

interface IBalance{
    balance: string
}

type Balances = {
    btc: string,
    eth: string,
    xrp: string,
    usdc: string,
    usdt: string,
    bnb: string,
    ada: string,
    doge: string,
    dot: string,
    uni: string,
    cex: string,
    usd: string
}

export async function getTotalBalance(){
    const headers = new Headers();
    headers.append("Authorization", getToken());

    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/total-balance`, requestOptions)

    const data:IBalance = await res.json()

    return data.balance
}

export async function getBalances(){
    const headers = new Headers();
    headers.append("Authorization", getToken());

    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/balances`, requestOptions)

    const data: Balances = await res.json()

    return data
}