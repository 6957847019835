import React, {FC, useEffect, useState} from 'react'
import Spacer from "../../ui/spacer/spacer"
import Pair from "./pair/pair"
import {
  FavoritePairsContent,
  FavoritePairsDescription,
  FavoritePairsDescriptionTitle,
  FavoritePairsWrapper,
  FavoritePairsDescriptionLink
} from "./styled-favorite-pairs"
import PairSelect from "./pair-select/pairs-select";
import FavoritePairsStore from '../../../store/favorite-pairs/favorite-pairs';
import {observer} from "mobx-react-lite";

const FavoritePairs :FC = observer( () => {

  const getPairs = async():Promise<void> => {
    await FavoritePairsStore.getFavoritePairs()
  }

  useEffect(() => {
    (async function (){
      await getPairs()
    })()
  },[])

  const [openPairs, setOpenPairs] = useState<boolean>(false)

  return (
    <FavoritePairsWrapper>
      <FavoritePairsContent>
        {openPairs && <PairSelect/>}
        <FavoritePairsDescription>
          <FavoritePairsDescriptionTitle>
            Favorite pairs
          </FavoritePairsDescriptionTitle>
          <Spacer/>
          <FavoritePairsDescriptionLink onClick={() => setOpenPairs(prev => !prev)}>
            Edit
          </FavoritePairsDescriptionLink>
        </FavoritePairsDescription>
        {
          FavoritePairsStore.favoritePairs.map(pair => <Pair key={pair.id} pair={pair}/>)
        }
      </FavoritePairsContent>
    </FavoritePairsWrapper>
  )
})

export default FavoritePairs;