import React from 'react'

const Add = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="#D9AF20" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 4V12"  stroke="#D9AF20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.5 8L4.5 8" stroke="#D9AF20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default Add