import styled from "styled-components";

export const TotalChartWrapper = styled.div`
  display:flex;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 100%;
`

interface IFilledCircle{
  color: string
}

export const FilledCircle = styled.div<IFilledCircle>`
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 12px;
    background: ${props => props.color};
`

export const Coins = styled.div`
  margin-top: 15px;
  margin-left: 15px;
  font-size: 13px;
  font-weight: 600;
`

export const CoinRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`

export const CoinRowCrypto = styled.div`
  margin-right: 20px;
  width: 40px;
  text-align: left;
`