import React, {FC} from 'react';
import MarketBottomBar from "../../../../../store/market-orders/market-orders";
import {observer} from "mobx-react-lite";
import {ActiveTab, TabItem, TabItemTitle} from "./styled-order-tab-item";

type tabItem = {
    title: string,
    value: string
}

interface IOrderTabItem{
    item: tabItem
}

const OrderTabItem: FC<IOrderTabItem> = observer( ({item}) => {

    const isActive = MarketBottomBar.currentTab.value === item.value

    const handleSelect = () => {
        MarketBottomBar.setCurrentTab(item)
    }

    return (
        <TabItem isActive={isActive} onClick={handleSelect}>
            <TabItemTitle isActive={isActive}> {item.title} {item.value === 'active' ? `(${MarketBottomBar.activeOrders})`:''} </TabItemTitle>
            <ActiveTab isActive={isActive}/>
        </TabItem>
    );
});

export default OrderTabItem;