import styled from "styled-components";

export const FavoritePairsWrapper = styled.div`
  background: #1B2228;
  margin-bottom: 4px;
  height: 100%;
  padding: 16px;
  
`

export const FavoritePairsDescription = styled.div`
  display: flex;
  flex: 0 1 100%;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 22px;
  align-items: center;
`

export const FavoritePairsDescriptionTitle = styled.span`
  color: #ffffff;
`

export const FavoritePairsDescriptionLink = styled.span`
  color: #D9AF20;
  position: relative;
  cursor: pointer;
`

export const FavoritePairsContent = styled.div`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; //Chrome, Safari and Opera
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`


export const AddFavoriteBtn = styled.button`
    outline: none;
    border: none;
    flex-grow: 1;
    padding-top: 7px;
    padding-bottom: 7px;
    background: #26323C;
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 4px ;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const PairTabItemWrapper = styled.div`
  margin-right: 16px;
  cursor: pointer;
`

interface ITabItem {
  isActive: boolean
}

export const TabItemTitle = styled.div<ITabItem>`
  margin-bottom: ${props => props.isActive? '12px': '13px'};
  color: ${props => props.isActive? '#F9C510': '#ffffff'};
  padding-left: 7px;
  padding-right: 7px;
`

export const ActiveTab = styled.div<ITabItem>`
  width: 100%;
  height: 2px;
  background: #F9C510;
  display: ${props => props.isActive? 'block': 'none'};
`