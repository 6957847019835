import styled from "styled-components/macro";

export const PairSelectWrapper = styled.div`
  width: 423px;
  height: 350px;
  border-radius: 4px;
  background: #26323C;
  position: absolute;
  right: 10px;
  top: 140px;
  overflow-y: scroll;
  padding: 16px;
  z-index: 123;
`

export const PairRow = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  color: #ffffff;
  cursor: pointer;
  &:hover{
    font-weight: 600;
    color: #F5C210;
  }
`

export const PairTitle = styled.div`
  flex: 0 1 33%;
  text-align: left;
`

export const PairLastPrice = styled.div`
  flex: 0 1 33%;
  text-align: left;
`

export const PairChange = styled.div`
  flex: 0 1 33%;
  text-align: right;
`

export const HighlightedText = styled.span`
  font-weight: 700;
  font-size: 13px;
  color: #F9C510;
`

export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid rgba(21, 26, 30, 0.3);
  background: rgba(21, 26, 30, 0.3);
  padding: 8px 12px;
  font-size: 12px;
  outline: none;
  color: #ffffff;
  border-radius: 4px;
  
  &:focus{
    border: 1px solid #F9C510;
  }
`

export const TableHeader = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  font-size: 13px;
`

export const Tabs = styled.div`
  display: flex;
  justify-content: end;
`