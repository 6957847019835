import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {
    Field,
    FieldTitle,
    InputGroup, SignInButton, SignUpRow,
} from "./reset-form.styled";
import {useHistory, useLocation} from 'react-router-dom'
import * as Joi from "joi";
import {joiResolver} from "@hookform/resolvers/joi";
import Password from "../../../../public/icons/password";
import {setNewPassword} from "../../../../services/api/reset-password/requests";

const ResetFormSchema = Joi.object({
    password: Joi.string().required().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
    confirmPassword: Joi.string().required().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/).valid(Joi.ref('password'))
})

interface IResetPassword{
    password: string,
    confirmPassword: string
}

type PasswordType = 'text' | 'password'

const ResetForm = () => {
    const history = useHistory()
    const location = useLocation()

    const [code, setCode] = useState<string>('')

    const {register, handleSubmit, formState} = useForm({
        resolver: joiResolver(ResetFormSchema ),
        mode: "onChange"
    })

    const {isValid, errors, isDirty} = formState

    useEffect(() => {
        console.log(errors, isValid)
        // console.log(watchAllFields)
    }, [errors, isValid, isDirty])


    useEffect(()=> {
        (async function() {
            const searchParams = new URLSearchParams( location.search)
            const code =  searchParams.get('code')
            if(!code){
                return history.push('/')
            }
            setCode(code)
        })()
    } ,[])

    const [isBlocked, setBlocked] = useState<boolean>(false)

    const onSubmit : SubmitHandler<IResetPassword> = async data => {
        setBlocked(true)
        try {
            const res = await setNewPassword(code, data.password)
            if(res < 300) history.push('/login')
            setBlocked(false)
        }catch (e){
            setTimeout(() => {
                setBlocked(false)
            },1000)
        }
    };

    const [passwordType, setPasswordType] = useState<PasswordType>('password')

    const togglePasswordType = () =>{
        passwordType === 'text' ? setPasswordType('password') : setPasswordType('text')
    }

    const [confirmPasswordType, setConfirmPasswordType] = useState<PasswordType>('password')

    const toggleConfirmPasswordType = () =>{
        confirmPasswordType === 'text' ? setConfirmPasswordType('password') : setConfirmPasswordType('text')
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
                <FieldTitle>
                    Password
                </FieldTitle>
                <div style={{position:'relative'}}>
                    <Field {...register('password')} type={passwordType} placeholder='Password' />
                    <div className="icon" style={{position:"absolute", right:'12px', top: '12px'}} onClick={() => togglePasswordType()}>
                        <Password/>
                    </div>
                </div>
            </InputGroup>

            <InputGroup>
                <div style={{position:'relative'}}>
                    <Field {...register('confirmPassword')} type={confirmPasswordType} placeholder='Confirm Password' />
                    <div className="icon" style={{position:"absolute", right:'12px', top: '12px'}} onClick={() => toggleConfirmPasswordType()}>
                        <Password/>
                    </div>
                </div>
            </InputGroup>

            <SignInButton type='submit' disabled={!isValid && !isBlocked}>
                Sign up
            </SignInButton>

            <SignUpRow>
                <span onClick={() => history.push('/login')} style={{fontWeight: 600}}>  Return to Sign in   </span>
            </SignUpRow>
        </form>
    );
};

export default ResetForm;