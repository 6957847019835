import BTC_IMG from '../../public/crypto/bitcoin.png'
import ETH_IMG from '../../public/crypto/eth.png'
import USDT_IMG from '../../public/crypto/usdt.png'
import BNB_IMG from '../../public/crypto/bnb.png'
import ADA_IMG from '../../public/crypto/ada.png'
import DOGE_IMG from '../../public/crypto/dogee.png'
import XRP_IMG from '../../public/crypto/xrp.png'
import USDC_IMG from '../../public/crypto/usdc.png'
import DOT_IMG from '../../public/crypto/dot.png'
import UNI_IMG from '../../public/crypto/uni.png'
import CEX_IMG from '../../public/crypto/cex.png'

export const BTC = 'BTC';
export const ETH = 'ETH';
export const USDT = 'USDT';
export const BNB = 'BNB';
export const ADA = 'ADA';
export const DOGE = 'DOGE';
export const XRP = 'XRP';
export const USDC = 'USDC';
export const DOT = 'DOT';
export const UNI = 'UNI';
export const CEX = 'CEX';
export const USD = 'USD';

export enum Coins {
  BTC = 'BTC',
  ETH = 'ETH',
  USDT = 'USDT',
  BNB = 'BNB',
  ADA = 'ADA',
  DOGE = 'DOGE',
  XRP = 'XRP',
  USDC = 'USDC',
  DOT = 'DOT',
  UNI = 'UNI',
  CEX = 'CEX',
  USD = 'USD',
}

export type LowerCaseCoins = Lowercase< typeof Coins[keyof typeof Coins] >

export type ILowerCaseCoins = Record<keyof typeof Coins, LowerCaseCoins>

export const LowercaseCrypto: ILowerCaseCoins = {
  BTC: 'btc',
  ETH: 'eth',
  BNB: 'bnb',
  ADA: 'ada',
  DOGE: 'doge',
  DOT: 'dot',
  UNI: 'uni',
  CEX: 'cex',
  USD: 'usd',
  XRP : 'xrp',
  USDC: 'usdc',
  USDT: 'usdt'
}

export const CryptoCoins: Record<LowerCaseCoins,  Coins> = {
  btc: Coins.BTC,
  eth: Coins.ETH,
  usdt: Coins.USDT,
  usdc: Coins.USDC,
  bnb: Coins.BNB,
  ada: Coins.ADA,
  doge: Coins.DOGE,
  xrp: Coins.XRP,
  dot: Coins.DOT,
  uni: Coins.UNI,
  cex: Coins.CEX,
  usd: Coins.USD
}



type CoinsNames = typeof Coins[keyof typeof Coins]
type CoinsName = CoinsNames
// type CoinsName = Exclude<CoinsNames, Coins.USD>
type CryptoInfo = {
  fullname: string,
  img: string
}

export const CryptoNames:Record<CoinsName, CryptoInfo> ={
  BTC: {
    fullname:  'Bitcoin',
    img: BTC_IMG
  },
  ETH : {
    fullname: 'Etherium',
    img: ETH_IMG
  },
  USDT : {
    fullname: 'Tether',
    img: USDT_IMG
  },
  BNB : {
    fullname: 'Binance Coin',
    img: BNB_IMG
  },
  ADA : {
    fullname: 'Cardano',
    img: ADA_IMG
  } ,
  DOGE: {
    fullname: 'Dogecoin',
    img: DOGE_IMG
  },

  XRP : {
    fullname: 'Ripple',
    img: XRP_IMG
  } ,
  USDC : {
    fullname: 'USD Coin',
    img: USDC_IMG
  } ,
  DOT : {
    fullname: 'Polkadot',
    img: DOT_IMG
  } ,
  UNI : {
    fullname: 'Uniswap',
    img: UNI_IMG
  } ,
  CEX : {
    fullname: 'CentExge',
    img: CEX_IMG
  },
  USD: {
    fullname: 'USD',
    img: BNB_IMG
  }

}