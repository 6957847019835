const storesToClear = [
  require('./balances/balances'),
  require('./pairs/pairs'),
  require('./coins/coins'),
  require('./favorite-pairs/favorite-pairs'),
  require('./app-session/app-session'),
  require('./email-verification/email-verification'),
  require('./market-orders/market-orders'),
  require('./order-history/order-history'),
  require('./trade-history/trade-history'),
  require('./market-sidebar/market-sidebar'),
  require('./market-orders/market-orders'),
  require('./wallet-total/wallet-total')
]

export function clearAllStores() {
  try{
    storesToClear.forEach(store => store.default.clear())
  }catch (e){
    console.log(e)
  }
}