import React, {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from "react-hook-form";
import {IResetPassword} from "../../../../types/reset-password/reset-password.types";
import {resetPassword} from "../../../../services/api/forgot-password/requests";
import {useHistory} from 'react-router-dom'
import * as Joi from "joi";
import {joiResolver} from "@hookform/resolvers/joi";
import {Field, FieldTitle, InputGroup, SignInButton, SignUpRow} from "./styled-forgot-form";

const ResetFormSchema = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } })
})

const ForgotForm = () => {
    const history = useHistory()

    const {register, handleSubmit, formState} = useForm({
        resolver: joiResolver(ResetFormSchema ),
        mode: "onChange"
    })

    const {isValid, errors, isDirty} = formState

    useEffect(() => {
        console.log(errors, isValid)
        // console.log(watchAllFields)
    }, [errors, isValid, isDirty])

    const [isBlocked, setBlocked] = useState<boolean>(false)


    const onSubmit : SubmitHandler<IResetPassword> = async data => {
        setBlocked(true)
        try {
            await resetPassword(data)
            setBlocked(false)
        }catch (e){
            setTimeout(() => {
                setBlocked(false)
            },1000)
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
                <FieldTitle>
                    Email
                </FieldTitle>
                <Field {...register('email')} type='email' placeholder='Enter Email' />
            </InputGroup>

            <SignInButton type='submit' disabled={(!isDirty && !isValid) && !isBlocked}>
                Continue
            </SignInButton>

            <SignUpRow>
                <span onClick={() => history.push('/login')} style={{fontWeight: 600}}>  Return to Sign in   </span>
            </SignUpRow>
        </form>
    );
};

export default ForgotForm;