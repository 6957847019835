import {getToken} from "../../app/cookie/cookie";
import {
  IFavoritePairs,
  IFavoritePairsOrder
} from "../../../types/favorite-pairs/favorite-pairs.types";
import {STATUS_CODES} from "../../../utils/constants/time";

export async function getFavoritePairs():Promise<IFavoritePairs>{
  const headers = new Headers()
  headers.append("Authorization", getToken())

  const requestOptions = {
    method: 'GET',
    headers: headers
  }

  const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/favourite`, requestOptions)

  return await res.json()
}

export async function addFavoritePair(id:number):Promise<number>{
  const headers = new Headers()
  headers.append("Authorization", getToken())
  headers.append("Content-Type", "application/json")

  const requestData: string = JSON.stringify({
    "pairId": id
  });

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: requestData
  }

  const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/favourite`, requestOptions)

  return res.status;
}

export async function deleteFavoritePair(id:number):Promise<number>{
  const headers = new Headers()
  headers.append("Authorization", getToken())

  const requestOptions = {
    method: 'DELETE',
    headers: headers,
  }

  const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/favourite/${id}`, requestOptions)

  return res.status;
}

export async function changePairsOrder(favoritePairs: IFavoritePairsOrder){
  const headers = new Headers()
  headers.append("Authorization", getToken())
  headers.append("Content-Type", "application/json")

  const requestOptions = {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(favoritePairs)
  }
  const res = await fetch(`${process.env.REACT_APP_API_PATH}/users/favourite`, requestOptions)

  return res.status === STATUS_CODES.NoContent
}