import styled from "styled-components";

export const OrderBookWrapper = styled.div`
  padding: 16px;
`

export const OrderBookRow = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 250px;
  padding-bottom: 25px;
`

export const BuyColumn = styled.div`
  flex: 0 1 50%;
`

export const SellColumn = styled.div`
  flex: 0 1 50%;
`

export const NoOrderBook = styled.div`
  height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.2);
`

export const LastOrder = styled.div`
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 93px;
`