import React, {FC, useEffect} from 'react';
import {
  TradeHistoryTableBody, TradeHistoryTableBodyItem,
  TradeHistoryTableHead,
  TradeHistoryTableHeadItem,
  TradeHistoryTableRow,
  TradeHistoryTable as TradeHistoryTableWrapper, NoTradeHistory
} from "./styled-trade-history-table";
import {observer} from "mobx-react-lite";
import TradeHistory from '../../../../../store/trade-history/trade-history'
import {toJS} from "mobx";
import {parseDate} from "../../../../../utils/date/date";
import {ITradeHistoryItem} from "../../../../../types/orders/trade-history.types";
import {toCoin} from "../../../../../utils/tools/calcs";

interface ITradeHistoryTableItem {
    item: ITradeHistoryItem
}
const TradeHistoryTableItem :FC<ITradeHistoryTableItem> = ({item}) => {

    const parsedAmount = toCoin(item.amount, item.tradingPair.fromCoin.symbol).toString()
    const parsedPrice = toCoin(item.price, item.tradingPair.toCoin.symbol).toString()
    const parsedTotal = toCoin(toCoin(item.total, item.tradingPair.toCoin.symbol).toString(),item.tradingPair.fromCoin.symbol).toFixed()

    return(
      <TradeHistoryTableRow>
          <TradeHistoryTableBodyItem> {item.tradingPair.fromCoin.symbol}/{item.tradingPair.toCoin.symbol} </TradeHistoryTableBodyItem>
          <TradeHistoryTableBodyItem> {item.exchange} </TradeHistoryTableBodyItem>
          <TradeHistoryTableBodyItem> {parsedAmount} </TradeHistoryTableBodyItem>
          <TradeHistoryTableBodyItem> {parsedPrice} </TradeHistoryTableBodyItem>
          <TradeHistoryTableBodyItem> {parseFloat( item.fee)} {item.feeType === 'PERCENTS'? '%' : item.tradingPair.fromCoin.symbol} </TradeHistoryTableBodyItem>
          <TradeHistoryTableBodyItem> {parsedTotal} </TradeHistoryTableBodyItem>
          <TradeHistoryTableBodyItem> {parseDate(item.createdAt)} </TradeHistoryTableBodyItem>
      </TradeHistoryTableRow>
    )

}

const TradeHistoryTable = observer (() => {

    useEffect(()=> {
        (async function (){
            console.log(toJS( TradeHistory.tradeHistory))
        })()
    },[TradeHistory.tradeHistory])

    return (
        <TradeHistoryTableWrapper>
            <TradeHistoryTableHead>
                <TradeHistoryTableRow>
                    <TradeHistoryTableHeadItem>Pair</TradeHistoryTableHeadItem>
                    <TradeHistoryTableHeadItem>Exchange</TradeHistoryTableHeadItem>
                    <TradeHistoryTableHeadItem>Amount</TradeHistoryTableHeadItem>
                    <TradeHistoryTableHeadItem>Price</TradeHistoryTableHeadItem>
                    <TradeHistoryTableHeadItem>Fee</TradeHistoryTableHeadItem>
                    <TradeHistoryTableHeadItem>Total</TradeHistoryTableHeadItem>
                    <TradeHistoryTableHeadItem>Date</TradeHistoryTableHeadItem>
                </TradeHistoryTableRow>
            </TradeHistoryTableHead>
            <TradeHistoryTableBody>
              {TradeHistory.tradeHistory.length > 0?
                <>
                  {
                    TradeHistory.tradeHistory.map(item => <TradeHistoryTableItem item={item} key={item.id}/>)
                  }
                </>:
                <NoTradeHistory>
                  You have no trade history
                </NoTradeHistory>
              }

            </TradeHistoryTableBody>
        </TradeHistoryTableWrapper>
    );
});

export default TradeHistoryTable;