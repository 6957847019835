import React, {FC} from 'react';
import styled from "styled-components";
import styles from './сoefficient-selector.module.css'

interface IContainer{
    range: number
}

const Container = styled.div<IContainer>`
  position: relative;
  height: 4px;
  margin-bottom: 32px;
  background: linear-gradient(
          90deg,
          #fff ${props => props.range}%,
          #26323c ${props => props.range}%
  )
`

interface ICoefficientSelector{
    range: number,
    setRange: (range: number) => void
}

const CoefficientSelector :FC<ICoefficientSelector> = ({range, setRange}) => {

    const normalizeValue = (value: number) =>  {
        if(value === 1) return 0
        if(value === 2) return 33
        if(value === 3) return 66
        if(value === 5) return 100
        return 0
    }

    return (
        <div className="main">
            <Container className={styles.container} range={normalizeValue(range)}>
                <div className={"super__dot one active"} onClick={() => setRange(1)}>
                    <span className={styles.percent}>None</span>
                    <span className={styles.super__minidot}/>
                </div>
                <div className={`${styles.dot} ${styles.two} ${range >= 2? `${styles.active}` : ''}`} onClick={() => setRange(2)} >
                    <span className={styles.percent}>2x</span>
                </div>
                <div className={`${styles.dot} ${styles.three} ${range >= 3? `${styles.active}` : ''}`} onClick={() => setRange(3)}>
                    <span className={styles.percent}>3x</span>
                </div>
                <div className={`${styles.dot} ${styles.four} ${range >= 4? `${styles.active}` : ''}`} onClick={() => setRange(5)}>
                    <span className={styles.percent}>5x</span>
                </div>
            </Container>
        </div>
    );
};

export default CoefficientSelector;