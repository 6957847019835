import React, {FC} from 'react';
import Spacer from "../../../../ui/spacer/spacer";
import {CopyRight, ResetForm, ResetFormMainTitle, ResetFormSubTitle, ResetFormWrapper } from "./styled-forgot-password-wrapper";

const ForgotPasswordWrapper :FC = ({children}) => {
    return (
        <ResetFormWrapper>
            <ResetFormMainTitle>
                Reset your password
            </ResetFormMainTitle>

            <ResetFormSubTitle>
                Enter the email address associated with your account and we’ll send you an email with instructions on how to reset your password
            </ResetFormSubTitle>
            <ResetForm>
                {children}
            </ResetForm>

            <Spacer/>

            <CopyRight>
                © 2023 Unicsoft. All rights reserved.
            </CopyRight>
        </ResetFormWrapper>
    );
};

export default ForgotPasswordWrapper;