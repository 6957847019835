export async function resendEmail(email:string):Promise<number>{
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        email
    });

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
    };

    const res = await fetch(`${process.env.REACT_APP_API_PATH}/auth/resend-email`, requestOptions)
    return res.status
}