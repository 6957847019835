import React, {FC} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import styled from "styled-components"
import Login from "../../../pages/login/login";
import RouterLayout from "../../../router/router-layout/router-layout";
import Market from "../../../pages/market/market";
import Wallet from "../../../pages/wallet/wallet";
import ExchangeCrypto from "../../../pages/exchange-crypto/exchange-crypto";


const UserLayoutWrapper = styled.div`
  min-height: 100vh;
  min-width: 100vh;
`

const UserLayout: FC = () => {
  return (
    <RouterLayout>
      <UserLayoutWrapper>
        <Switch>
          <Route component={Market} exact path={'/market'}/>
          <Route component={Wallet} exact path={'/wallet'}/>
          <Route component={Login} exact path={'/account'}/>
          <Route component={Login} exact path={'/trade'}/>
          <Route component={ExchangeCrypto} exact path={'/exchange'}/>
          <Redirect to={'/market'}/>
        </Switch>
      </UserLayoutWrapper>
    </RouterLayout>
  );
};

export default UserLayout;