import React from 'react';

const Movable = ({color = 'white'}) => {
  return (
    <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2 6C2 5.44772 2.44772 5 3 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H3C2.44772 7 2 6.55228 2 6ZM2 10C2 9.44772 2.44772 9 3 9H13C13.5523 9 14 9.44772 14 10C14 10.5523 13.5523 11 13 11H3C2.44772 11 2 10.5523 2 10Z" fill={color}/>
    </svg>
  )
}

export default Movable