import React, {FC, useEffect, useState} from 'react';
import {TableTimeItem as TableTimeItemWrapper, TableTimeSelectedTab, TableTimeSelectTitle} from "../styled-table-time-items";
import {observer} from "mobx-react-lite";
import MarketTable from '../../../../../../store/market-table/market-table'
import {ITimeTableItem} from "../../../../../../types/market/time-table-item";

interface ITableTimeItem{
    item: ITimeTableItem
}

const TableTimeItem: FC<ITableTimeItem> = observer( ({item}) => {

    const [isActive, setActive] = useState<boolean>(false)

    useEffect( () => {
            setActive(MarketTable.time.value === item.value)
    },[MarketTable.time.value])

    const handleItemSelect = () => {
        MarketTable.setTime(item)
    }

    return (
        <TableTimeItemWrapper onClick={handleItemSelect}>
            <TableTimeSelectTitle color={isActive?'#F9C510': 'white'}> {item.title} </TableTimeSelectTitle>
            <TableTimeSelectedTab isActive={isActive}/>
        </TableTimeItemWrapper>
    );
});

export default TableTimeItem;