import React, {FC} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import styled from "styled-components"
import Login from "../../../pages/login/login"
import RouterLayout from "../../../router/router-layout/router-layout"
import ForgotPassword from "../../../pages/forgot-password/forgot-password"
import Registration from "../../../pages/registration/registration"
import EmailVerification from "../../../pages/email-verification/email-verification"
import Verification from "../../../pages/email-verification/verification/verification"
import ResetPassword from "../../../pages/reset-password/reset-password"
import SuccessEmailPage from "../../../pages/email-verification/success-email-page/success-email-page";
import FailEmailPage from "../../../pages/email-verification/fail-email-page/fail-email-page";


const AuthLayoutWrapper = styled.div`
  min-height: 100vh;
  min-width: 100vh;
`

const AuthLayout: FC = () => {
    return (
        <RouterLayout>
            <AuthLayoutWrapper>
                <Switch>
                    <Route component={Login} exact path={'/login'}/>
                    <Route component={ForgotPassword} exact path={'/forgot-password'}/>
                    <Route component={ResetPassword} exact path={'/reset-password'}/>
                    <Route component={Registration} exact path={'/registration'}/>
                    <Route component={EmailVerification} exact path={'/email-verification'}/>
                    <Route component={Verification} exact path={'/auth/activate'} />
                    <Route component={SuccessEmailPage} exact path={'/success'}/>
                    <Route component={FailEmailPage} exact path={'/error'}/>
                    <Redirect to={'/login'}/>
                </Switch>
            </AuthLayoutWrapper>
        </RouterLayout>
    );
};

export default AuthLayout;