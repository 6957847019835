import React, {FC, useEffect} from 'react';
import {
  OrderHistoryTableBody, OrderHistoryTableBodyItem,
  OrderHistoryTableHead,
  OrderHistoryTableHeadItem,
  OrderHistoryTableRow,
  OrderHistoryTable as OrderHistoryTableWrapper, NoOrderHistory
} from "./styled-order-history-table";
import {observer} from "mobx-react-lite";
import OrderHistory from '../../../../../store/order-history/order-history'
import {toJS} from "mobx";
import {toCoin} from "../../../../../utils/tools/calcs";
import {parseDate} from "../../../../../utils/date/date";
import {IOrderHistoryItem, OrderStatus} from "../../../../../types/orders/order-history.types";

interface IOrderHistoryTableItem{
    item: IOrderHistoryItem
}

const status: Record<OrderStatus, string> = {
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  CREATED: 'Created',
  PARTIALLY_COMPLETED: 'Partially completed'
}


const cutString = (value:string, symbolsAmount: number) =>{
  if(value.length < symbolsAmount) return value
  return String( value).slice(0, symbolsAmount)
}

const OrderHistoryTableItem :FC<IOrderHistoryTableItem> = ({item}) => {

    const parsedAmount = toCoin(item.amount, item.tradingPair.fromCoin.symbol).toString()
    const parsedPrice = toCoin(item.price, item.tradingPair.toCoin.symbol).toString()
    const parsedTotal = toCoin(toCoin(item.total, item.tradingPair.toCoin.symbol).toString(),item.tradingPair.fromCoin.symbol).toFixed()
    const parsedAverage = toCoin(item.average, item.tradingPair.toCoin.symbol).toFixed()
    const executed = toCoin(item.executed, item.tradingPair.fromCoin.symbol).toFixed()

    useEffect(() => {
      console.log(item.status)
    },[])
    return(
      <OrderHistoryTableRow>
          <OrderHistoryTableBodyItem> {item.tradingPair.fromCoin.symbol}/{item.tradingPair.toCoin.symbol} </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {item.orderType} </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {item.exchange} </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {executed} </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {item.amount === '' ? '-' : parsedAmount} </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {item.price === '' ? '-' : parsedPrice} </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {isNaN(Number(parsedAverage))? '-' : cutString(parsedAverage, 8) } </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {parsedTotal} </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> - </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {status[item.status] } </OrderHistoryTableBodyItem>
          <OrderHistoryTableBodyItem> {parseDate(item.createdAt)} </OrderHistoryTableBodyItem>
      </OrderHistoryTableRow>
    )

}

const OrderHistoryTable = observer (() => {
    useEffect(()=> {
        (async function (){
            console.log(toJS( OrderHistory.items))
        })()
    },[OrderHistory.items])

    return (
        <OrderHistoryTableWrapper>
            <OrderHistoryTableHead>
                <OrderHistoryTableRow>
                    <OrderHistoryTableHeadItem>Pair</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Type</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Exchange</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Executed</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Amount</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Price</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Average</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Total</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Trigger Condition</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Status</OrderHistoryTableHeadItem>
                    <OrderHistoryTableHeadItem>Date</OrderHistoryTableHeadItem>
                </OrderHistoryTableRow>
            </OrderHistoryTableHead>
            <OrderHistoryTableBody>
              {OrderHistory.items.length > 0?
                <>
                  {
                    OrderHistory.items.map(item => <OrderHistoryTableItem item={item} key={item.id}/>)
                  }
                </>:
                <NoOrderHistory>
                  You have no order history
                </NoOrderHistory>
              }


            </OrderHistoryTableBody>
        </OrderHistoryTableWrapper>
    );
});

export default OrderHistoryTable;