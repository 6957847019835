import styled from "styled-components";

export const SidebarNewsWrapper = styled.div`
  background: #1B2228;
  margin-top: 4px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
`

export const SidebarNewsTitle = styled.div`
  display: flex;
  border-bottom: 1px solid #151A1E;
`

export const ViewAll = styled.span`
  color: #D9AF20;
  display: flex;
`

export const News = styled.span`
  color: #ffffff;
`

export const NewsBlock = styled.div`
  margin-top: 8px;
`

export const TabItem = styled.div`
  margin-left: 16px;
  cursor: pointer;
`

interface ITabItem {
  isActive: boolean
}

export const TabItemTitle = styled.div<ITabItem>`
  margin-bottom: ${props => props.isActive? '12px': '13px'};
  color: ${props => props.isActive? '#F9C510': '#ffffff'};
  padding-left: 7px;
  padding-right: 7px;
`

export const ActiveTab = styled.div<ITabItem>`
  width: 100%;
  height: 2px;
  background: #F9C510;
  display: ${props => props.isActive? 'block': 'none'};
`
