import styled from "styled-components/macro";

export const EmailVerificationWrapper = styled.div`
  margin-left: 70px;
  padding-top: 20px;
`

export const CopyRight = styled.div`
  color: rgba(37, 53, 69, 0.4);
  font-size: 13px;
  text-align: left;
  margin-top: 32px;
`

export const DescriptionWrapper = styled.div`
    margin-top: 32px;
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: #253545;
  text-align: left;
`

export const Subtitle = styled.div`
  margin-top: 8px;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: rgba(37, 53, 69, 0.6);
`

export const Row = styled.div`
  display: flex;
  margin-top: 58px;
`

export const Button = styled.button`
  border: none;
  outline: none;
  color: #ffffff;
  padding: 10px 32px;
  background: #1B2228;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`