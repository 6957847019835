import styled from "styled-components/macro";

export const DangerWrapper = styled.div`
  display: block;
`

export const Title = styled.div`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 16px;
`

export const Purchased = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.4);
`

export const Row = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 400;
`

export const SubTitle = styled.span`
  color: rgba(255, 255, 255, 0.2);
`

export const Value = styled.span`
  color: #ffffff;
`

export const TotalRow = styled.div`
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.2);
  display: flex;
`

export const DangerBtn = styled.button`
  background: #D9AF20;
  color: #ffffff;
  padding: 8px 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
`

export const Error = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #EB5746;
`