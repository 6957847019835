import React, {useEffect, useState} from 'react';
import Buy from "./buy/buy";
import Sell from "./sell/sell";
import {getLastOrder, getOrderBook} from "../../../../services/api/orders/order-book";
import {IOrderBookItem} from "../../../../types/orders/order-book.types";
import {observer} from "mobx-react-lite";
import Pairs from '../../../../store/pairs/pairs'
import {BuyColumn, LastOrder, NoOrderBook, OrderBookRow, OrderBookWrapper, SellColumn} from "./styled-order-book";
import {toCoin} from "../../../../utils/tools/calcs";


const OrderBook = observer( () => {

    const [buyItems, setBuyItems] = useState<IOrderBookItem[]>([])
    const [sellItems, setSellItems] = useState<IOrderBookItem[]>([])

    const [lastOrder, setLastOrder] = useState<{coins: string; bucks: string}>()
    const getOrders = async () => {
      if("id" in Pairs.currentPair && Pairs.currentPair.id){
        const id = Pairs.currentPair.id
        const res = await getOrderBook(id)
        setBuyItems(res.buy)
        setSellItems(res.sell)
        try{
          const lastOrder = await getLastOrder(id)
          const coins = toCoin(lastOrder.price, Pairs.currentPair.toCoin.symbol).toFixed()
          const bucks = (Number( coins) * Number( Pairs.currentPair.toCoin.usdPrice)).toFixed(2)
          console.log(Pairs.currentPair.toCoin.usdPrice)
          console.log(coins)
          console.log(bucks)
          setLastOrder({
            coins,
            bucks
          })
        }catch (e){
          console.log(e)
        }
      }
    }

    useEffect(()=> {
        getOrders().then(() => console.log('got orders'))

        const interval = setInterval(() => {
            getOrders().then(() => console.log('got orders'))
        },3000)

        return  function cleanup (){
            clearInterval(interval)
        }
    },[Pairs.currentPair])

    return (
      <OrderBookWrapper>
          <OrderBookRow>
              {
                  buyItems.length > 0 || sellItems.length> 0?
                    <>
                        <BuyColumn>
                            <Buy items={buyItems}/>
                        </BuyColumn>
                        <LastOrder>
                            <div style={{fontWeight: 700, fontSize: '14px', textAlign: 'left', color: '#ffffff'}}> {lastOrder?.coins} </div>
                            <div style={{fontWeight: 400, fontSize: '13px', textAlign: 'left', color: 'rgba(255, 255, 255, 0.4)'}}> ${lastOrder?.bucks} </div>
                        </LastOrder>
                        <SellColumn>
                            <Sell items={sellItems}/>
                        </SellColumn>
                    </>
                    : <NoOrderBook>
                        No order book
                    </NoOrderBook>
              }

          </OrderBookRow>
      </OrderBookWrapper>
    )
})

export default OrderBook