import React, {FC,  useRef, useState} from 'react';
import Default from "../../components/layouts/default/default";
import styled from "styled-components";
import {useIntersectionObserver} from "../../hooks/use-intersection-observer";
// import orders from "../../components/wallet/orders/orders";
import WalletChart from '../../public/wallet_chart.png'
import AppBar from "../../components/global/app-bar/app-bar";
import Content from "../../components/global/content-wrapper/content-wrapper";
import FavoritePairs from "../../components/wallet/favorite-pairs/favorite-pairs";
import BalanceCrypto from "../../components/wallet/balance-crypto/balance-crypto";
import WalletHeader from "../../components/wallet/wallet-header/wallet-header";
import Orders from "../../components/wallet/orders/orders";

const WalletPageWrapper = styled.div`
  display: grid;
  height: 93vh;
  width: 100%;
  padding-top: 22px;
  box-sizing: border-box;
  grid-template: 
    "h h h h h h h h h h s s  "
    "h h h h h h h h h h s s  "
    "c c c g g g g g g g s s  "
    "c c c g g g g g g g s s  "
    "c c c g g g g g g g s s  "
    "c c c g g g g g g g s s  "
    "c c c g g g g g g g s s  "
    "c c c g g g g g g g s s  "
    "c c c g g g g g g g s s  "
    "c c c g g g g g g g s s  "
    "c c c b b b b b b b b b  "
    "c c c b b b b b b b b b  ";
  
  grid-template-rows: 1fr 1.2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr  !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; //Chrome, Safari and Opera
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

const TotalBalance = styled.div`
  grid-area: h;
  display: flex;
  height: 100%;
`

interface IBalanceCoins{
    height: number,
    children: any
}

const BalanceCoins = styled.div<IBalanceCoins>`
  grid-area: c;
  margin: 4px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height:  ${props => props.height > 0? `${props.height}vh`: 'none' } 
`

const Chart = styled.img`
  grid-area: g;
  object-fit: contain;
  height: 100%;
  width: 100%;
`

const BottomBar = styled.div`
  grid-area: b;
`

const FavoriteCoins = styled.div`
  grid-area: s;
  margin-bottom: 4px;
`

const Wallet :FC = () => {

    const parentRef = useRef(null)
    const childRef = useRef(null)

    const callback = (isIntersecting :boolean) => {
        if(isIntersecting && height !== 0){
          setHeight(0)
        }
        if(!isIntersecting && height !== 90){
            setHeight(90)
        }
    }

    useIntersectionObserver(parentRef, childRef,   callback )

    const [height, setHeight] = useState<number>(0)

    return (
        <Default>
            <AppBar/>
            <Content>
                <WalletPageWrapper ref={parentRef}>
                    <TotalBalance ref={childRef}>
                        <WalletHeader/>
                    </TotalBalance>
                    <BalanceCoins height={height}>
                        <BalanceCrypto/>
                    </BalanceCoins>
                    <Chart src={WalletChart} />
                    <BottomBar>
                        <Orders/>
                    </BottomBar>
                    <FavoriteCoins> <FavoritePairs/></FavoriteCoins>
                </WalletPageWrapper>
            </Content>
        </Default>
    );
};

export default Wallet;