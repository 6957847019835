import {makeAutoObservable} from "mobx";
import {DropDownItem} from "../../types/market/dropdown";
import {DROPDOWN_ITEMS} from "../../utils/constants/market-dropdown";


class MarketSidebar{

    activeAction:string = 'Buy'

    activeTypeAction: DropDownItem = DROPDOWN_ITEMS[0]

    constructor() {
        makeAutoObservable(this)
    }

    setActiveAction(action:string){
        this.activeAction = action
    }

    setActiveTypeAction(action: DropDownItem){
        this.activeTypeAction = action
    }

    clear(){
        this.activeAction = 'Buy'
        this.activeTypeAction = DROPDOWN_ITEMS[0]
    }
}

export default new MarketSidebar()