import React from 'react';

const Delete = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5 2C5 1.44772 5.44772 1 6 1H10C10.5523 1 11 1.44772 11 2H13C13.5523 2 14 2.44772 14 3C14 3.55228 13.5523 4 13 4H3C2.44772 4 2 3.55228 2 3C2 2.44772 2.44772 2 3 2H5ZM3.59953 6.08342C3.70162 6.03875 3.81261 6.01063 3.92911 6.00247C3.95866 6.00034 3.98804 5.99953 4.01719 6H11.9828C12.012 5.99953 12.0413 6.00034 12.0709 6.00247C12.1876 6.01065 12.2987 6.03883 12.4009 6.08361C12.539 6.14395 12.6595 6.23403 12.7555 6.34478C12.8522 6.4562 12.9247 6.58924 12.9647 6.73568C12.994 6.84233 13.0059 6.95532 12.9975 7.07089C12.9955 7.10046 12.9921 7.12967 12.9875 7.15847L12.1126 13.2828C11.9718 14.2681 11.128 15 10.1327 15H5.8673C4.872 15 4.02815 14.2681 3.8874 13.2828L3.01248 7.15845C3.0079 7.12966 3.00454 7.10045 3.00247 7.07089C2.99414 6.95532 3.00602 6.84233 3.0353 6.73568C3.11454 6.44704 3.32122 6.20483 3.59953 6.08342ZM5.15301 8L5.8673 13L10.1327 13L10.847 8H5.15301Z" fill="#EB5746"/>
    </svg>
  )
}

export default Delete;