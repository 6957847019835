import React, {FC, useEffect, } from 'react';
import Default from "../../components/layouts/default/default";
import styled from "styled-components";
import TableOptions from "../../components/market/table/table-options/table-options";
import Orders from "../../components/market/orders/orders";
import Sidebar from "../../components/market/sidebar/sidebar";
import AppBar from "../../components/global/app-bar/app-bar";
import Content from "../../components/global/content-wrapper/content-wrapper";
//TODO rewrite this with hooks and typescript
// @ts-ignore
import TradingViewWidget, {Themes} from 'react-tradingview-widget'
import {observer} from "mobx-react-lite";
import Pairs from '../../store/pairs/pairs'
import {IPair} from "../../types/exchange/pair.types";
import MarketTable from '../../store/market-table/market-table'

const TableBlock = styled.div`
  flex: 0 1 75%;
  height: calc(93vh - 22px);
  padding-right: 4px;
  display: block;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; 
  }
  -ms-overflow-style: none; 
  scrollbar-width: none;
`

const Chart = styled.div`
  height: 571px;
  width: 100%;
`

const CHART_FROM_PAIR_COLLECTION = new Map([
  ['ETHBTC', 'ETHBTC'],
  ['ETHUSDT', 'ETHUSDT'],
  ['BNBBTC','BNBBTC'],
  ['BTCUSDT', 'BTCUSDT'],
  ['ADABTC', 'ADABTC'],
  ['DOGEBTC','DOGEBTC'],
  ['BNBETH','BNBETH'],
  ['BNBXRP','BNBXRP'],
])

const Market :FC = observer( () => {

    useEffect(() => {
      (async function (){
        if(Pairs.pairs.length === 0) await Pairs.getPairs()
      })()
    },[Pairs.pairs])

    const currentPair = Pairs.currentPair as IPair

    const tradingViewPair = CHART_FROM_PAIR_COLLECTION.get(`${ currentPair?.fromCoin?.symbol }${currentPair?.toCoin?.symbol}`)
    return (
        <Default>
            <AppBar/>
            <Content>
                <div style={{height:'93vh'}}>
                    <div style={{paddingTop:'22px'}}/>
                    <div style={{ display:"flex" }}>
                        <TableBlock>
                            <TableOptions/>
                            <Chart>
                              <TradingViewWidget symbol={tradingViewPair? tradingViewPair: 'XRPETH'} height={'570px'} width={'100%'} theme={Themes.DARK} hide_ledend={true} hide_side_toolbar={true} hide_top_toolbar={true} range={'15m'} interval={MarketTable.time.value}/>
                            </Chart>
                            <Orders/>
                        </TableBlock>
                        <Sidebar/>
                    </div>
                </div>
            </Content>
        </Default>
    );
});

export default Market;