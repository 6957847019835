import React, {FC} from 'react';
import styled from "styled-components";
import Spacer from "../../ui/spacer/spacer";

const HeaderWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  padding: 16px;
  margin-bottom: 4px;
  background: #1B2228;
`

const PageTitle = styled.span`
  font-weight: 600;
  color: #ffffff;
  font-size: 24px;
`

const TabsWrapper = styled.div`
  background: #171C21;
  display: flex;
`

interface IHeaderTab{
    isActive: boolean
}

const HeaderTab = styled.div<IHeaderTab>`
  border-radius: 4px;
  margin: 2px;
  padding: 6px 14px;
  font-size: 13px;
  font-weight: 600;
  background: ${props => props.isActive? '#1B2228' : 'transparent'} ;
  color: ${props => props.isActive? '#ffffff' : 'rgba(255, 255, 255, 0.4)'};
  cursor: pointer;
`

interface IHeader{
    tabs: string[],
    currentTab: string,
    setCurrentTab: (value: string) => void
}

const Header :FC<IHeader> = ({tabs, currentTab, setCurrentTab}) => {
    return (
        <HeaderWrapper>
            <PageTitle> Buy & Sell crypto instantly  </PageTitle>
            <Spacer/>
            <TabsWrapper>
                <HeaderTab isActive={currentTab === 'Buy'} onClick={() => setCurrentTab('Buy')}> Buy </HeaderTab>
                <HeaderTab isActive={currentTab === 'Sell'} onClick={() => setCurrentTab('Sell')}> Sell </HeaderTab>
            </TabsWrapper>

        </HeaderWrapper>
    );
};

export default Header;