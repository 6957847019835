import React, {FC} from 'react';
import LogoImage from '../../../public/unicsoft_logo.png'
import Spacer from "../../ui/spacer/spacer"
import Notification from '../../../public/notification.png'
import {
    AppBarLogo,
    AppBarLogoImage,
    AppBarLogoTitle,
    AppBarNavigation,
    AppBarNavItem,
    AppBarWrapper
} from "./styled-app-bar"
import {useHistory} from 'react-router-dom'
import Account from "./account/account";

const AppBar: FC = () => {

    const history = useHistory()

    const pushToLink = (path : string) => {
        history.push(path)
    }

    return (
        <AppBarWrapper>
            <AppBarLogo>
                <AppBarLogoImage src={LogoImage}/>
                <AppBarLogoTitle> Unicsoft </AppBarLogoTitle>
            </AppBarLogo>
            <Spacer/>
            <AppBarNavigation>
                <AppBarNavItem > Home </AppBarNavItem>
                <AppBarNavItem onClick={() => pushToLink('/market')}> Market </AppBarNavItem>
                <AppBarNavItem> Trade </AppBarNavItem>
                <AppBarNavItem onClick={() => pushToLink('/wallet')}> Wallets </AppBarNavItem>
                <Account/>
                <AppBarNavItem> <img alt='Notifications' src={Notification}/> </AppBarNavItem>
            </AppBarNavigation>
        </AppBarWrapper>
    );
};

export default AppBar;