import React, {FC} from 'react';
import styled from "styled-components";
import OrderTabItem from "./order-tab-item/order-tab-item";
import {TABS} from "../../../../utils/constants/tab";

const OrderTabsWrapper = styled.div`
  height: 50px ;
  display: flex;
  border-bottom: 1px solid #151A1E;
  margin-left: 14px;
  margin-right: 14px;
`

const OrderTabs: FC = () => {
    return (
        <OrderTabsWrapper>
            <OrderTabItem item={TABS.active} />
            <OrderTabItem item={TABS.trade} />
            <OrderTabItem item={TABS.order} />
            <OrderTabItem item={TABS.orderHistory} />
        </OrderTabsWrapper>
    );
};

export default OrderTabs;