import React, {FC, useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {activateEmail} from "../../../services/api/email-verification/requests";
import EmailVerification from "../../../store/email-verification/email-verification";

const Verification :FC = () => {

    const location = useLocation()
    const history = useHistory()

    useEffect(()=> {
        (async function() {
            const searchParams = new URLSearchParams( location.search)
            const code =  searchParams.get('code')
            if(!code){
                EmailVerification.error = 'There is no code'
                return history.push('/error')
            }
            const res = await activateEmail(code)
            console.log(res.message)
            if(res.message !== ''){
                EmailVerification.error = res.message
                return history.push('/error')
            }
            return history.push('/success')
        })()
    } ,[])
    return (
        <div>
            {/*TODO show loader here*/}
        </div>
    );
};

export default Verification;