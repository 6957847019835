import {makeAutoObservable} from "mobx";
import {ITimeTableItem} from "../../types/market/time-table-item";

class MarketTable{

    time: ITimeTableItem = {
        value: 'D',
        title: '1D'
    }

    constructor() {
        makeAutoObservable(this)
    }

    setTime(newTime: ITimeTableItem){
        this.time = newTime
    }

    clear(){
        this.time = {
            value: 'D',
            title: '1D'
        }
    }
}

export default new MarketTable()