import React, {FC} from 'react';
import styled from "styled-components";
import TotalBalance from "./total-balance/total-balance";
import TotalChart from "./total-chart/TotalChart";
import Spacer from "../../ui/spacer/spacer";
import WalletTabs from "./wallet-tabs/wallet-tabs";

const WalletHeaderWrapper = styled.div`
  display: block;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 4px;
  background: #1B2228;
  color: #ffffff;
`

const WalletHeader :FC = () => {
    return (
        <WalletHeaderWrapper>
            <div style={{display:'flex', height: '100%'}}>
                <TotalBalance/>
                <TotalChart/>
                <Spacer/>
                <WalletTabs/>
            </div>
        </WalletHeaderWrapper>
    );
};

export default WalletHeader;