import {makeAutoObservable} from "mobx";
import {IFavoritePair} from "../../types/favorite-pairs/favorite-pairs.types";
import {getFavoritePairs} from '../../services/api/favorite-pairs/favorite-pairs'

class FavoritePairs{
  favoritePairs: IFavoritePair[] = []

  async getFavoritePairs(){
    this.favoritePairs = await getFavoritePairs()
  }
  setFavoritePairs(favoritePairs: IFavoritePair[]){
    this.favoritePairs = favoritePairs
  }

  clear(){
    this.favoritePairs = []
  }

  constructor() { makeAutoObservable(this)}
}

export default new FavoritePairs()